




























import { customRule } from "@/lib/rules/customRule";
import { handleError } from "@/lib/utility/handleError";
import { ThgChargingStationProofDocumentDtoGen, ThgChargingStationProofDtoGen } from "@/services/thg/v1/data-contracts";
import { ThgModule } from "@/store/modules/thg.store";
import saveAs from "file-saver";
import { Component, Vue } from "vue-property-decorator";
import FileInput from "../utility/FileInput.vue";

export type ChargingStationFileProof = File /** timestamp to show when the document is uploaded */ & {
  uploaded: number;
};

@Component({
  components: {
    FileInput
  }
})
export default class ThgProof extends Vue {
  get thg() {
    return ThgModule.myThg;
  }

  get isLoading() {
    return ThgModule.isLoadingProofs;
  }

  file!: File | undefined;
  disabled = false;
  /**
   * is the file not uploaded yet
   */
  noDuplicateFiles = true;

  downloadFile(file: ThgChargingStationProofDocumentDtoGen) {
    saveAs(file.url, file.name);
  }

  get fileProofs() {
    return this.thg?.proofs || [];
  }

  get rules() {
    return [
      customRule(
        this.noDuplicateFiles,
        String(this.$i18n.t("components.thg.ThgChargingStationProof.fileAlreadyExists"))
      )
    ];
  }

  mount() {
    this.file = null as any;
  }

  async onChange(file: File) {
    if (!file) {
      return;
    }
    /** Don't allow uploading the same file twice */
    this.noDuplicateFiles = true;
    const dto: ThgChargingStationProofDtoGen = {
      file: file
    };

    try {
      if (this.thg?.id) {
        ThgModule.setProofLoading(true);
        let savedFile: ThgChargingStationProofDocumentDtoGen = null as any;
        savedFile = await ThgModule.uploadProof({ data: dto, thgId: this.thg.id });

        this.$emit("file-change", savedFile);
      }
    } catch (error) {
      handleError(error);
    } finally {
      ThgModule.setProofLoading(false);
    }
  }
}
