var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "showFooter": false,
      "renderBackAndForthFooter": false
    }
  }, [_c('thg-checkout-card', {
    attrs: {
      "loading": _vm.isLoading,
      "userExists": _vm.userExists,
      "hint": _vm.hint
    },
    on: {
      "update:loading": function updateLoading($event) {
        _vm.isLoading = $event;
      },
      "submit": _vm.submit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }