
import { Component, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({})
export default class ThgImpactSelectorScreensMixin extends Vue {
  /**
   * Screens for deciding the handling of GHG
   * @example `thgimpactselector`, `thgSustainable`
   */
  impactSelectorScreens = [
    ReportScreenEnum.thgImpactSelectorOnlyCustomerAccount,
    ReportScreenEnum.thgImpactSelectorWithCustomerAccount,
    ReportScreenEnum.thgimpactselector,
    ReportScreenEnum.thgimpactselectortrees,
    ReportScreenEnum.thgSustainable,
    ReportScreenEnum.thgSustainableTrees
  ];
}
