


















import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import ThgRegistrationAtUploadCard from "@/components/thg/ThgRegistrationAtUploadCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti,
    ThgRegistrationAtUploadCard
  }
})
export default class ThgRegistrationAtImageUpload extends ReportStepMixin implements IReportStep {
  get getRegistrationImageFront() {
    return ThgCreateModule.registrationImageFront;
  }

  get getRegistrationImageBack() {
    return ThgCreateModule.registrationImageBack;
  }

  screen = ReportScreenEnum.thgregistrationat;

  header: IReportHeaderData = {
    title: "report.thgRegistrationAt.title",
    description: "report.thgRegistrationAt.description",
    image: AssetEnum.registrationAtExample
  };

  instruction: IReportInstructionData = {
    title: "report.thgRegistrationAt.title",
    text: "report.thgRegistrationAt.instructionText",
    pictures: [AssetEnum.registrationAtCorrectInstructions, AssetEnum.registrationAtIncorrectInstructions],
    example: AssetEnum.registrationAtExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  get isDone(): boolean {
    if (this.getRegistrationImageFront.length <= 0) {
      return false;
    }

    if (this.getRegistrationImageBack.length <= 0) {
      return false;
    }

    return true;
  }
}
