/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import accountService from "@/services/thg/services/accountService";
import {
  ThgAccountControllerFindAllParamsGen,
  ThgAccountControllerCreateAccountParamsGen,
  ThgAccountDtoGen,
  ThgAccountViewmodelGen,
  ThgOperationIdViewModelGen,
  ThgUpdateAccountDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Account } from "@/models/account.entity";

export class AccountDataAccessLayer extends AbstractLocalDataAccessLayer<ThgAccountViewmodelGen> {
  getIdentifier(entity: ThgAccountViewmodelGen): string {
    return entity.id;
  }
}

export class AccountPageDataProvider extends AbstractPageDataProvider<
  ThgAccountViewmodelGen,
  ThgAccountControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: ThgAccountControllerFindAllParamsGen): Promise<IPageViewModel<ThgAccountViewmodelGen>> {
    const res = await accountService.findAll(query);

    return res;
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "account",
  store
})
export class AccountStore extends PaginatedBaseStore<ThgAccountViewmodelGen, ThgAccountControllerFindAllParamsGen> {
  _data = new AccountDataAccessLayer();
  _pageProvider = new AccountPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions = Account.filterables;

  @Action
  async createAccount(data: { query: ThgAccountControllerCreateAccountParamsGen; dto: ThgAccountDtoGen }) {
    const account = await accountService.create(data.query, data.dto);

    this._data.set(account);
  }

  @Action
  async updateAccount(data: { accountId: string; dto: ThgUpdateAccountDtoGen }) {
    const account = await accountService.update(data.accountId, data.dto);

    this._data.set(account);
  }

  @Action
  async check(): Promise<ThgOperationIdViewModelGen> {
    return accountService.check();
  }

  @Action
  getUserAccountNumber(): Promise<number> {
    return accountService.getUserAccountNumber();
  }
}

export const AccountModule = getModule(AccountStore);
