/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAccountingRecordItemViewmodelGen,
  ThgBillingInfoGen,
  ThgBillingThgControllerCreateBillingDocumentParamsGen,
  ThgBillingThgControllerGetAllThgsParamsGen,
  ThgBillingViewmodelGen,
  ThgCreateBillingThgPdfDtoGen,
  ThgCreateBillingThgSettingsDtoGen,
  ThgThgMeterReadingViewModelGen,
  ThgThgViewModelGen,
  ThgUrlViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BillingThg<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags billing-thg
   * @name BillingThgControllerCreateBillingDocument
   * @summary (ADMIN) create a billing for a thg
   * @request POST:/billing/thg/{id}
   * @secure
   * @response `201` `ThgBillingViewmodelGen` Created billing document
   */
  billingThgControllerCreateBillingDocument = (
    { id, ...query }: ThgBillingThgControllerCreateBillingDocumentParamsGen,
    data: ThgCreateBillingThgSettingsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBillingViewmodelGen, any>({
      path: `/billing/thg/${id}`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-thg
   * @name BillingThgControllerCreateBillingPdf
   * @summary (ADMIN) create a billing pdf for the billing of a thg
   * @request PUT:/billing/{billingNumber}/thg/pdf
   * @secure
   * @response `200` `ThgUrlViewmodelGen` A link to the pdf
   */
  billingThgControllerCreateBillingPdf = (
    billingNumber: number,
    data: ThgCreateBillingThgPdfDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgUrlViewmodelGen, any>({
      path: `/billing/${billingNumber}/thg/pdf`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-thg
   * @name BillingThgControllerCreateBillingAccountingRecords
   * @summary (ADMIN) create an accounting record for the billing of a thg
   * @request PUT:/billing/{billingNumber}/thg/record
   * @secure
   * @response `200` `(ThgAccountingRecordItemViewmodelGen)[]` A link to the pdf
   */
  billingThgControllerCreateBillingAccountingRecords = (billingNumber: number, params: RequestParams = {}) =>
    this.http.request<ThgAccountingRecordItemViewmodelGen[], any>({
      path: `/billing/${billingNumber}/thg/record`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-thg
   * @name BillingThgControllerGetAllThgs
   * @summary (ADMIN) Returns all thgs that are ready to be billed or for which the affiliate can be billed
   * @request GET:/billing/thg/{billingType}
   * @secure
   * @response `200` `((ThgThgViewModelGen)[] | (ThgThgMeterReadingViewModelGen)[])`
   */
  billingThgControllerGetAllThgs = (
    { billingType, ...query }: ThgBillingThgControllerGetAllThgsParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen[] | ThgThgMeterReadingViewModelGen[], any>({
      path: `/billing/thg/${billingType}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-thg
   * @name BillingThgControllerAddBillingInfoToThg
   * @summary (ADMIN) add billing info to a thg document. This should only be used for when a billing was created manually outside of our logic.
   * @request PUT:/billing/thg/{thgId}/add
   * @secure
   * @response `200` `ThgThgViewModelGen` A link to the pdf
   */
  billingThgControllerAddBillingInfoToThg = (thgId: string, data: ThgBillingInfoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, any>({
      path: `/billing/thg/${thgId}/add`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
