var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 px-0"
  }, [_vm._v(_vm._s(_vm.$t("report.thgBillingOverview.title")) + " 💸")]), _c('v-card-subtitle', {
    staticClass: "pl-0"
  }, [_vm._v(_vm._s(_vm.$t("report.thgBillingOverview.description")))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-spacer'), _vm.isEnabled ? _c('div', {
    staticClass: "text-center"
  }, [_c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "bottom": "",
      "offset-y": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "two-line": ""
          }
        }, 'v-list-item', attrs, false), on), [_c('v-list-item-content', [_vm.isLoading ? _c('v-skeleton-loader', {
          attrs: {
            "type": "list-item"
          }
        }) : _c('v-list-item-title', [_c('span', {
          staticClass: "text-h6 mr-1 font-weight-light"
        }, [_vm._v(" € ")]), _c('span', {
          staticClass: "text-h4 font-weight-bold "
        }, [_vm._v(" " + _vm._s(_vm.euroCentSplit(_vm.incomingTotal)[0]) + " ")]), _c('span', {
          staticClass: "text-h6 ml-n2 mr-n1 font-weight-light"
        }, [_vm._v(",")]), _c('span', {
          staticClass: "text-h6 font-weight-light"
        }, [_vm._v(" " + _vm._s(_vm.euroCentSplit(_vm.incomingTotal)[1]) + " ")])]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgBillingOverview.incomingTotal")) + " ")])], 1)], 1)];
      }
    }], null, false, 1811932686)
  }, [_c('v-list', [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-credit-card-check-outline")])], 1), _c('v-list-item-content', {
    staticClass: "text-right"
  }, [_c('v-list-item-title', {
    staticClass: "text-h6 font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.incomingCustomerAccount) + "€ ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgBillingOverview.incomingCustomerAccount")) + " ")])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-tree-outline")])], 1), _c('v-list-item-content', {
    staticClass: "text-right"
  }, [_c('v-list-item-title', {
    staticClass: "text-h6 font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.outgoingTrees) + "€ ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgBillingOverview.outgoingTrees")))])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-ev-station")])], 1), _c('v-list-item-content', {
    staticClass: "text-right"
  }, [_c('v-list-item-title', {
    staticClass: "text-h6 font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.outgoingCharging) + "€ ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgBillingOverview.outgoingCharging")))])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-handshake-outline")])], 1), _c('v-list-item-content', {
    staticClass: "text-right"
  }, [_c('v-list-item-title', {
    staticClass: "text-h6 font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.incomingAffiliate) + "€ ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgBillingOverview.incomingAffiliate")))])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), !_vm.isLoading ? _c('v-row', [_c('v-col', {
    staticClass: "px-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-billing-overview-card')], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-2"
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-heading, table-tbody, table-tfoot"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }