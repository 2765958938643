




































































































import ThgCheckoutOrderCard from "@/components/thg/ThgCheckoutOrderCard.vue";
import ThgCheckoutPersonalDataCard from "@/components/thg/ThgCheckoutPersonalDataCard.vue";
import ThgCheckoutVehicleCard from "@/components/thg/ThgCheckoutVehicleCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { requiredRule } from "@/lib/rules/requiredRule";
import { Type } from "@/lib/utility/type";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";

@Component({
  components: {
    LayoutReportStep,
    ThgCheckoutPersonalDataCard,
    ThgCheckoutOrderCard,
    ThgCheckoutVehicleCard
  }
})
export default class ThgCheckoutCard extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  dialogCloseButtonFunction?: Function;

  @Prop({ default: false })
  hideTitle?: boolean;

  @Prop({ default: "" })
  hint?: string;

  @Prop({ default: false })
  hideEdit?: boolean;

  @Prop({ default: false })
  hideSubmit?: boolean;

  @Prop({ default: false })
  userExists?: boolean;

  get isLoading() {
    return this.loading;
  }

  set isLoading(n: boolean) {
    this.$emit("update:loading", n);
  }

  partnerColor = new PartnerColor();

  mounted() {
    this.isConfirmed = false;
  }

  valid = false;

  userExistsText = "report.thgClosing.userAlreadyExists";

  get isDone() {
    return this.valid && this.isConfirmed;
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get reportReviewedRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(this.thgRegistrationImageValidRule());
    rules.push(this.numberplateIsMandatory());

    //TODO: Make this ways smarter
    if (ReportModule.screenOder.includes(ReportScreenEnum.thgcontact) && !this.isAuthenticated) {
      rules.push(this.reportContactEmailValidRule());
      rules.push(this.reportUserValidRule());
    }

    if (ReportModule.screenOder.includes(ReportScreenEnum.thgpassword) && !this.isAuthenticated) {
      rules.push(this.passwordIsMandatory());
    }

    return rules;
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  passwordIsMandatory(message = "report.thgClosing.passwordMandatory"): boolean | string {
    const passwordValid = Type.isPresent(ThgCreateModule.password) && !Type.isBlankString(ThgCreateModule.password);
    return passwordValid || String(this.$t(message));
  }

  numberplateIsMandatory(message = "report.thgClosing.numberplateMandatory"): boolean | string {
    const numberplateValid =
      Type.isPresent(ThgCreateModule.numberplate) && !Type.isBlankString(ThgCreateModule.numberplate);
    return numberplateValid || String(this.$t(message));
  }

  reportContactEmailValidRule(message = "report.summary.mailMandatory"): boolean | string {
    return (
      (Type.isPresent(ThgCreateModule.contact.email) && !Type.isBlankString(ThgCreateModule.contact.email)) ||
      String(this.$t(message))
    );
  }

  thgRegistrationImageValidRule(message = "report.thgClosing.registrationImagesMandatory"): boolean | string {
    return ThgCreateModule.registrationImageFront.length !== 0 || String(this.$t(message));
  }

  reportUserValidRule(message = "report.summary.nameMandatory"): boolean | string {
    return (
      (Type.isPresent(ThgCreateModule.user.firstName) &&
        Type.isPresent(ThgCreateModule.user.lastName) &&
        !Type.isBlankString(ThgCreateModule.user.firstName) &&
        !Type.isBlankString(ThgCreateModule.user.lastName)) ||
      String(this.$t(message))
    );
  }

  get isTermsAccepted(): boolean {
    return ThgCreateModule.isTermsAccepted;
  }

  set isTermsAccepted(bool: boolean) {
    ThgCreateModule.setIsTermsAccepted(bool);
  }

  get isConfirmed(): boolean {
    return ThgCreateModule.isConfirmed;
  }

  set isConfirmed(bool: boolean) {
    ThgCreateModule.setIsConfirmed(bool);
  }

  get isMarketingContactAllowed(): boolean {
    return ThgCreateModule.isMarketingOptIn;
  }

  set isMarketingContactAllowed(bool: boolean) {
    ThgCreateModule.setIsMarketingOptIn(bool);
  }

  goToLogin() {
    this.$router.push({ path: "/login" });
  }

  async submit() {
    this.$emit("submit");
  }
}
