
import { Component, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({})
export default class ThgYearSelectionScreensMixin extends Vue {
  /**
   * Screens for selecting product(type of GHG, the year of submission)
   * @example `thgYearSelection`
   */
  yearSelectionScreens = [ReportScreenEnum.thgYearSelection, ReportScreenEnum.thgProducts];
}
