



































import BadConscienceDialog from "@/components/thg/BadConscienceDialog.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Ref, Vue } from "vue-property-decorator";
import { IThgImpact } from "./interface/ThgImpact.interface";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    BadConscienceDialog
  }
})
export default class ThgImpactSelectorCard extends Vue {
  @Ref("badConscienceDialog")
  readonly badConscienceDialog!: BadConscienceDialog;

  get selected() {
    return ThgCreateModule.impactTypeId;
  }

  set selected(id) {
    ThgCreateModule.setImpactTypeId(id);
  }

  get company() {
    return ReportModule.partner.companyName;
  }

  get items() {
    return ThgCreateModule.activeImpactTypeItems;
  }

  get item() {
    return ThgCreateModule.impactTypeItem;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  updateImpactTypeId(item: IThgImpact) {
    ThgCreateModule.setImpactType(item.impactType);
    ThgCreateModule.setImpactFactor(item.baseImpactFactor);

    if (item.showDialog) {
      this.badConscienceDialog.openDialog();
    }
  }
}
