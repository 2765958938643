var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "515"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 px-0"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-subtitle', {
    staticClass: "pl-0",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })], 1)], 1)], 1), !_vm.isLoading ? _c('v-row', _vm._l(_vm.myAffiliates, function (myAffiliate, i) {
    return _c('v-col', {
      key: i,
      staticClass: "px-1",
      attrs: {
        "cols": "12"
      }
    }, [_c('thg-invite-friends-card', {
      attrs: {
        "myAffiliate": myAffiliate
      }
    })], 1);
  }), 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "offset": "0",
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mb-10",
    attrs: {
      "max-width": "515"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "article, actions"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }