/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCheckZipViewmodelGen,
  ThgCreatePartnerZipDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgPartnerZipViewmodelGen,
  ThgZipControllerFindAllParamsGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Zip<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Zip
   * @name ZipControllerFindAll
   * @summary (ADMIN) List all zip information
   * @request GET:/zip
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgPartnerZipViewmodelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  zipControllerFindAll = (query: ThgZipControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgPartnerZipViewmodelGen[] }, ThgExceptionViewmodelGen>({
      path: `/zip`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Zip
   * @name ZipControllerCreateOrUpdateForPartner
   * @summary Creates a zip information for a partner
   * @request POST:/partner/{partnerId}/zip
   * @secure
   * @response `200` `ThgPartnerZipViewmodelGen` The zip informations
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  zipControllerCreateOrUpdateForPartner = (
    partnerId: string,
    data: ThgCreatePartnerZipDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerZipViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/zip`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Zip
   * @name ZipControllerGetForPartner
   * @summary Gets allowed zips information for a partner
   * @request GET:/partner/{partnerId}/zip
   * @secure
   * @response `200` `ThgPartnerZipViewmodelGen` Allowed Zips per Partner
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  zipControllerGetForPartner = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerZipViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/zip`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Zip
   * @name ZipControllerCheckZipForPartner
   * @summary Gets allowed zips information for a partner
   * @request GET:/partner/{partnerId}/zip/{zip}
   * @response `200` `ThgCheckZipViewmodelGen` Allowed Zips per Partner
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  zipControllerCheckZipForPartner = (partnerId: string, zip: string, params: RequestParams = {}) =>
    this.http.request<ThgCheckZipViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/zip/${zip}`,
      method: "GET",
      format: "json",
      ...params
    });
}
