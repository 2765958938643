var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    staticClass: "ml-n7",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_vm.user ? _c('profile-form-fields', {
    attrs: {
      "isAddressRequired": _vm.isAddressRequired,
      "isTaxnumberRequired": _vm.isTaxnumberRequired,
      "isTaxDeductibleEnabled": _vm.isTaxDeductibleEnabled,
      "displayOptions": _vm.displayOptions,
      "user": _vm.user,
      "hideState": _vm.hideState,
      "disabled": _vm.isDisabled
    }
  }) : _vm._e(), _vm.showEdit ? _c('v-card-text', [_c('v-row', [_c('v-spacer'), _vm.isDisabled ? _c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "color": "primary",
      "data-test-profile-edit": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDisabled = !_vm.isDisabled;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.edit")) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('v-card-text', [_c('v-row', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-6",
    attrs: {
      "color": "primary",
      "disabled": !_vm.valid || _vm.isLoading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.save")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }