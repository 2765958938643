











































































































import ThgCheckoutSustainableCard from "@/components/thg/ThgCheckoutSustainableCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import { GtagModule } from "@/store/modules/gtag.store";
import ThgPromotionListItem from "@/components/thg/ThgPromotionListItem.vue";

@Component({
  components: {
    LayoutReportStep,
    ThgCheckoutSustainableCard,
    ThgPromotionListItem
  }
})
export default class ThgVehicleClass extends ReportStepMixin implements IReportStep {
  screen = ReportScreenEnum.thgVehicleClass;

  isLoading = false;

  promotion = "";

  get activePromotion() {
    return PartnerActiveOfferModule.currentSignUpPromotion;
  }

  get vehicleClassPreview() {
    return ThgCreateModule.vehicleClassPreview;
  }

  set vehicleClassPreview(isPreview: boolean) {
    ThgCreateModule.setVehicleClassPreview(isPreview);
  }

  get selectedYears() {
    return ThgCreateModule.years;
  }

  getPrice(year: number, vehicleClass: ThgVehicleTypes) {
    return PartnerActiveOfferModule.priceConfig.get({ year: year, vehicleClass: vehicleClass });
  }

  get showPreview() {
    return PartnerActiveOfferModule.priceConfig.vehicleClass.length > 3;
  }

  goToChargingStationOverview() {
    this.$router.push({ name: "ThgChargingStationOverview" });
  }

  get vehicleClass(): ThgVehicleTypes | undefined {
    if (!ThgCreateModule.vehicleClass && this.availableVehicleClasses.length) {
      this.vehicleClass = this.availableVehicleClasses[0].vehicleType;
    }
    return ThgCreateModule.vehicleClass;
  }

  set vehicleClass(vehicleClass: ThgVehicleTypes | undefined) {
    ThgCreateModule.setVehicleClass(vehicleClass);
  }

  public header: IReportHeaderData = {
    title: "report.thgVehicleClass.title",
    description: "report.thgVehicleClass.description"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgVehicleClass.instructionTitle",
    text: "report.thgVehicleClass.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  activeCardStyle(productId: ThgVehicleTypes) {
    if (this.vehicleClass === productId) {
      return "primary";
    }
    return "";
  }

  get availableVehicleClasses() {
    const vehicleClasses = PartnerActiveOfferModule.priceConfig.vehicleClass;
    if (this.vehicleClassPreview) {
      return vehicleClasses.slice(0, 3);
    }
    return vehicleClasses;
  }

  get isCharging() {
    return ReportModule.partner.settings?.bookedServices.chargingStation;
  }

  get company() {
    return ReportModule.partner.companyName;
  }

  get items() {
    return ThgCreateModule.yearSelections;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return true;
  }

  next() {
    GtagModule.dispatchAddToCart();
  }

  async mounted() {
    this.setCurrentStep();
  }
}
