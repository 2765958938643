var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug,
      "showFooter": false,
      "hasInstructions": false
    }
  }, [_c('v-card', {
    staticClass: "mx-auto pt-4",
    attrs: {
      "max-width": "800",
      "flat": ""
    }
  }, [_c('v-container', [_c('v-row', [_vm.showImpactSlider ? _c('thg-impact-factor-slider', {
    staticClass: "pr-4 pl-4",
    attrs: {
      "impactTypeItem": _vm.icon,
      "sliderValue": _vm.sliderValue
    }
  }) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.sliderValue != 0 ? _c('v-card', {
    staticClass: "mx-auto mb-5",
    attrs: {
      "max-width": "500"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "height": "130",
      "src": _vm.item.src,
      "gradient": "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)"
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.cashImpactInvestment) + " % " + _vm._s(_vm.$t(_vm.item.title, {
    company: _vm.company
  })) + " " + _vm._s(_vm.isRetried ? "" : "*"))]), _c('v-card-subtitle', {
    staticClass: "pb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.item.subtitle)) + " ")])], 1)], 1) : _vm._e(), _vm.sliderValue != 100 ? _c('v-card', {
    staticClass: "mx-auto mb-5",
    attrs: {
      "max-width": "500"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "height": "130",
      "src": _vm.itemPayout.src,
      "gradient": "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)"
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.cashPayment) + " % " + _vm._s(_vm.$t("report.thgImpactTypeItems.payoutTitle")) + "*")]), _c('v-card-subtitle', {
    staticClass: "pb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.itemPayout.subtitle)) + " ")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _vm.isRetried ? _c('v-subheader') : !_vm.isWhiteLabelPartner ? _c('v-subheader', {
    staticClass: "mb-4"
  }, [_c('small', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('report.thgImpactFactor.note'))
    }
  })]) : _c('v-subheader', {
    staticClass: "mb-4"
  }, [_c('small', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('report.thgImpactFactor.noteWhiteLabelPartner'))
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }