/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Defines how the quota should be calculated
 */
export enum ThgCalculationMethodEnum {
  /**
   * Use statistic value for calculation of ghg quota
   */
  STATISTIC = "statistic",

  /**
   * Use exact measurement for calculation of ghg quota
   */
  EXACT = "exact"
}
