import affiliateService from "@/services/thg/services/affiliateService";
import { ThgShortAffiliateViewmodelGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "affiliate",
  store
})
export class AffiliateStore extends VuexModule {
  /**
   * Defines the amount of the bonus in euro
   */
  private _myAffiliates: ThgShortAffiliateViewmodelGen[] = [];

  get myAffiliates(): ThgShortAffiliateViewmodelGen[] {
    return this._myAffiliates;
  }

  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Mutation
  _mutateMyAffiliates(myAffiliates: []) {
    this._myAffiliates = myAffiliates;
  }

  @Mutation
  _mutateIsLoading(isLaoding: boolean) {
    this._isLoading = isLaoding;
  }

  @Action
  async getMyAffiliates() {
    const myAffiliates: any[] = await affiliateService.getMyAffiliateCodes();
    myAffiliates.sort(function(a, b) {
      return a.timestamp.created > b.timestamp.created ? -1 : a.timestamp.created > b.timestamp.created ? 1 : 0;
    });
    this.context.commit("_mutateMyAffiliates", myAffiliates);
  }
}

export const AffiliateModule = getModule(AffiliateStore);
