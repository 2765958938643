import { ReportModule } from "@/store/modules/report.store";
import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { Affiliate } from "../v1/Affiliate";
import {
  ThgAffiliateViewmodelGen,
  ThgCreateAffiliateDtoGen,
  ThgShortAffiliateViewmodelGen,
  ThgUpdateAffiliateDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "./../v1/http-client";

/**
 * Communicates with the thg module (implementet in thg api)
 */
export class AffiliateService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Affiliate;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Affiliate(this.client);
  }
  /**
   *
   * Gets the Affiliate code by authinfo
   *
   * @returns
   */
  async getMyAffiliateCodes(): Promise<ThgShortAffiliateViewmodelGen[]> {
    const response = await this.proxy.affiliateControllerFindOrCreateMyAffiliates(
      ReportModule.partner.id || ReportModule.partner._id
    );

    return response.data;
  }

  /**
   *
   * Gets the Affiliates for partnerId
   *
   * @returns
   */
  async getAllAffiliate(partnerId: string): Promise<ThgAffiliateViewmodelGen[]> {
    const response = await this.proxy.affiliateControllerFindAll(partnerId);

    return response.data;
  }

  /**
   *
   * Gets a Affiliate by partnerId and affiliateId
   *
   * @returns
   */
  async getAffiliate(partnerId: string, affiliateId: string): Promise<ThgAffiliateViewmodelGen> {
    const response = await this.proxy.affiliateControllerFind(partnerId, affiliateId);

    return response.data;
  }

  /**
   *
   * updates an affiliate by partnerId and thgId
   *
   * @param partnerId
   * @param affiliateId
   * @param updateDto
   * @returns
   */
  async updateAffiliate(
    partnerId: string,
    affiliateId: string,
    updateDto: ThgUpdateAffiliateDtoGen
  ): Promise<ThgAffiliateViewmodelGen> {
    const response = await this.proxy.affiliateControllerUpdate(partnerId, affiliateId, updateDto);

    return response.data;
  }

  /**
   *
   * creates an affiliate for a specific partnerId using a createDto
   *
   * @param partnerId
   * @param createDto
   * @returns
   */
  async createAffiliate(partnerId: string, createDto: ThgCreateAffiliateDtoGen): Promise<ThgAffiliateViewmodelGen> {
    const response = await this.proxy.affiliateControllerAddAffiliate(partnerId, createDto);

    return response.data;
  }

  /**
   * gets an affiliate by code
   *
   * @param partnerId
   * @param code
   * @returns
   */
  async getAffiliateByCode(partnerId: string, code: string): Promise<ThgAffiliateViewmodelGen> {
    const response = await this.proxy.affiliateControllerFindByCode(partnerId, code);

    return response.data;
  }

  /**
   * gets an affiliate by userId
   *
   * @param userId
   * @returns
   */
  async getAffiliatesByUserId(userId: string): Promise<ThgAffiliateViewmodelGen[]> {
    const response = await this.proxy.affiliateControllerFindAllByUserId(userId);

    return response.data;
  }

  /**
   * Gets many affiliate documents from a list of codes
   */
  async getManyAffiliatesByCode(codes: string[]): Promise<ThgAffiliateViewmodelGen[]> {
    return (await this.proxy.affiliateControllerFindManyByCode(codes)).data;
  }
}

export default new AffiliateService(new ThgHttpClientProvider());
