
























import ThgVehicleCard from "@/components/thg/ThgVehicleCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartnerColor } from "@/lib/partnerColor";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    ThgVehicleCard
  }
})
export default class ThgVehicleCardIterator extends Vue {
  @Prop()
  myThgs!: ThgThgMeViewModelGen[];

  search = "";

  newCar() {
    this.$router.push({ path: "/thghome" });
  }

  partnerColor = new PartnerColor();

  refresh() {
    this.$emit("refresh");
  }
}
