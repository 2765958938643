var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug,
      "showFooter": false,
      "hasInstructions": true,
      "instructionPictures": _vm.instruction.pictures,
      "instructionText": _vm.instruction.text,
      "instructionTitle": _vm.instruction.title
    },
    on: {
      "next": _vm.next
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.impactType,
      callback: function callback($$v) {
        _vm.impactType = $$v;
      },
      expression: "impactType"
    }
  }, [_c('v-sheet', {
    staticClass: "mb-2 py-n6",
    attrs: {
      "outlined": "",
      "rounded": "",
      "color": _vm.activeCardStyle(_vm.payout)
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.impactType = _vm.payout;
      }
    }
  }, [_c('v-list', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "60"
    }
  }, [_c('v-img', {
    staticStyle: {
      "border-radius": "4px"
    },
    attrs: {
      "src": _vm.payoutImage
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("report.thgPayoutConfiguration.payout.title")) + " ")]), _c('v-list-item-subtitle', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t("report.thgPayoutConfiguration.payout.subtitle1")) + " ")], 1), _c('v-list-item-subtitle', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t("report.thgPayoutConfiguration.payout.subtitle2")) + " ")], 1)], 1), _c('v-list-item-action', [_c('v-radio', {
    attrs: {
      "value": _vm.payout
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mb-2 py-n6",
    attrs: {
      "outlined": "",
      "rounded": "",
      "color": _vm.activeCardStyle(_vm.customerAccount)
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.impactType = _vm.customerAccount;
      }
    }
  }, [_c('v-list', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "60"
    }
  }, [_c('v-img', {
    staticStyle: {
      "border-radius": "4px"
    },
    attrs: {
      "src": _vm.customerAccountImage
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("report.thgPayoutConfiguration.customerAccount.title")) + " ")]), _c('v-list-item-subtitle', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t("report.thgPayoutConfiguration.customerAccount.subtitle1", {
    company: _vm.partnerName
  })) + " ")], 1), _c('v-list-item-subtitle', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t("report.thgPayoutConfiguration.customerAccount.subtitle2")) + " ")], 1)], 1), _c('v-list-item-action', [_c('v-radio', {
    attrs: {
      "value": _vm.customerAccount
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }