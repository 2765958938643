import { ThgChargingPointGen } from "@/services/thg/v1/data-contracts";

/**
 * The type of charging point.
 */
export enum ChargingPointType {
  NORMAL = "Normalladepunkt",
  QUICK = "Schnellladepunkt"
}

/**
 * The type of charging point.
 */
export enum ChargingPointConnector {
  TYPE_2_AC_STECKDOSE = "Typ 2 Steckdose (AC)",
  TYPE_2_AC_KUPPLUNG = "Typ 2 Fahrzeugkupplung (AC)",
  TYPE_2_DC_KUPPLUNG_COMBO = "Combo Typ 2 - CCS Fahrzeugkupplung (DC)",
  SCHUKO_AC = "Schuko (Haushaltssteckdose)",
  CHADEMO_DC = "CHAdeMO (DC)",
  OTHERS = "Sonstiges"
}

/**
 * @inheritdoc
 */
export class CreateChargingPoint implements ThgChargingPointGen {
  /**
   * @inheritdoc
   */
  connectionPowerInKwh = 22;

  /**
   * @inheritdoc
   */
  type: ChargingPointType = ChargingPointType.NORMAL;

  /**
   * @inheritdoc
   */
  connector: ChargingPointConnector[] = [ChargingPointConnector.TYPE_2_AC_STECKDOSE];

  /**
   * @inheritdoc
   */
  publicKey?: string;
}
