var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FileUploadMulti', {
    attrs: {
      "instruction": _vm.instruction,
      "snackbarTitle": _vm.multiSteps.frontLeft.text,
      "example": _vm.multiSteps.frontLeft.example,
      "overlay": _vm.multiSteps.frontLeft.overlay,
      "text": _vm.multiSteps.frontLeft.text,
      "textAlt": _vm.multiSteps.frontLeft.textAlt,
      "filesCb": _vm.multiSteps.frontLeft.filesCb,
      "displayImages": _vm.getRegistrationImageFront,
      "handleDeleteCb": _vm.multiSteps.frontLeft.handleDelete,
      "handleUploadCb": _vm.multiSteps.frontLeft.handleUpload,
      "maxFiles": 1,
      "data-test-registration-front": ""
    }
  }), _c('FileUploadMulti', {
    attrs: {
      "instruction": _vm.instruction,
      "snackbarTitle": _vm.multiSteps.rearLeft.text,
      "example": _vm.multiSteps.rearLeft.example,
      "overlay": _vm.multiSteps.rearLeft.overlay,
      "text": _vm.multiSteps.rearLeft.text,
      "textAlt": _vm.multiSteps.rearLeft.textAlt,
      "filesCb": _vm.multiSteps.rearLeft.filesCb,
      "displayImages": _vm.getRegistrationImageBack,
      "handleDeleteCb": _vm.multiSteps.rearLeft.handleDelete,
      "handleUploadCb": _vm.multiSteps.rearLeft.handleUpload,
      "maxFiles": 1,
      "data-test-registration-back": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }