








































import ThgCheckoutSustainableCard from "@/components/thg/ThgCheckoutSustainableCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "./enum/impact-type.enum";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    LayoutReportStep,
    ThgCheckoutSustainableCard
  }
})
export default class ThgCustomerAccount extends ReportStepMixin implements IReportStep {
  screen = ReportScreenEnum.thgCustomerAccount;
  isLoading = false;
  customerAccount = ImpactTypeEnum.customerAccount;
  customerAccountImage = "https://static.mmmint.ai/wirkaufendeinethg/customerAccount-1.jpg";

  public header: IReportHeaderData = {
    title: "report.thgImpactSelector.titleCustomerAccount",
    description: "report.thgImpactSelector.descriptionCustomerAccount"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgImpactSelector.instructionTitleCustomerAccount",
    text: "report.thgImpactSelector.instructionTextCustomerAccount",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };
  get partnerName() {
    return ReportModule.partner.companyName;
  }

  mounted() {
    ThgCreateModule.setActiveImpactTypeItems([ImpactTypeEnum.customerAccount]);
    ThgCreateModule.setImpactType(this.customerAccount);
    ThgCreateModule.setImpactFactor(ThgCreateModule.impactTypeItem.baseImpactFactor);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return true;
  }
}
