













































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard,
    FileUploadMulti
  }
})
export default class ThgQuoteUpdateRegistrationImageDialog extends DarkModeHighlightMixin {
  bigButton = false;
  isLoading = false;
  dialog = false;

  title = "components.thg.ThgUpdateRegistrationImagesDialog.title";
  btnText = "components.thg.ThgUpdateRegistrationImagesDialog.btnText";
  btnTextAlt = "components.thg.ThgUpdateRegistrationImagesDialog.title";
  text = "components.thg.ThgUpdateRegistrationImagesDialog.text";

  multiSteps = {
    frontLeft: {
      example: AssetEnum.registration,
      overlay: AssetEnum.registrationSchema,
      text: "report.thgRegistration.registrationFront",
      textAlt: "report.thgRegistration.registrationFrontAlt",
      filesCb: ThgCreateModule.registrationImageFront,
      handleUpload: ThgCreateModule.updateRegistrationFront,
      handleDelete: ThgCreateModule.removeRegistrationFront
    },
    rearLeft: {
      example: AssetEnum.registrationBack,
      overlay: AssetEnum.registrationBackOutline,
      text: "report.thgRegistration.registrationBack",
      textAlt: "report.thgRegistration.registrationBackAlt",
      filesCb: ThgCreateModule.registrationImageBack,
      handleUpload: ThgCreateModule.updateRegistrationBack,
      handleDelete: ThgCreateModule.removeFromRegistrationImageBack
    }
  };

  instruction: IReportInstructionData = {
    title: "report.thgRegistration.title",
    text: "report.thgRegistration.instructionText",
    pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
    example: AssetEnum.registrationExample,
    display: true
  };

  get valid() {
    return ThgCreateModule.registrationImageFront.length === 1 && ThgCreateModule.registrationImageBack.length === 1;
  }

  get getRegistrationImageFront() {
    return ThgCreateModule.registrationImageFront;
  }

  get getRegistrationImageBack() {
    return ThgCreateModule.registrationImageBack;
  }

  get loading() {
    return this.isLoading;
  }

  async update() {
    this.isLoading = true;
    try {
      await ThgPortalModule.updateRegistrationImages();
    } catch (error) {
      this.$toast.error(this.$t("components.thg.ThgUpdateRegistrationImagesDialog.error").toString());
      ErrorLogModule.addErrorLog({
        name: "Update registrationimage error",
        message: "Could not update registration image."
      });
    } finally {
      this.isLoading = false;
      this.dialog = false;
    }
  }

  initialize() {
    this.isLoading = true;
    this.isLoading = false;
  }

  close() {
    this.dialog = false;
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }
}
