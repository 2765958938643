





















import ThgMeterReadingForm from "@/components/thg/ThgMeterReadingForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { HttpException } from "@/lib/exceptions/http";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { CreateMeterReading } from "@/store/models/thg/meter-reading";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReportStep, ThgMeterReadingForm }
})
export default class ThgMeterReadingCreate extends Vue {
  loading = false;
  chargingStationId = this.$route.params.chargingStationId;

  header: IReportHeaderData = {
    title: "report.thgMeterReadingCreate.title",
    description: "report.thgMeterReadingCreate.description"
  };

  instruction: IReportInstructionData = {
    title: "report.thgMeterReadingCreate.title",
    text: "report.thgMeterReadingCreate.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  async save(data: CreateMeterReading) {
    const partnerId = PartnerModule.partner.id || PartnerModule.partner._id;
    this.$log.debug(data);
    try {
      this.loading = true;
      await MeterReadingModule.createMeterReading({ partnerId, data });
      this.$toast.success(this.$t("report.thgMeterReadingCreate.success"));
      this.$router.push({ name: "ThgChargingStationDetail" });
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }

  get isDone(): boolean {
    return true;
  }
}
