



















































import { PartnerColor } from "@/lib/partnerColor";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { ChargingStationUiDto } from "@/store/models/thg/create-charging-station";
import { HttpException } from "@/lib/exceptions/http";
import ThgChargingStationForm from "@/components/thg/ThgChargingStationForm.vue";

@Component({
  components: { ThgChargingStationForm }
})
export default class ThgChargingStationFormDialog extends DarkModeHighlightMixin {
  @Prop({ default: false })
  overflowBtn!: boolean;

  dialog = false;
  loading = false;

  async save(item: ChargingStationUiDto) {
    this.$log.debug(item);
    try {
      this.loading = true;
      await ChargingStationModule.createChargingStation(item.toCreateMyChargingStation());

      if (ChargingStationModule.activeChargingStation) {
        this.$router.push({
          name: "ThgChargingStationDetail",
          params: { chargingStationId: ChargingStationModule.activeChargingStation.id }
        });
      } else {
        this.$router.push({ name: "ThgChargingStationOverview" });
      }
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }

  openDialog() {
    this.dialog = true;
  }

  close() {
    this.dialog = false;
  }

  partnerColor = new PartnerColor();
}
