
import BadConscienceDialog from "@/components/thg/BadConscienceDialog.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import { ImpactTypeEnum } from "./enum/impact-type.enum";
import ThgImpactSelector from "./ThgImpactSelector.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { AssetEnum } from "@/lib/AssetRepository";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    LayoutReportStep,
    BadConscienceDialog
  }
})
export default class ThgImpactSelectorOnlyPayout extends ThgImpactSelector implements IReportStep {
  screen = ReportScreenEnum.thgImpactSelectorOnlyPayout;
  mounted() {
    this.setCurrentStep();
    ThgCreateModule.setActiveImpactTypeItems([ImpactTypeEnum.payout]);
    ThgCreateModule.setImpactType(ImpactTypeEnum.payout);
    ThgCreateModule.setImpactFactor(0);
    if (this.$route.query.campaign) {
      ThgCreateModule.setCampaign((this.$route.query.campaign as string) || "");
    }
  }

  public header: IReportHeaderData = {
    title: "report.thgImpactSelector.instructionTitleOnlyPayout",
    description: "report.thgImpactSelector.instructionTextOnlyPayout"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgImpactSelector.instructionTitleOnlyPayout",
    text: "report.thgImpactSelector.instructionTextOnlyPayout",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };
}
