






























































































































































































































































































import { HttpException } from "@/lib/exceptions/http";
import { germanZipRule } from "@/lib/rules/contactRule";
import { beforeCurrentDateRule, dateRule } from "@/lib/rules/dateRule";
import { evseOutletRule } from "@/lib/rules/evseRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import partnerService from "@/services/shared/partnerService";
import {
  ThgChargingStationDocumentTypeDtoGen,
  ThgChargingStationProofDocumentDtoGen,
  ThgCreateChargingStationDtoGen
} from "@/services/thg/v1/data-contracts";
import { ChargingPointConnector, ChargingPointType } from "@/store/models/thg/create-charging-point";
import { ChargingStationUiDto } from "@/store/models/thg/create-charging-station";
import { FeatureModule } from "@/store/modules/feature.store";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ThgChargingStationProof from "./ThgChargingStationProof.vue";
import ThgChargingStationProofDocumentTable from "./ThgChargingStationProofDocumentTable.vue";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { PartnerColor } from "@/lib/partnerColor";
import GeoFinderField from "@/components/utility/GeoFinderField.vue";

@Component({
  components: {
    ThgChargingStationProof,
    ThgChargingStationProofDocumentTable,
    GeoFinderField
  }
})
export default class ThgChargingStationForm extends Vue {
  /**
   * Property that can pass an existing station or create a new empty one.
   */
  @Prop()
  value!: ThgCreateChargingStationDtoGen;

  @Prop({ default: false })
  isEdit!: boolean;

  chargingStation = new ChargingStationUiDto(this.value, this.user, this.countryCode);

  /**
   * Enables certain form properties, e.g. disables the geo location for end users, but enables this for the admin components.
   */
  @Prop({ type: Boolean, default: false })
  detail!: boolean;

  /**
   * Distinguish between submission for an individual or a company.
   * Only allow company submissions for the moment
   */
  isCompany = true;

  /**
   * files added by the user as proof
   */
  files: ThgChargingStationProofDocumentDtoGen[] = [];

  /**
   * Form settings
   */
  minChargingPointAmount = 1;
  maxChargingPointAmount = 8;
  recommendedMaxChargingPointAmount = 4;
  minConnectionPower = 1;

  /**
   * Internal state for the form element to enable submission on complete.
   */
  valid = false;

  /**
   * Internal loading state
   */
  loading = false;

  powerOutletId = [];

  partnerColor = new PartnerColor();

  get partner() {
    return PartnerModule.partner;
  }

  get user() {
    return UserModule.user;
  }

  get isEvseEnabled() {
    return FeatureModule.isEvseEnabled;
  }

  get countryCode(): string {
    if (!this.value || !this.value.address) {
      return this.partner.countryCode;
    }

    return this.value.address.countryCode || this.partner.countryCode || CountryCodeEnum.germany;
  }

  get idType() {
    return "E";
  }

  get evseId() {
    if (!this.chargingStation.address || !this.chargingStation.chargingPointOperator) {
      return "";
    }

    return `${this.chargingStation.address.countryCode}*${this.chargingStation.chargingPointOperator.evseOperatorId}*${this.idType}`;
  }

  get isRenewableChargingStationEnabled() {
    return FeatureModule.isRenewableChargingStationEnabled;
  }

  /**
   * Return the date in the format YYYY-MM-DD so the vuetify component can handle the input
   */
  get commissioningDate() {
    if (!this.chargingStation.commissioningDate) {
      return "";
    }

    return this.chargingStation.commissioningDate.substring(0, 10);
  }

  /**
   * Convert the string returned from the component to a date and store as iso string expected by backend.
   * On invalid date the conversion should fail and date is empty, which results in the backend to fail.
   */
  set commissioningDate(dateString: string) {
    const date = new Date(dateString);
    if (!date) {
      return;
    }

    this.chargingStation.commissioningDate = date.toISOString();
  }

  /**
   * Return the date in the format YYYY-MM-DD so the vuetify component can handle the input
   */
  get registrationDate() {
    if (!this.chargingStation.registrationDate) {
      return "";
    }

    return this.chargingStation.registrationDate.substring(0, 10);
  }

  /**
   * Convert the string returned from the component to a date and store as iso string expected by backend.
   * On invalid date the conversion should fail and date is empty, which results in the backend to fail.
   */
  set registrationDate(dateString: string) {
    const date = new Date(dateString);
    if (!date) {
      return;
    }

    this.chargingStation.registrationDate = date.toISOString();
  }

  get isRenewableEnergyItems() {
    return [
      { text: this.$t("components.thg.ThgChargingStationForm.isRenewable.true"), value: true },
      { text: this.$t("components.thg.ThgChargingStationForm.isRenewable.false"), value: false }
    ];
  }

  get isRegisteredItems() {
    return [
      { text: this.$t("components.thg.ThgChargingStationForm.isCompany.true"), value: true },
      { text: this.$t("components.thg.ThgChargingStationForm.isCompany.false"), value: false }
    ];
  }

  get germanZipRule() {
    return [germanZipRule(), requiredRule()];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get evseOutletRule() {
    return [evseOutletRule()];
  }

  /**
   *
   * Bnetza Operator ID contains only numbers
   * Bnetza Operato ID is not greater than 10.000, since right now the number contains 4 digits, we allow 5
   *
   */
  get bnetzaOperatorIdRule() {
    return [
      requiredRule(),
      isNumberRule(`components.thg.ThgChargingStationForm.${this.countryCode}.bnetzaOperatorIdNumberRule`),
      numberLowerRule(
        100000,
        `components.thg.ThgChargingStationForm.${this.countryCode}.bnetzaOperatorIdNumberLowerRule`
      )
    ];
  }

  get dateRule() {
    return [dateRule(), beforeCurrentDateRule()];
  }

  get numberRule() {
    return [isNumberRule(), requiredRule()];
  }

  get chargingPointNumberRule() {
    return [
      isNumberRule(),
      requiredRule(),
      numberHigherRule(this.minChargingPointAmount),
      numberLowerRule(this.maxChargingPointAmount)
    ];
  }

  get chargingPointAmountHint() {
    return this.chargingStation.chargingPointCount > this.recommendedMaxChargingPointAmount
      ? this.$t("components.thg.ThgChargingStationForm.chargingPointCountHintRecommended")
      : this.$t("components.thg.ThgChargingStationForm.chargingPointCountHint");
  }

  get chargingPointAmountSliderColor() {
    return this.chargingStation.chargingPointCount > this.recommendedMaxChargingPointAmount
      ? this.partnerColor.secondaryDarken_1
      : this.partnerColor.primary;
  }

  get connectionPowerRule() {
    return [isNumberRule(), requiredRule(), numberHigherRule(this.minConnectionPower)];
  }

  get chargingPointTypes() {
    return ChargingPointType;
  }

  get chargingPointConnector() {
    return ChargingPointConnector;
  }

  get isBnetzA() {
    return this.chargingStation.address.countryCode === "DE";
  }

  @Watch("chargingStation.isFromRenewableEnergy")
  clearFiles() {
    /** Reset files and description when clicked back to false */
    if (!this.chargingStation.isFromRenewableEnergy) {
      this.chargingStation.renewableEnergySourceDescription = "";
      this.files = [];
    }
  }

  onFileUpload(file: ThgChargingStationProofDocumentDtoGen) {
    this.files.push(file);
  }

  onRenewableDescriptionChange(description: string) {
    this.chargingStation.renewableEnergySourceDescription = description;
  }

  /**
   * Emits a save event passing the updated property to the parent.
   */
  save() {
    this.chargingStation.chargingPoints = [];
    if (this.isEvseEnabled) {
      for (let x = 0; x < this.chargingStation.chargingPointCount; x++) {
        this.$log.debug(`Adding evseId ${this.powerOutletId[x]}`);
        this.chargingStation.chargingPoints[x] = { evseId: this.powerOutletId[x] };
      }
    }

    this.chargingStation.refs = this.files.map(file => {
      return {
        refType: file.extensionType,
        refId: file.id
      } as ThgChargingStationDocumentTypeDtoGen;
    });

    this.$log.debug(this.chargingStation);
    this.$emit("save", this.chargingStation);
  }
  // TODO: Used in another component
  async getLocation() {
    this.loading = true;
    const searchstring = `${this.chargingStation.address.street} ${this.chargingStation.address.zip} ${this.chargingStation.address.city} ${this.chargingStation.address.state}`;
    this.$log.warn(searchstring);
    try {
      const searchstring = `${this.chargingStation.address.street} ${this.chargingStation.address.zip} ${this.chargingStation.address.city} ${this.chargingStation.address.state}`;

      const geoResult = await partnerService.geocodehelper(searchstring);

      this.$log.warn(geoResult);

      if (geoResult && geoResult.type != "Point") {
        this.$toast.error("Error fetching geo");
        return;
      }

      this.chargingStation.address.geo.lat = geoResult.coordinates[1];
      this.chargingStation.address.geo.lng = geoResult.coordinates[0];
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
