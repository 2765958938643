













































import ImageDialog from "@/components/utility/ImageDialog.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Prop } from "vue-property-decorator";
import ThgContactScreensMixin from "./mixins/ThgContactScreensMixin.vue";
import { mixins } from "vue-class-component";
import ThgRegistrationScreensMixin from "./mixins/ThgRegistrationScreensMixin.vue";

@Component({
  components: {
    ImageDialog
  }
})
export default class ThgCheckoutVehicleCard extends mixins(ThgContactScreensMixin, ThgRegistrationScreensMixin) {
  @Prop({ default: false })
  hideEdit?: boolean;

  get numberplate(): string {
    return ThgCreateModule.numberplate;
  }

  showValueOrDefault(value: string, title: string) {
    if (!value.replace(" ", "")) {
      return this.$t("components.thg.ThgCreateOverviewCard.defaultValue", { title: this.$t(title) });
    }

    return value;
  }

  goToContactData() {
    const screenName = this.contactScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.thgcontact"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.thgcontact"));
    }
  }

  goToRegistration() {
    const screenName = this.registrationScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.registration"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.registration"));
    }
  }

  goToScreen(screenName: ReportScreenEnum) {
    ReportModule.setStep(screenName);
    this.$router.push({ name: screenName });
  }

  get isRegistrationFront(): number {
    return ThgCreateModule.registrationImageFront.length;
  }

  fileToUrl(imageUploaded: IImageUploaded) {
    if (!imageUploaded || !imageUploaded.file) {
      return "";
    }

    let url = "";
    try {
      url = URL.createObjectURL(imageUploaded.file);
    } catch (error) {
      this.$log.error(error);
    }

    return url;
  }

  get getRegistrationImageFront(): string {
    if (ThgCreateModule.registrationImageFront.length === 0) {
      return "";
    }
    return this.fileToUrl(ThgCreateModule.registrationImageFront[0]);
  }
}
