





















































import { PartnerColor } from "@/lib/partnerColor";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { IThgImpact } from "@/views/thg/interface/ThgImpact.interface";
import { ThgCreateModule } from "@/store/modules/thg.create.store";

@Component({
  components: {}
})
export default class ThgCheckoutSustainableFactorDialog extends DarkModeHighlightMixin {
  @Prop()
  item!: IThgImpact;

  UPDATE_IMPACTFACTOR = "update";
  value = 0;

  dialog = false;

  predefinedValues = [5, 10, 30, 50, 100];

  setCurrentImapact() {
    this.value = ThgCreateModule.impactFactor;
  }

  close() {
    this.dialog = false;
  }

  save() {
    this.dialog = false;
    this.$emit(this.UPDATE_IMPACTFACTOR, this.value);
  }

  partnerColor = new PartnerColor();
}
