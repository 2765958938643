/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAccountControllerCreateAccountParamsGen,
  ThgAccountControllerFindAllParamsGen,
  ThgAccountDtoGen,
  ThgAccountViewmodelGen,
  ThgExceptionViewmodelGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgUpdateAccountDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Account<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags account
   * @name AccountControllerCreateAccount
   * @summary Create a new Account
   * @request POST:/account
   * @secure
   * @response `201` `ThgAccountViewmodelGen` The created Account
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerCreateAccount = (
    query: ThgAccountControllerCreateAccountParamsGen,
    data: ThgAccountDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAccountViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/account`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags account
   * @name AccountControllerFindAll
   * @summary Finds all Accounts
   * @request GET:/account
   * @secure
   * @response `200` `((ThgPageViewModelGen & { data?: (ThgAccountViewmodelGen)[] }))`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerFindAll = (query: ThgAccountControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgAccountViewmodelGen[] }, ThgExceptionViewmodelGen>({
      path: `/account`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags account
   * @name AccountControllerValidityCheck
   * @summary (ADMIN) Check if all account numbers are there and that there is no gap
   * @request POST:/account/check
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` ID of operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerValidityCheck = (params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/account/check`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags account
   * @name AccountControllerFindMe
   * @summary Get account number for user
   * @request GET:/account/me
   * @secure
   * @response `201` `number` The users account number
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerFindMe = (params: RequestParams = {}) =>
    this.http.request<number, ThgExceptionViewmodelGen>({
      path: `/account/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags account
   * @name AccountControllerFindById
   * @summary Finds Account by Id
   * @request GET:/account/{accountId}
   * @secure
   * @response `201` `ThgAccountViewmodelGen` Specific Accounts
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerFindById = (accountId: string, params: RequestParams = {}) =>
    this.http.request<ThgAccountViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/account/${accountId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags account
   * @name AccountControllerUpdate
   * @summary Updates Account with id
   * @request PATCH:/account/{accountId}
   * @secure
   * @response `201` `ThgAccountViewmodelGen` Specific Accounts
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerUpdate = (accountId: string, data: ThgUpdateAccountDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgAccountViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/account/${accountId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags account
   * @name AccountControllerDeleteByid
   * @summary Delete Account by id
   * @request DELETE:/account/{accountId}
   * @secure
   * @response `200` `ThgAccountViewmodelGen` Specific Accounts
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  accountControllerDeleteByid = (accountId: string, params: RequestParams = {}) =>
    this.http.request<ThgAccountViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/account/${accountId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
