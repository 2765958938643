var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "my-5",
    attrs: {
      "max-width": "600"
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "height": "100px",
      "min-width": "200px",
      "gradient": "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)",
      "src": _vm.thgImpactImage(_vm.myThg.impactType).src
    }
  }, [_c('v-card-title', [_vm._v(" Kalenderjahr: " + _vm._s(_vm.myThg.year) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.myThg.numberplate + " " + _vm.thgImpactImage(_vm.myThg.impactType).smiley) + " "), _c('v-spacer')], 1)], 1), _vm.myThg.promotionConfiguration ? _c('v-alert', {
    attrs: {
      "type": "success"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "large": ""
          }
        }, [_vm._v(" mdi-gift ")])];
      },
      proxy: true
    }], null, false, 2205110082)
  }, [_c('div', {
    attrs: {
      "align": "end"
    }
  }, [_c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.PromotionTypeEnum.".concat(_vm.myThg.promotionConfiguration.type)).toUpperCase()) + " ")]), _c('br'), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.promotionValue) + " ")])])]) : _vm._e(), _c('v-card-subtitle', {
    staticClass: "pb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgVehicleCard.subTitle", {
    date: _vm.dateSimple
  })) + " ")]), _c('v-card-text', {
    staticClass: "text--primary"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.thgStatus(_vm.myThg.status).text)) + " ")]), _vm.requestEmissionCalculationData(_vm.myThg.status) ? _c('v-row', [_c('v-col', {
    staticClass: "ma-4"
  }, [_c('thg-emission-calculation-dialog', {
    attrs: {
      "thg": _vm.myThg
    }
  })], 1)], 1) : _vm._e(), _c('v-divider'), _vm.isQuickCheckoutEnabled && _vm.myThg.quickCheckoutEnabledFor && _vm.myThg.quickCheckoutEnabledFor.length > 0 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-quick-checkout', {
    staticClass: "ma-4",
    attrs: {
      "thg": _vm.myThg
    },
    on: {
      "refresh": _vm.refresh
    }
  })], 1)], 1) : _vm._e(), _vm.isQuickCheckoutEnabled && _vm.myThg.quickCheckoutEnabledFor && _vm.myThg.quickCheckoutEnabledFor.length > 0 ? _c('v-divider') : _vm._e(), _c('v-row', [_vm.cashImpactInvestment ? _c('v-col', [_c('v-card-title', {
    staticClass: "text--primary"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.toolTip")))])];
      }
    }], null, false, 1994962536)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t(_vm.thgImpactImage(_vm.myThg.impactType).descriptionAlt)))])])], 1), _vm.cashImpactInvestment ? _c('v-progress-circular', {
    staticClass: "mx-4 mb-4",
    attrs: {
      "size": 100,
      "rotate": -90,
      "width": 15,
      "value": _vm.cashImpactInvestment,
      "color": "green"
    }
  }, [_vm._v(" " + _vm._s(_vm.cashImpactInvestment) + " %* ")]) : _vm._e()], 1) : _vm._e(), _vm.cashPayment ? _c('v-col', [_c('v-card-title', {
    staticClass: "text--primary"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.payout")))])];
      }
    }], null, false, 66065947)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.payoutToolTip")))])])], 1), _c('v-progress-circular', {
    staticClass: "mx-4 mb-4",
    attrs: {
      "size": 100,
      "rotate": -90,
      "width": 15,
      "value": _vm.cashPayment,
      "color": "blue"
    }
  }, [_vm._v(" " + _vm._s(_vm.cashPayment) + " %* ")])], 1) : _vm._e(), _vm.customerAccount ? _c('v-col', [_c('v-card-text', {
    staticClass: "text--primary mt-10"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.customerAccount")))])], 1) : _vm._e(), !_vm.cashPayment && !_vm.customerAccount ? _c('v-col', [_c('v-card-text', {
    staticClass: "text--primary mt-10"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.impactAlt")))])], 1) : _vm._e(), !_vm.cashImpactInvestment && !_vm.customerAccount ? _c('v-col', [_c('v-card-text', {
    staticClass: "text--primary mt-10"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.noImpact")))])], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "text--primary"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t("components.thg.ThgVehicleCard.registration")))]), _c('v-spacer'), _vm.showRegistrationUpdateDialog ? _c('thg-quote-vehicle-update-registration-dialog', {
    attrs: {
      "selectedThg": _vm.myThg
    }
  }) : _vm._e()], 1), !_vm.showRegistrationImageUpdateDialog ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.registrationImageFront ? _c('image-dialog', {
    attrs: {
      "src": _vm.registrationImageFront,
      "title": "Vorderseite"
    }
  }) : _vm._e(), _vm.registrationImageBack ? _c('image-dialog', {
    attrs: {
      "src": _vm.registrationImageBack,
      "title": "Rückseite"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "disabled": "",
      "label": _vm.$t('components.thg.ThgRegistrationForm.owner'),
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.fullname,
      callback: function callback($$v) {
        _vm.fullname = $$v;
      },
      expression: "fullname"
    }
  }), _c('v-text-field', {
    staticClass: "mr-4 mt-n4",
    attrs: {
      "disabled": "",
      "label": _vm.$t('components.thg.ThgRegistrationForm.firstregistration'),
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.firstRegistrationDate,
      callback: function callback($$v) {
        _vm.firstRegistrationDate = $$v;
      },
      expression: "firstRegistrationDate"
    }
  }), _c('v-text-field', {
    staticClass: "mr-4 mt-n4",
    attrs: {
      "disabled": "",
      "label": _vm.$t('components.thg.ThgRegistrationForm.identificationnumber'),
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.myThg.registration.identificationnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.myThg.registration, "identificationnumber", $$v);
      },
      expression: "myThg.registration.identificationnumber"
    }
  }), _c('v-text-field', {
    staticClass: "mr-4 mt-n4",
    attrs: {
      "disabled": "",
      "label": _vm.$t('components.thg.ThgRegistrationForm.driveTyp'),
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.myThg.registration.driveTyp,
      callback: function callback($$v) {
        _vm.$set(_vm.myThg.registration, "driveTyp", $$v);
      },
      expression: "myThg.registration.driveTyp"
    }
  }), _c('v-text-field', {
    staticClass: "mr-4 mt-n4",
    attrs: {
      "disabled": "",
      "label": _vm.$t('components.thg.ThgRegistrationForm.vehicleClass'),
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.myThg.registration.vehicleClass,
      callback: function callback($$v) {
        _vm.$set(_vm.myThg.registration, "vehicleClass", $$v);
      },
      expression: "myThg.registration.vehicleClass"
    }
  })], 1)], 1)], 1) : _c('v-row', [_c('v-col', {
    staticClass: "mx-4 my-2"
  }, [_c('thg-quote-update-my-registration-image-dialog', {
    attrs: {
      "bigButton": true,
      "myThg": _vm.myThg
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }