
import BadConscienceDialog from "@/components/thg/BadConscienceDialog.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import { ImpactTypeEnum } from "./enum/impact-type.enum";
import ThgImpactSelector from "./ThgImpactSelector.vue";

@Component({
  components: {
    LayoutReportStep,
    BadConscienceDialog
  }
})
export default class ThgImpactSelectorWithCustomerAccount extends ThgImpactSelector implements IReportStep {
  screen = ReportScreenEnum.thgImpactSelectorWithCustomerAccount;
  mounted() {
    this.setCurrentStep();
    ThgCreateModule.setActiveImpactTypeItems([
      ImpactTypeEnum.charging,
      ImpactTypeEnum.trees,
      ImpactTypeEnum.customerAccount,
      ImpactTypeEnum.payout
    ]);
    if (this.$route.query.campaign) {
      ThgCreateModule.setCampaign((this.$route.query.campaign as string) || "");
    }
  }
}
