

























import { Component, Prop, Vue } from "vue-property-decorator";
import { PartnerColor } from "@/lib/partnerColor";

@Component({
  components: {}
})
export default class ThgChargingStationHelpDialog extends Vue {
  @Prop()
  helpText!: string;
  @Prop()
  helpTextTitle!: string;

  dialog = false;
  partnerColor = new PartnerColor();

  close() {
    this.dialog = false;
  }
}
