/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";

export function requestEmissionCalculationData(thgStatusEnum: any) {
  if (
    [
      ThgStatusEnum.DATA_REQUESTED_BEV_AT_QUOTA_DETAILS,
      ThgStatusEnum.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS,
      ThgStatusEnum.DATA_UPDATED_BEV_AT_QUOTA_DETAILS,
      ThgStatusEnum.DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS,
      ThgStatusEnum.NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS
    ].includes(thgStatusEnum)
  ) {
    return true;
  }

  return false;
}
