










































import LoginComponentPasswordRequirementList from "@/components/login/LoginComponentPasswordRequirementList.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { getSpecialCharsList } from "@/lib/passwordValidator";
import { customRule } from "@/lib/rules/customRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import { noWhiteSpaceRule } from "@/lib/rules/noWhiteSpaceRule";

@Component({
  components: {
    LayoutReportStep,
    LoginComponentPasswordRequirementList
  }
})
export default class ThgPassword extends ReportStepMixin implements IReportStep {
  public md = 4;
  public header: IReportHeaderData = {
    title: "report.thgPassword.title",
    description: "report.thgPassword.description"
  };

  screen = ReportScreenEnum.thgpassword;

  password2 = "";

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get password1() {
    return ThgCreateModule.password;
  }

  set password1(password: string) {
    ThgCreateModule.setPassword(password);
  }

  get isValidPassword() {
    return ThgCreateModule.isValidPassword;
  }

  set isValidPassword(valid: boolean) {
    ThgCreateModule.setIsValidPassword(valid);
  }

  isPasswordValid = false;
  isFormValid = false;

  isPasswordVisible1 = false;
  isPasswordVisible2 = false;

  setRequirementsFullfilled(isFullfilled: boolean) {
    ThgCreateModule.setIsValidPassword(isFullfilled);
  }

  get passwordRequirements() {
    const rules = [];
    rules.push(
      noWhiteSpaceRule(),
      customRule(this.isValidPassword, "Die Anforderungen an das Passwort müssen erfüllt sein")
    );
    return rules;
  }
  get passwordRepeatRule() {
    return [customRule(this.password1 === this.password2, "Die Passwörter müssen übereinstimmen")];
  }
  get specialChars() {
    return getSpecialCharsList();
  }

  get isDone() {
    return !!this.password2 && !!this.password1 && this.isFormValid && this.password1 === this.password2;
  }

  validate() {
    this.form.validate();
  }

  updatePassword() {
    ThgCreateModule.setPassword(this.password1);
  }
}
