


































































































import ThgChargingStationCard from "@/components/thg/ThgChargingStationCard.vue";
import ThgChargingStationForm from "@/components/thg/ThgChargingStationForm.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { HttpException } from "@/lib/exceptions/http";
import { PartnerColor } from "@/lib/partnerColor";
import { ThgChargingStationViewModelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";

import { Component, Vue } from "vue-property-decorator";
import { handleError } from "@/lib/utility/handleError";
import { ChargingStationUiDto } from "@/store/models/thg/create-charging-station";

@Component({
  components: {
    ThgChargingStationCard,
    LayoutReport,
    ConfirmActionDialog,
    ThgChargingStationForm,
    Debug
  }
})
export default class ThgChargingStationDetail extends Vue {
  isLoading = true;

  partnerColor = new PartnerColor();

  // Dialogs
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;
  isUpdateDialogActive = false;
  isUpdateDialogLoading = false;

  async mounted() {
    try {
      this.isLoading = true;
      await MeterReadingModule.getMeterReadingsByChargingStation(this.$route.params.chargingStationId);
      await ChargingStationModule.getChargingStation(this.$route.params.chargingStationId);
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.isLoading = false;
    }
  }

  async updateChargingStation(chargingStation: ChargingStationUiDto) {
    if (!this.chargingStation) {
      return;
    }

    this.isUpdateDialogLoading = true;

    try {
      await ChargingStationModule.updateChargingStation({
        id: this.chargingStation.id,
        data: chargingStation.toUpdateMyChargingStationShort()
      });
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isUpdateDialogActive = false;
      this.isUpdateDialogLoading = false;
    }
  }

  async deleteChargingStation() {
    if (!this.chargingStation) {
      return;
    }

    this.isDeleteDialogLoading = true;

    try {
      await ChargingStationModule.removeChargingStation(this.chargingStation.id);
      this.$toast.success("👍");
      this.goToChargingStationOverview();
    } catch (error) {
      handleError(error);
    } finally {
      this.isDeleteDialogActive = false;
      this.isDeleteDialogLoading = false;
    }
  }

  get chargingStation(): ThgChargingStationViewModelGen | undefined {
    return ChargingStationModule.activeChargingStation;
  }

  get meterReadings(): ThgThgMeterReadingViewModelGen[] | undefined {
    return MeterReadingModule.meterReadings;
  }

  goToChargingStationOverview() {
    this.$router.push({ path: "/charging-station" });
  }

  get chargingStationTimeStamp(): any {
    const timestamp = this.chargingStation?.timestamp as any;
    return new Date(timestamp.created).toLocaleString();
  }
}
