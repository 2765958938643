












import { Component, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {}
})
export default class ThgSuccessCard extends Vue {
  openRating() {
    window.open(ConfigModule.googleThgRatingLink, "_blank");
  }

  close() {
    this.$emit("close");
  }
}
