/*
 * See https://gist.github.com/graydon/11198540#file-country-bounding-boxes-py-L4
 *  'AT': ('Austria', (9.47996951665, 46.4318173285, 16.9796667823, 49.0390742051)),
 *  'DE': ('Germany', (5.98865807458, 47.3024876979, 15.0169958839, 54.983104153)),
 */

// Lower boundaries
export const MIN_LNG = 5;
export const MIN_LAT = 46;

// Upper boundaries
export const MAX_LNG = 17;
export const MAX_LAT = 55;
