/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Possible dialog screens
 */
export enum ThgDialogEnum {
  none = 0,
  thgDialogYearSelection = 1,
  thgDialogImageConfirm = 2,
  thgDialogImageUpload = 3,
  thgDialogConfirm = 4,
  thgDialogSuccess = 5
}

/**
 * Config model
 */
export class ThgCheckoutDialogWindowConfig {
  type: ThgDialogEnum;
  title = "";

  leftText = "Zurück";
  leftClick: Function | undefined = undefined;

  rightText = "Weiter";
  rightClick: Function | undefined = undefined;

  hideLeft = false;
  hideRight = false;
  hideClose = false;

  loading = false;

  /**
   *
   */
  constructor(
    type: ThgDialogEnum,
    title = "",
    leftClick: Function | undefined,
    rightClick: Function | undefined,
    rightText = "Weiter",
    leftText = "Zurück",
    hideClose = false,
    hideLeft = false,
    hideRight = false
  ) {
    this.type = type;
    this.title = title;
    this.leftClick = leftClick;
    this.leftText = leftText;
    this.rightClick = rightClick;
    this.rightText = rightText;
    this.hideClose = hideClose;
    this.hideLeft = hideLeft;
    this.hideRight = hideRight;
  }
}

/**
 * Builder
 */
export class ThgQuickCheckoutDialogBuilder {
  dialogs: ThgCheckoutDialogWindowConfig[] = [];

  addStep(dialog: ThgCheckoutDialogWindowConfig) {
    this.dialogs.push(dialog);
    return dialog;
  }

  getStep(type: ThgDialogEnum): ThgCheckoutDialogWindowConfig {
    return (
      this.dialogs.find(s => s.type === type) ||
      new ThgCheckoutDialogWindowConfig(ThgDialogEnum.none, "Whoops", undefined, undefined, "", "", false, true, true)
    );
  }
}
