









































































import ThgCheckoutSustainableCard from "@/components/thg/ThgCheckoutSustainableCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "./enum/impact-type.enum";
import { ReportModule } from "@/store/modules/report.store";
import { GtagModule } from "@/store/modules/gtag.store";

@Component({
  components: {
    LayoutReportStep,
    ThgCheckoutSustainableCard
  }
})
export default class ThgPayoutConfiguration extends ReportStepMixin implements IReportStep {
  screen = ReportScreenEnum.thgPayoutConfiguration;
  isLoading = false;

  payout = ImpactTypeEnum.payout;
  customerAccount = ImpactTypeEnum.customerAccount;

  payoutImage = "https://static.mmmint.ai/wirkaufendeinethg/photo-1580048915913-4f8f5cb481c4-1.jpeg";
  customerAccountImage = "https://static.mmmint.ai/wirkaufendeinethg/customerAccount-1.jpg";

  public header: IReportHeaderData = {
    title: "report.thgPayoutConfiguration.title",
    description: "report.thgPayoutConfiguration.description"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgPayoutConfiguration.instructionTitle",
    text: "report.thgPayoutConfiguration.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  get partnerName() {
    return ReportModule.partner.companyName;
  }

  get impactType() {
    return ThgCreateModule.impactType;
  }

  set impactType(type: ImpactTypeEnum) {
    ThgCreateModule.setImpactType(type);
    ThgCreateModule.setImpactFactor(ThgCreateModule.impactTypeItem.baseImpactFactor);
  }

  activeCardStyle(type: ImpactTypeEnum) {
    if (this.impactType === type) {
      return "primary";
    }
    return "";
  }

  next() {
    GtagModule.dispatchBeginCheckoutEvent();
  }

  mounted() {
    ThgCreateModule.setActiveImpactTypeItems([ImpactTypeEnum.payout, ImpactTypeEnum.customerAccount]);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return true;
  }
}
