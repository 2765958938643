
import { Component, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({})
export default class ThgContactScreensMixin extends Vue {
  /**
   * Screens for inputting contact data and vehicle information.
   * @example `thgcontact`
   */
  contactScreens = [ReportScreenEnum.thgcontact, ReportScreenEnum.thgcontactat];
}
