












































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import ThgImpactSelectorCard from "./ThgImpactSelectorCard.vue";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { PayoutOptionEnum } from "./enum/payout-option.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

@Component({
  components: {
    LayoutReportStep,
    ThgImpactSelectorCard
  }
})
export default class ThgPayoutOptionSelector extends ReportStepMixin implements IReportStep {
  public header: IReportHeaderData = {
    title: "report.thgPayoutOptionSelector.title",
    description: "report.thgPayoutOptionSelector.description"
  };
  screen = ReportScreenEnum.thgpayoutoptionselector;

  public instruction: IReportInstructionData = {
    title: "report.thgPayoutOptionSelector.instructionTitle",
    text: "report.thgPayoutOptionSelector.instructionText",
    pictures: [],
    example: undefined,
    display: false
  };

  get isDone() {
    return true;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  activeCardStyle(option: PayoutOptionEnum) {
    if (this.selected === option) {
      return "primary";
    }
    return "";
  }

  get items() {
    return ThgCreateModule.activePayoutOptions;
  }

  get selected() {
    return ThgCreateModule.payoutOption;
  }

  set selected(option: PayoutOptionEnum) {
    ThgCreateModule.setPayoutOption(option);
  }

  mounted() {
    this.setCurrentStep();
  }
}
