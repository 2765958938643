var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "md": _vm.md,
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "showFooter": false
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pl-4 pr-4",
    attrs: {
      "flat": "",
      "max-width": "1200"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isExternalCustomer,
      callback: function callback($$v) {
        _vm.isExternalCustomer = $$v;
      },
      expression: "isExternalCustomer"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.thgCustomerDetails.isCustomer', {
        partnerName: _vm.companyName
      }),
      "value": true,
      "data-test-form-iscompany": ""
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.thgCustomerDetails.isNoCustomer', {
        partnerName: _vm.companyName
      }),
      "value": false,
      "data-test-form-isprivate": ""
    }
  })], 1), _vm.isExternalCustomer ? _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.externalCustomerNumber,
      "label": _vm.$t('report.thgCustomerDetails.customerNumber'),
      "outlined": "",
      "data-test-form-company": ""
    },
    on: {
      "blur": _vm.updateExternalCustomerNumber
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }