var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto mt-8"
  }, [_c('v-card', {
    style: "border: 1px solid ".concat(_vm.color, ";"),
    attrs: {
      "flat": "",
      "max-width": _vm.maxWidth
    }
  }, [_c('v-card-text', [_c('div', {
    staticClass: "text-center text-md-h5 text-sm-h6 text-h7 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))]), _c('div', {
    staticClass: "text-center text-md-h7 text-sm-h7 text-h7"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.subtitle)) + " ")])]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.src,
      "max-width": "100px"
    }
  })], 1), _c('v-card-text', {
    staticClass: "text-center text-md-h7 text-sm-h7 text-h7"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgPriceOverview.atLeast")) + " "), _c('div', {
    staticClass: "text-center text-md-h5 text-sm-h6 text-h6 font-weight-black",
    style: "color:".concat(_vm.partnerColorDarkmode)
  }, [_vm._v(" " + _vm._s(_vm.price) + " ")]), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgPriceOverview.perVehicle")) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }