import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import {} from "@/lib/utility/t";
import {
  ThgAccountViewmodelGen,
  ThgBillingViewmodelGen,
  ThgTimestampDocumentGen
} from "@/services/thg/v1/data-contracts";
import { Timestamp } from "./timestamp.entity";

@IsFilterable
class AccountBase implements ThgAccountViewmodelGen {
  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.account.id" })
  id: string;

  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.account.userId" })
  userId: string;

  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.account.partnerId" })
  partnerId?: string | undefined;

  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.account.accountNumber"
  })
  accountNumber: number;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ThgTimestampDocumentGen;

  constructor(account?: Partial<ThgBillingViewmodelGen>) {
    this.id = account?.id || "";
    this.userId = account?.userId || "";
    this.partnerId = account?.partnerId || "";
    this.accountNumber = account?.accountNumber || -1;
    this.timestamp = account?.timestamp as ThgTimestampDocumentGen;
  }
}

export const Account = Filter.createForClass(AccountBase);
