
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class SvgMixin extends Vue {
  @Prop({ default: "350" })
  width!: string;

  @Prop({ default: "300" })
  height!: string;

  @Prop({ default: "0 0 1100 800" })
  viewBox!: string;

  get color() {
    return getDefaultPartnerColor();
  }
}
