

























































import ThgMeterReadingForm from "@/components/thg/ThgMeterReadingForm.vue";
import { HttpException } from "@/lib/exceptions/http";
import { PartnerColor } from "@/lib/partnerColor";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { CreateMeterReading } from "@/store/models/thg/meter-reading";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { ThgMeterReadingForm }
})
export default class ThgChargingStationMeterReadingFormDialog extends DarkModeHighlightMixin {
  @Prop({ default: false })
  overflowBtn!: boolean;

  chargingStationId = this.$route.params.chargingStationId;

  dialog = false;
  loading = false;

  async save(data: CreateMeterReading) {
    const partnerId = PartnerModule.partner.id || PartnerModule.partner._id;
    this.$log.debug(data);
    try {
      this.loading = true;
      await MeterReadingModule.createMeterReading({ partnerId, data });
      await MeterReadingModule.getMeterReadingsByChargingStation(this.$route.params.chargingStationId);
      this.$toast.success(this.$t("report.thgMeterReadingCreate.success"));
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
    this.close();
  }

  openDialog() {
    this.dialog = true;
  }

  close() {
    (this.$refs.form as ThgMeterReadingForm).resetForm();
    this.dialog = false;
  }

  partnerColor = new PartnerColor();
}
