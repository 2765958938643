/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAccountingRecordItemViewmodelGen,
  ThgBillingBatchAutoCreationDtoGen,
  ThgBillingBatchControllerCreateBillingDocumentParamsGen,
  ThgBillingBatchCreateDtoGen,
  ThgBillingListDtoGen,
  ThgBillingViewmodelGen,
  ThgCreateBillingThgPdfDtoGen,
  ThgNamedUrlViewModelGen,
  ThgOperationIdViewModelGen,
  ThgPartnerCommissionDtoGen,
  ThgUrlViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BillingBatch<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerCreateBillingDocument
   * @summary (ADMIN) create a billing for a batch of thgs
   * @request POST:/billing/batch
   * @secure
   * @response `201` `ThgBillingViewmodelGen` Created billing document
   */
  billingBatchControllerCreateBillingDocument = (
    query: ThgBillingBatchControllerCreateBillingDocumentParamsGen,
    data: ThgBillingBatchCreateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBillingViewmodelGen, any>({
      path: `/billing/batch`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerDispatchAutoBilling
   * @summary (ADMIN) Dispatch operation to automatically and asynchronously bill documents that are ready for billing (works for credit, creditCustomerAccount)
   * @request POST:/billing/batch/async
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   */
  billingBatchControllerDispatchAutoBilling = (data: ThgBillingBatchAutoCreationDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, any>({
      path: `/billing/batch/async`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerCreateBillingBatchAsync
   * @summary (ADMIN) Dispatch operation to automatically and asynchronously a single billing document
   * @request POST:/billing/batch/one/async
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   */
  billingBatchControllerCreateBillingBatchAsync = (data: ThgBillingBatchCreateDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, any>({
      path: `/billing/batch/one/async`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerCreateBillingPdf
   * @summary (ADMIN) create a billing pdf for the billing of a thg
   * @request PUT:/billing/{billingNumber}/batch/pdf
   * @secure
   * @response `200` `ThgUrlViewmodelGen` A link to the pdf
   */
  billingBatchControllerCreateBillingPdf = (
    billingNumber: number,
    data: ThgCreateBillingThgPdfDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgUrlViewmodelGen, any>({
      path: `/billing/${billingNumber}/batch/pdf`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerCreateBillingPdfAsync
   * @summary (ADMIN) Dispatch operation to create a pdf for a billing
   * @request PUT:/billing/{billingNumber}/batch/pdf/async
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   */
  billingBatchControllerCreateBillingPdfAsync = (
    billingNumber: number,
    data: ThgCreateBillingThgPdfDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgOperationIdViewModelGen, any>({
      path: `/billing/${billingNumber}/batch/pdf/async`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerCreateBillingAccountingRecords
   * @summary (ADMIN) create an accounting record for the billing of a thg
   * @request PUT:/billing/{billingNumber}/batch/record
   * @secure
   * @response `200` `(ThgAccountingRecordItemViewmodelGen)[]` A link to the pdf
   */
  billingBatchControllerCreateBillingAccountingRecords = (
    billingNumber: number,
    data: ThgPartnerCommissionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAccountingRecordItemViewmodelGen[], any>({
      path: `/billing/${billingNumber}/batch/record`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerCreateBillingAccountingRecordsAsync
   * @summary (ADMIN) Dispatch operation to create accounting records for a billing asynchronous
   * @request PUT:/billing/{billingNumber}/batch/record/async
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   */
  billingBatchControllerCreateBillingAccountingRecordsAsync = (
    billingNumber: number,
    data: ThgPartnerCommissionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgOperationIdViewModelGen, any>({
      path: `/billing/${billingNumber}/batch/record/async`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerDispatchBillingCsv
   * @summary (ADMIN) dispatch task that creates all CSVs that are important to do all the accounting for billings
   * @request POST:/billing/batch/csv
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` id of operation
   */
  billingBatchControllerDispatchBillingCsv = (data: ThgBillingListDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, any>({
      path: `/billing/batch/csv`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing-batch
   * @name BillingBatchControllerAccessCsvFromOperation
   * @summary (ADMIN) Returns a csv file for a create csv operation
   * @request GET:/billing/batch/csv/{operationId}
   * @secure
   * @response `200` `(ThgNamedUrlViewModelGen)[]` A list of names and urls of documents
   */
  billingBatchControllerAccessCsvFromOperation = (operationId: string, params: RequestParams = {}) =>
    this.http.request<ThgNamedUrlViewModelGen[], any>({
      path: `/billing/batch/csv/${operationId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
