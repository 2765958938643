





































































import { Component, Vue } from "vue-property-decorator";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ReportModule } from "@/store/modules/report.store";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";

@Component({
  components: { LayoutReport }
})
export default class ThgImprint extends Vue {
  get partner(): PartnerEntity {
    return ReportModule?.partner;
  }

  get isWhiteLabelPartner(): boolean {
    return this.partner?.partnerType !== PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE;
  }

  get email() {
    return this.isWhiteLabelPartner ? "info@mintfuture.de" : "info@wirkaufendeinethg.de";
  }
}
