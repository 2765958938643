















































































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import {
  ThgCheckoutDialogWindowConfig,
  ThgDialogEnum,
  ThgQuickCheckoutDialogBuilder
} from "@/lib/config/thg/ThgQuickCheckoutDialogBuilder";
import { NetworkError } from "@/lib/exceptions/http/NetworkError";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { GtagModule } from "@/store/modules/gtag.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ThgDialogYearSelection from "./ThgDialogYearSelection.vue";
import ThgQuickCheckoutClosingCard from "./ThgQuickCheckoutClosingCard.vue";
import ThgQuickCheckoutValidateRegistrationCard from "./ThgQuickCheckoutValidateRegistrationCard.vue";
import ThgRegistrationUploadCard from "./ThgRegistrationUploadCard.vue";
import ThgSuccessCard from "./ThgSuccessCard.vue";

@Component({
  components: {
    ConfirmActionDialog,
    ThgDialogYearSelection,
    ThgSuccessCard,

    ThgRegistrationUploadCard,

    ThgQuickCheckoutValidateRegistrationCard,
    ThgQuickCheckoutClosingCard
  }
})
export default class ThgQuickCheckout extends Vue {
  @Prop()
  thg!: ThgThgMeViewModelGen;

  /**
   * Form valid
   */
  isValid = false;

  /**
   * Displays loading animation
   */
  loading = false;

  /**
   * Show Dialog
   */
  isDialogActive = false;

  /**
   * Builder to get steps for the quick checkout
   *
   */
  builder = new ThgQuickCheckoutDialogBuilder();

  /**
   * Current active dialog;
   */
  activeDialog: ThgDialogEnum = ThgDialogEnum.none;

  /**
   * User input if registrations should be reused
   */
  get isRegistrationValid() {
    return ThgCreateModule.isRegistrationValid;
  }

  /**
   * Gets the dialog to be displayed from the builder.
   * Builder creates the Dialog order initialized via @see startCheckout function.
   */
  get activeDialogConfig(): ThgCheckoutDialogWindowConfig {
    return this.builder.getStep(this.activeDialog);
  }

  /**
   * Rule for disabling or enabling the "Weiter" buttont.
   *
   * Decides based on the current active dialog to proceed.
   * TODO: Fix this and make the state manager a bit more predictable.
   */
  get isRightDisabled() {
    if (this.activeDialog === ThgDialogEnum.thgDialogYearSelection) {
      return this.selectedYears.length === 0;
    }

    if (this.activeDialog === ThgDialogEnum.thgDialogImageUpload) {
      return ThgCreateModule.registrationImageBack.length === 0 || ThgCreateModule.registrationImageFront.length === 0;
    }

    if (this.activeDialog === ThgDialogEnum.thgDialogConfirm) {
      return !this.isValid;
    }

    return false;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get color() {
    return "success";
  }

  get selectedYears() {
    return ThgCreateModule.years;
  }

  get ThgDialogEnum() {
    return ThgDialogEnum;
  }

  get qaEngineer() {
    return AssetRepository.getAsset(false, AssetEnum.qaEngineer);
  }

  get registrationYears() {
    if (!this.thg.quickCheckoutEnabledFor) {
      return [];
    }

    if (this.thg.quickCheckoutEnabledFor.length === 1) {
      return this.thg.quickCheckoutEnabledFor[0].year;
    }

    return this.thg.quickCheckoutEnabledFor.map(c => c.year.toString().substr(2, 2)).join("/");
  }

  /**
   * Sets the new active dialog to be one of the @see ThgDialogEnum.
   * Must be part of the builder, otherwise this will fail.
   *
   * @param selectedDialog the to be displayed dialog
   */
  setDialog(selectedDialog: ThgDialogEnum) {
    this.activeDialog = this.builder.getStep(selectedDialog).type;
  }

  async startCheckout() {
    ThgCreateModule.resetThgForQuickCheckout();
    ThgCreateModule.setNumberplate(this.thg.numberplate);
    ThgCreateModule.setYears((this.thg.quickCheckoutEnabledFor || []).map(f => f.year));
    ThgCreateModule.setParentId(this.thg.id);
    ThgCreateModule.setImpactFactor(this.thg.impactFactor || 0);

    ThgCreateModule.setImpactType(ImpactTypeEnum[this.thg.impactType] || ImpactTypeEnum.payout);

    this.isDialogActive = true;

    GtagModule.dispatchViewItemEvent();

    this.builder.addStep(
      new ThgCheckoutDialogWindowConfig(
        ThgDialogEnum.thgDialogYearSelection,
        "Jetzt THG-Quote beantragen",
        undefined, // this.closeDialog,
        () => {
          GtagModule.dispatchAddToCart();
          this.setDialog(ThgDialogEnum.thgDialogImageConfirm);
        },
        "Weiter",
        "Abbrechen",
        false,
        true,
        false
      )
    );

    this.builder.addStep(
      new ThgCheckoutDialogWindowConfig(
        ThgDialogEnum.thgDialogImageConfirm,
        "Fahrzeugschein bestätigen",
        () => this.setDialog(ThgDialogEnum.thgDialogYearSelection),
        this.imageConfirmRightClickAction
      )
    );

    this.builder.addStep(
      new ThgCheckoutDialogWindowConfig(
        ThgDialogEnum.thgDialogImageUpload,
        "Fahrzeugschein neu hochladen",
        () => this.setDialog(ThgDialogEnum.thgDialogImageConfirm),
        () => {
          GtagModule.dispatchBeginCheckoutEvent();
          this.setDialog(ThgDialogEnum.thgDialogConfirm);
        }
      )
    );

    this.builder.addStep(
      new ThgCheckoutDialogWindowConfig(
        ThgDialogEnum.thgDialogConfirm,
        "Beantragung abschließen",
        this.confirmLeftClickAction,
        this.quickCheckout,
        "Bestätigen"
      )
    );

    this.builder.addStep(
      new ThgCheckoutDialogWindowConfig(
        ThgDialogEnum.thgDialogSuccess,
        "Alles erledigt!",
        undefined,
        undefined,
        "",
        "",
        true,
        true,
        true
      )
    );

    this.setDialog(ThgDialogEnum.thgDialogYearSelection);
  }

  closeDialog() {
    this.isDialogActive = false;
    this.setDialog(ThgDialogEnum.thgDialogYearSelection);
  }

  /**
   * TODO: FIXME: Upload the existing registration images of a THG as new ones.
   * Download the image from URL, and convert it into a file to be "reuploaded" as a new registration image.
   *
   * Creates a new @see IImageUploaded to be used as a registration image.
   */
  async uploadExistingRegistrationDocuments() {
    const frontImage = await fetch(this.thg.registrationImages[0].url);
    const backImage = await fetch(this.thg.registrationImages[1].url);

    const frontImageBlob = await frontImage.blob();
    const backImageBlob = await backImage.blob();

    ThgCreateModule.addRegistrationBack(
      new File([backImageBlob], "back.jpg", { type: "image/jpg", lastModified: new Date().getTime() })
    );
    ThgCreateModule.addRegistrationFront(
      new File([frontImageBlob], "back.jpg", { type: "image/jpg", lastModified: new Date().getTime() })
    );
  }

  imageConfirmRightClickAction() {
    if (this.isRegistrationValid) {
      GtagModule.dispatchBeginCheckoutEvent();
      this.setDialog(ThgDialogEnum.thgDialogConfirm);
    } else {
      this.setDialog(ThgDialogEnum.thgDialogImageUpload);
    }
  }

  confirmLeftClickAction() {
    this.isRegistrationValid
      ? this.setDialog(ThgDialogEnum.thgDialogImageConfirm)
      : this.setDialog(ThgDialogEnum.thgDialogImageUpload);
  }

  /**
   * Creates a new THG based on the parent.
   * Uses the ThgCreateStore to set the items.
   */
  async quickCheckout() {
    this.loading = true;

    try {
      await ThgCreateModule.createThgMe();
      await ThgCreateModule.resetThg();
      this.setDialog(ThgDialogEnum.thgDialogSuccess);
    } catch (error) {
      if (error instanceof NetworkError) {
        this.$toast.error("Fehler mit der Verbindung. Bitte zu einem späteren Zeitpunkt erneut versuchen.");
      } else {
        this.$toast.error("Fehler beim senden. Bitte Eingaben noch einmal prüfen.");
      }
    } finally {
      this.loading = false;
    }
  }

  async close() {
    this.isDialogActive = false;
    this.$emit("refresh");
  }
}
