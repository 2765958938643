






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ImageOnHoverChangeColor extends Vue {
  @Prop({ default: 800 })
  src!: number;

  hover = false;

  colorImage(hover: boolean) {
    if (!hover) {
      return "greyScale";
    } else {
      return "";
    }
  }

  isHovering = false;
}
