































import LayoutReport from "@/layouts/LayoutReport.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { FaqModule } from "@/store/modules/faq.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport }
})
export default class ThgFrequentlyAskedQuestionsAt extends Vue {
  isLoading = false;

  get color() {
    return getDefaultPartnerColor();
  }

  async mounted() {
    this.isLoading = true;

    try {
      await FaqModule.getAll({ partnerId: ReportModule.partner._id });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  get faqs() {
    const faqs = FaqModule.paginationList;

    if (faqs && faqs.length >= 1) {
      this.faqList.push(...faqs);
    }

    return this.faqList.filter(faq => faq.isActive);
  }

  get faqList(): ThgFaqViewModelGen[] {
    return [
      {
        question: "Was ist die THG-Quote?",
        answer:
          "THG-Quote steht für Treibhausgasminderungsquote. Jährlich gilt es für Unternehmen, die beispielsweise fossile Brennstoffe in Umlauf bringen, einen steigenden Prozentsatz an Emissionen zu reduzieren oder wenigstens zu kompensieren. Sollte diese auferlegte Quote nicht eingehalten werden, drohen Strafzahlungen. Die gesetzliche Grundlage für die THG-Quote findet sich im Kraftstoffverordnung 2012.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wieso erhalte ich für mein E-Fahrzeug eine THG-Prämie?",
        answer:
          "Wenn du elektrisch fährst, sparst du CO2 ein. Diese Einsparungen können wir nun für dich vermarkten, da es seit 2023 eine neue Gesetzesgrundlage dafür gibt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wie hoch ist die THG-Prämie?",
        answer: "Die aktuellen Preise werden im Rahmen der Registrierung angezeigt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wie oft kann ich die THG-Prämie beantragen?",
        answer: "Du kannst die THG-Prämie für jedes vollelektrische Fahrzeug einmal pro Kalenderjahr beantragen.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Gibt es die THG-Quote auch in Österreich?",
        answer:
          "Ja, ab 2023 wird das elektrische Fahren in Österreich durch den Gesetzgeber im Rahmen der Treibhausgasminderungsquote (kurz THG-Quote) ebenfalls anerkannt. Dann ist es möglich, die CO2-Einsparungen zertifizieren zu lassen und die THG-Quote verkaufen. In Österreich können Besitzer/-innen die CO2-Einsparungen über wirkaufendeinthg.de zertifizieren und die THG-Quote verkaufen lassen.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wie hoch ist die THG-Quote in Österreich?",
        answer:
          "Aktuell gibt es noch keine Informationen zu tatsächlich erzielbaren Preisen; sobald eine Auszahlungshöhe bekannt ist, erfährst du es an dieser Stelle von uns.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Was brauche ich zur Beantragung der THG-Quote in Österreich?",
        answer:
          "Unbedingt notwendig ist die Papier- oder Scheckkartenzulassungsbescheinigung des Elektrofahrzeugs. Zusätzlich kann die nachweislich gemessene Strommenge des Antragsjahres für das Fahrzeug benötigt werden.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Gilt die THG-Quote in Österreich auch für Hybrid-Fahrzeuge?",
        answer:
          "Ja, in Österreich sind Halter von Fahrzeugen mit hybridem Antrieb ebenfalls quotenberechtigt. Hierbei muss allerdings die exakte Erfassung der Strommenge erfolgen, eine Pauschale kann nicht zugrunde gelegt werden.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Gibt es in Österreich einen festen Anmeldezeitraum für die THG-Quote?",
        answer:
          "Du kannst dich ab sofort auf unserer Plattform registrieren. Wir nehmen die Meldung für das Antragsjahr 2023 fristgerecht im Zeitraum 01.01.2024 – 01.03.2024 beim österreichischen Umweltbundesamt vor.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Sind E-Roller und Pedelecs auch in Österreich quotenberechtigt?",
        answer:
          "Berechtigt sind aktuell lediglich mehrspurige Fahrzeuge. Sobald eine Änderung des Gesetzes erfolgt und es neue Infos zu diesem Punkt gibt, erfährst du es hier von uns.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Gibt es die THG-Quote für öffentliche Ladestationen in Österreich?",
        answer:
          "Durch den verpflichtenden Eintrag im Ladestellenverzeichnis der öffentlichen Ladestationen in Österreich, sind dessen Besitzer/-innen für die THG-Quote antragsberechtigt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Ist die THG-Prämie in Österreich steuerpflichtig?",
        answer:
          "Aktuell wird vom österreichischen Gesetzgeber noch geprüft, ob die Prämienauszahlung steuerpflichtig sein wird. Sobald es neue Infos zu diesem Punkt gibt, erfährst du es hier von uns.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wird die THG-Quote in Österreich immer für das ganze Antragsjahr angerechnet?",
        answer:
          "Nein, in Österreich wird keine jährliche Pauschalannahme für unterjährig zugelassene Fahrzeuge getroffen. Die THG-Quote wird aliquot für den tatsächlichen Zeitraum der Zulassung ausgezahlt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Muss ich die Abmeldung meines Fahrzeugs nach Beantragung der THG-Prämie in Österreich melden?",
        answer:
          "Die THG-Quote wird aliquot für den tatsächlichen Zeitraum der Zulassung ausgezahlt. Sobald die Zulassung auf den bisherigen Halter endet, muss uns dies umgehend gemeldet werden.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      }
    ];
  }
}
