import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import {
  ThgMeterReadingGen,
  ThgPayoutConfigurationGen,
  ThgPromotionConfigurationGen,
  ThgUpdateThgAdminDtoGen
} from "@/services/thg/v1/data-contracts";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { IThg } from "./thg.entity";

type IUpdateThg = UpdateThg;

class UpdateThg implements ThgUpdateThgAdminDtoGen {
  numberplate: string;
  impactType: ImpactTypeEnum;
  impactFactor: number;
  payoutConfiguration: ThgPayoutConfigurationGen;
  promotionConfiguration?: ThgPromotionConfigurationGen | undefined | null;
  meterReading?: ThgMeterReadingGen | undefined;
  decommissioningDate?: string | undefined;
  code?: string | undefined;
  year: number;
  countryCode?: CountryCodeEnum;

  constructor(thg: IThg) {
    this.code = thg.code;
    this.countryCode = thg.countryCode as CountryCodeEnum;
    this.impactType = thg.impactType as ImpactTypeEnum;
    this.decommissioningDate = thg.decommissioningDate;
    this.impactFactor = thg.impactFactor;
    this.meterReading = thg.meterReading;
    this.numberplate = thg.numberplate;
    this.payoutConfiguration = thg.payoutConfiguration;
    this.promotionConfiguration = thg.promotionConfiguration;
    this.year = thg.year;
  }
}

export { IUpdateThg, UpdateThg };
