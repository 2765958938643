




















import { customRule } from "@/lib/rules/customRule";
import { ThgChargingStationProofDocumentDtoGen, ThgChargingStationProofDtoGen } from "@/services/thg/v1/data-contracts";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { Component, Prop, Vue } from "vue-property-decorator";

export type ChargingStationFileProof = File /** timestamp to show when the document is uploaded */ & {
  uploaded: number;
};

@Component({
  components: {}
})
export default class ThgChargingStationProof extends Vue {
  @Prop()
  uploadedFiles!: ThgChargingStationProofDocumentDtoGen[];

  file!: File;
  disabled = false;
  loading = false;
  /**
   * is the file not uploaded yet
   */
  noDuplicateFiles = true;

  get rules() {
    return [
      customRule(
        this.noDuplicateFiles,
        String(this.$i18n.t("components.thg.ThgChargingStationProof.fileAlreadyExists"))
      )
    ];
  }

  mount() {
    this.file = null as any;
  }

  async onChange(file: File) {
    if (!file) {
      return;
    }

    /** Don't allow uploading the same file twice */
    this.noDuplicateFiles = true;
    if (this.uploadedFiles) {
      const fileNames = this.uploadedFiles.map(file => file.originalName);

      if (fileNames.includes(file.name)) {
        this.noDuplicateFiles = false;
        return;
      }
    }

    const dto: ThgChargingStationProofDtoGen = {
      file: file
    };

    try {
      this.loading = true;
      let savedFile: ThgChargingStationProofDocumentDtoGen = null as any;

      if (file.type.includes("pdf")) {
        savedFile = await ChargingStationModule.uploadChargingStationPdfProof(dto);
      } else if (file.type.includes("image")) {
        savedFile = await ChargingStationModule.uploadChargingStationImageProof(dto);
      }

      this.$emit("file-change", savedFile);
    } catch (error) {
      this.$log.error(error);
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }
}
