var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "60"
    }
  }, [_vm.value && _vm.value.image && _vm.value.image.url ? _c('v-img', {
    staticStyle: {
      "border-radius": "4px"
    },
    attrs: {
      "src": _vm.value.image.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "fill-height ma-0",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v(" mdi-gift ")])], 1)];
      },
      proxy: true
    }], null, false, 1059475974)
  }) : _c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v(" mdi-gift ")])], 1), _vm.value ? _c('v-list-item-content', [!_vm.value.title ? _c('v-list-item-title', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.PromotionTypeEnum.".concat(_vm.value.type))) + " ")]) : _c('v-list-item-title', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.value.title)
    }
  })]), _vm.value.subtitle ? _c('v-list-item-subtitle', {
    domProps: {
      "innerHTML": _vm._s(_vm.value.subtitle)
    }
  }) : _vm._e(), _vm.value.description ? _c('v-list-item-subtitle', {
    domProps: {
      "innerHTML": _vm._s(_vm.value.description)
    }
  }) : _vm._e(), _vm.value.hint ? _c('v-list-item-subtitle', {
    domProps: {
      "innerHTML": _vm._s(_vm.value.hint)
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-list-item-action', [_c('span', [_vm.value.hint ? _c('span', [_vm._v("*")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.valueString) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }