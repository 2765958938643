



































































import Notifications from "@/components/thg/Notifications.vue";
import ThgVehicleCard from "@/components/thg/ThgVehicleCard.vue";
import ThgVehicleCardIterator from "@/components/thg/ThgVehicleCardIterator.vue";
import ThgVehicleTable from "@/components/thg/ThgVehicleTable.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { ThgModule } from "@/store/modules/thg.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    ThgVehicleCard,
    LayoutReport,
    ThgVehicleTable,
    ThgVehicleCardIterator,
    Notifications
  }
})
export default class ThgVehicleOverview extends Vue {
  verificationCode = "";
  isLoading = true;
  valid = false;
  showVehicleTable = false;
  activeView = 0;

  async mounted() {
    await this.loadAll();
  }

  async loadAll() {
    try {
      this.isLoading = true;
      await ThgModule.getMyThgs();
      if (ThgModule.myThgs.length > 10) {
        this.showVehicleTable = true;
        this.activeView = 1;
      }
    } catch {
      this.$toast.error(this.$t("report.thgVehicleOverview.errorTxt").toString());
    } finally {
      this.isLoading = false;
    }
  }

  get isLoadingAllThgs() {
    return ThgModule.isLoading;
  }

  get isThgs() {
    if (this.myThgs.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get myThgs(): ThgThgMeViewModelGen[] {
    return ThgModule.myThgs;
  }

  get isAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  get description() {
    if (this.isThgs) {
      return this.$t("report.thgVehicleOverview.description").toString();
    } else {
      return this.$t("report.thgVehicleOverview.descriptionAlt").toString();
    }
  }

  get note() {
    if (this.isWhiteLabelPartner) {
      return this.$t("report.thgImpactFactor.noteWhiteLabelPartner").toString();
    } else {
      return this.$t("report.thgImpactFactor.note").toString();
    }
  }

  get partner() {
    return ReportModule.partner;
  }

  get title() {
    return this.$t("report.thgVehicleOverview.title").toString();
  }

  newCar() {
    this.$router.push({ path: "/thghome" });
  }

  changeToListView() {
    this.showVehicleTable = true;
  }

  changeToCardView() {
    this.showVehicleTable = false;
  }

  get isWhiteLabelPartner(): boolean {
    return PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_QUOTE_AS_A_SERVICE;
  }
}
