var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto mt-8",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "text-md-h3 text-sm-h4 text-h4 mt-md-12  mt-4 text-center font-weight-medium",
    style: "color:".concat(_vm.partnerColorDarkmode)
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgPriceOverview.title")) + " ")]), _c('v-row', _vm._l(_vm.items, function (item, i2) {
    return _c('v-col', {
      key: i2,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('thg-price-card', {
      attrs: {
        "price": item.price,
        "title": item.title,
        "subtitle": item.subTitle,
        "src": item.src
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }