





















import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ThgChargingStationProofDocumentDtoGen,
  ThgChargingStationDocumentTypeDtoGen
} from "@/services/thg/v1/data-contracts";

@Component({
  components: {},
  filters: { simpleDoubleDigitDate }
})
export default class ThgChargingStationProofDocumentTable extends Vue {
  @Prop()
  files!: ThgChargingStationProofDocumentDtoGen[];
  @Prop()
  refs!: ThgChargingStationDocumentTypeDtoGen[];

  isFileInRefs(file: ThgChargingStationProofDocumentDtoGen) {
    return this.refs.find(ref => ref.refId === file.id);
  }

  fileExtension(file: ThgChargingStationProofDocumentDtoGen) {
    return file.name.split(".")[1];
  }

  removeFile(file: ThgChargingStationProofDocumentDtoGen) {
    this.$emit("file-removed", file);
  }
}
