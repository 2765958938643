











import ThgVehicleCard from "@/components/thg/ThgVehicleCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component } from "vue-property-decorator";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ThgModule } from "@/store/modules/thg.store";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    ThgVehicleCard,
    LayoutSimple
  }
})
export default class ThgVehicleDetail extends PartnerFallbackMixin {
  isLoading = false;

  thg: ThgThgMeViewModelGen | undefined = undefined;

  async mounted() {
    this.isLoading = true;
    const thgId = this.$route.params.thgId;

    try {
      await this.trySetByRouteOrDefault();
      await ThgModule.getMyThgs();

      this.thg = ThgModule.myThgs.find(v => v.id == thgId);

      if (!this.thg) {
        this.$toast.error(this.$t("views.Error.subtitle"));
      }
    } catch (error) {
      this.$log.error((error as any).message);
    } finally {
      this.isLoading = false;
    }
  }
}
