































































































import { IForm } from "@/lib/interfaces/form.interface";
import { noNumberRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { vinRuleChecksum, vinRuleLength, vinRuleNotAllowedCharacter } from "@/lib/rules/vinRule";
import { calculateVinChecksum, legitChecksums, VinChecksum } from "@/lib/utility/calculateVinChecksum";
import { deepCopy } from "@/lib/utility/deep-copy";
import { driveTypeMap } from "@/lib/utility/driveTypeMap";
import { thgVehicleClassMap } from "@/lib/utility/thgVehicleClassMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IUser } from "@/models/user.entity";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { ClipperModule } from "@/store/modules/clipper.store";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { IThg } from "@/models/thg.entity";
import { ThgRegistrationDtoGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: { Debug }
})
export default class ThgQuoteWorkitemRegistrationCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  selectedThg!: IThg;

  @Prop()
  selectedUser!: IUser;

  @Prop({ default: false })
  isResetValidation!: boolean;

  valid = false;

  registration: ThgRegistrationDtoGen = {} as any;

  fin = "";
  calculatedChecksum = 0;
  checksum: VinChecksum | "" = "";

  day = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31"
  ];

  month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  year = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990"
  ];

  get driveTypes() {
    return Array.from(driveTypeMap.keys());
  }

  get vehicleClasses() {
    return Array.from(thgVehicleClassMap.keys());
  }

  get legitChecksums() {
    return legitChecksums;
  }

  get _checksum() {
    if (this.checksum === "") {
      return this.checksum;
    }
    if (!isNaN(Number(this.checksum))) {
      return Number(this.checksum) as VinChecksum;
    }
    return this.checksum;
  }
  set _checksum(checksum: VinChecksum | "") {
    this.checksum = checksum;
  }

  _registrationDate = "";
  get registrationDate() {
    let { firstregistrationYear, firstregistrationMonth, firstregistrationDay } = this.registration;
    if (!(firstregistrationYear && firstregistrationMonth && firstregistrationDay)) {
      return "";
    }
    firstregistrationYear = firstregistrationYear.padStart(4, "0");
    firstregistrationMonth = firstregistrationMonth.padStart(2, "0");
    firstregistrationDay = firstregistrationDay.padStart(2, "0");

    return `${firstregistrationYear}-${firstregistrationMonth}-${firstregistrationDay}`;
  }
  set registrationDate(registrationDateString: string) {
    if (!registrationDateString) {
      this.registration = {
        ...this.registration,
        firstregistrationYear: "",
        firstregistrationMonth: "",
        firstregistrationDay: ""
      };
      return;
    }

    this._registrationDate = registrationDateString;
    const registrationDate = new Date(registrationDateString);
    const firstregistrationYear = registrationDate.getFullYear().toString();
    const monthNumber = registrationDate.getMonth() + 1;
    const dateNumber = registrationDate.getDate();
    let firstregistrationMonth = monthNumber.toString();
    if (monthNumber < 10) {
      firstregistrationMonth = "0" + monthNumber.toString();
    }
    let firstregistrationDay = dateNumber.toString();
    if (dateNumber < 10) {
      firstregistrationDay = "0" + dateNumber.toString();
    }

    this.registration = {
      ...this.registration,
      firstregistrationYear,
      firstregistrationMonth,
      firstregistrationDay
    };
  }

  getDriveType(key: string) {
    return driveTypeMap.get(key)?.text;
  }

  getVehicleClass(key: ThgVehicleTypes) {
    return thgVehicleClassMap.get(key)?.text;
  }

  blurChecksum(e: any) {
    this._checksum = e.target?.value || "";

    this.validate();
  }
  /**
   * make sure that interval is gone
   */
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyDown);
  }

  setUsernameFromIUser() {
    this.registration.firstname = this.selectedUser?.firstName;
    this.registration.name = this.selectedUser?.lastName;
  }

  setCompanyFromIUser() {
    if (this.selectedUser) {
      this.registration.name = deepCopy(this.selectedUser).company || ("" as any);
      this.registration.firstname = "" as any;
    }
  }

  get startTextFieldRef(): any {
    return this.$refs.startTextField;
  }

  mounted() {
    document.addEventListener("keyup", this.handleKeyDown);
    this.registration = deepCopy(this.selectedThg.registration);
    if (this.isResetValidation) {
      this.form.resetValidation();
    }
    this.setFocus();
  }

  get isCroppingActive() {
    return ClipperModule.isCroppingActive;
  }

  @Watch("isCroppingActive")
  setFocus() {
    if (!this.isCroppingActive) {
      setTimeout(() => {
        this.startTextFieldRef.$parent.$el["1"].focus();
        (this.$refs.form as IForm)?.validate();
      }, 100);
    }
  }

  @Watch("registration", { deep: true })
  registrationUpdate() {
    this.$log.debug(JSON.stringify(this.registration));
  }

  uppercase() {
    this.registration.identificationnumber = this.registration.identificationnumber?.toUpperCase();
  }

  get formFin(): Vue & { validate: () => boolean } {
    return this.$refs.fin as Vue & { validate: () => boolean };
  }

  get form(): Vue & { resetValidation: () => boolean } {
    return this.$refs.form as Vue & { resetValidation: () => boolean };
  }

  validate() {
    this.formFin.validate();
  }

  reset() {
    this.registration.identificationnumber = "";
    this.calculatedChecksum = 0;
    this._checksum = "";
    this.registration = {} as any;
  }

  get getChecksumText() {
    return "Check:" + calculateVinChecksum(this.registration.identificationnumber || "");
  }

  checkfin() {
    this.calculatedChecksum = calculateVinChecksum(this.registration.identificationnumber || "") as number;

    this.validate();
  }

  async submit() {
    this.$emit("submit", this.registration);
    this.reset();
  }

  /**
   * Rules
   */

  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get vinRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(vinRuleNotAllowedCharacter());
    rules.push(vinRuleLength());
    rules.push(vinRuleChecksum("Prüfziffern stimmen nicht überein", this._checksum as VinChecksum));

    return rules;
  }

  get noNumberAndRequiredRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(noNumberRule());

    return rules;
  }

  get noNumberRules() {
    const rules = [];
    rules.push(noNumberRule());

    return rules;
  }

  /**
   * default prefill
   */
  handleKeyDown(event: any) {
    if (event.altKey === true && event.shiftKey === false && event.key === "Enter") {
      this.setUsernameFromIUser();
    }

    if (event.altKey === true && event.shiftKey === true && event.key === "Enter") {
      this.setCompanyFromIUser();
    }

    if (event.ctrlKey === true && event.key === "Enter") {
      this.registration.vehicleClass = "M1";
      this.registration.driveTyp = "0004";
    }
  }
}
