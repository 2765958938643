var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 px-0"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " "), _c('v-spacer'), _vm._l(_vm.actions, function (action, index) {
    return _c('v-btn', {
      key: index,
      staticClass: "ml-2 d-none d-sm-block",
      attrs: {
        "elevation": "0",
        "small": "",
        "color": _vm.partnerColor.primary,
        "outlined": action.isOutlined,
        "dark": ""
      },
      on: {
        "click": action.click
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(action.title)) + " ")]);
  })], 2), _c('v-card-subtitle', {
    staticClass: "pl-0",
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.description))
    }
  })], 1)], 1)], 1), _c('notifications'), _vm._l(_vm.navItems, function (nav, i) {
    return _c('v-row', {
      key: i,
      staticClass: "pb-8",
      attrs: {
        "dense": ""
      }
    }, [nav.isEnabled ? [_c('v-col', {
      staticClass: "pb-4",
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      staticClass: "mb-0 h3"
    }, [_vm._v(_vm._s(_vm.$t(nav.title)))]), _c('v-divider')], 1), _vm._l(nav.items, function (item, j) {
      return _c('v-col', {
        key: j,
        attrs: {
          "cols": "6",
          "md": "4"
        }
      }, [item.isEnabled ? _c('v-card', {
        on: {
          "click": item.click
        }
      }, [_c('v-img', {
        staticClass: "white--text align-end",
        attrs: {
          "height": "150px",
          "gradient": "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)",
          "src": item.src
        }
      }, [_c('v-card-subtitle', [_vm._v(_vm._s(_vm.$t(item.title)))])], 1)], 1) : _vm._e()], 1);
    })] : _vm._e()], 2);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }