

















import ThgChargingStationForm from "@/components/thg/ThgChargingStationForm.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { HttpException } from "@/lib/exceptions/http";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { ChargingStationUiDto } from "@/store/models/thg/create-charging-station";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport, LayoutReportStep, ThgChargingStationForm }
})
export default class ThgChargingStationCreate extends Vue {
  loading = false;

  header: IReportHeaderData = {
    title: "report.thgChargingStation.title",
    description: "report.thgChargingStation.description"
  };

  instruction: IReportInstructionData = {
    title: "report.thgChargingStation.title",
    text: "report.thgChargingStation.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  async save(item: ChargingStationUiDto) {
    this.$log.debug(item);
    try {
      this.loading = true;
      await ChargingStationModule.createChargingStation({ ...item.toCreateMyChargingStation() });

      if (ChargingStationModule.activeChargingStation) {
        this.$router.push({
          name: "ThgMeterReadingCreate",
          params: { chargingStationId: ChargingStationModule.activeChargingStation.id }
        });
      } else {
        this.$router.push({ name: "ThgChargingStationOverview" });
      }
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }

  get isDone(): boolean {
    return true;
  }
}
