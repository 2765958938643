






import LoginCard from "@/components/login/LoginCard.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: { LayoutReport, LoginCard }
})
export default class ThgLogin extends Vue {
  logo = "https://static.mmmint.ai/wirkaufendeinethg/wirkaufendeinethg_logo.png";

  get title() {
    return this.$t("views.thg.ThgLogin.title");
  }
  get text() {
    return this.$t("views.thg.ThgLogin.text");
  }

  onLoginFunction = async () => {
    let loginScreen = "ThgOverview";

    if (this.$route.query.charging) {
      this.$log.info("Charging Station Context");

      loginScreen = "ThgChargingStationCreate";
    }

    if (this.$route.query.vehicle) {
      this.$log.info("Vehicle Context");
      loginScreen = ReportScreenEnum.thgimpactselector;
    }

    this.$router.push({ name: loginScreen });
  };

  /**
   * Try to set the Logo by priority
   */
  get setLogo() {
    if (PartnerModule.partner.settings?.reportSettings?.logoUrl) {
      return PartnerModule.partner.settings?.reportSettings?.logoUrl;
    }

    if (PartnerModule.partner.settings?.logoUrl) {
      return PartnerModule.partner.settings?.logoUrl;
    }
    return this.logo;
  }
}
