var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-radio-group', {
    attrs: {
      "disabled": _vm.isEdit,
      "row": "",
      "data-test-form-isCompany": ""
    },
    model: {
      value: _vm.isCompany,
      callback: function callback($$v) {
        _vm.isCompany = $$v;
      },
      expression: "isCompany"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.thgContactData.corporateClient'),
      "value": true,
      "data-test-form-iscompany": ""
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.thgContactData.private'),
      "value": false,
      "data-test-form-isprivate": ""
    }
  })], 1), _vm.isCompany ? _c('v-form', {
    ref: "chargingStationForm",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "loading": _vm.loading
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.ThgChargingStationForm.name'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.nameHint'),
      "required": "",
      "outlined": "",
      "data-test-form-name": ""
    },
    model: {
      value: _vm.chargingStation.name,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "name", $$v);
      },
      expression: "chargingStation.name"
    }
  }), _c('v-card-text', [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationForm.chargingStationOperaterTitle")) + " ")])]), _c('v-text-field', {
    attrs: {
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".chargingpointOperaterName")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".chargingpointOperaterNameHint")),
      "rules": _vm.requiredRule,
      "disabled": _vm.isEdit,
      "required": "",
      "outlined": "",
      "data-test-form-company": ""
    },
    model: {
      value: _vm.chargingStation.chargingPointOperator.name,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.chargingPointOperator, "name", $$v);
      },
      expression: "chargingStation.chargingPointOperator.name"
    }
  }), _vm.isBnetzA ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".bnetzaOperatorId")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".bnetzaOperatorIdHint")),
      "persistent-hint": "",
      "rules": _vm.bnetzaOperatorIdRule,
      "disabled": _vm.isEdit,
      "required": "",
      "outlined": "",
      "data-test-form-bnetza-operator-id": ""
    },
    model: {
      value: _vm.chargingStation.chargingPointOperator.bnetzaOperatorId,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.chargingPointOperator, "bnetzaOperatorId", $$v);
      },
      expression: "chargingStation.chargingPointOperator.bnetzaOperatorId"
    }
  }) : _vm._e(), _c('v-card-text', [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationForm.chargingStationLocationTitle")) + " ")])]), _c('v-text-field', {
    attrs: {
      "disabled": _vm.isEdit,
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.ThgChargingStationForm.street'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.addressHint'),
      "required": "",
      "outlined": "",
      "data-test-form-street": ""
    },
    model: {
      value: _vm.chargingStation.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "street", $$v);
      },
      expression: "chargingStation.address.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "disabled": _vm.isEdit,
      "rules": _vm.germanZipRule,
      "label": _vm.$t('components.thg.ThgChargingStationForm.zip'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.addressHint'),
      "required": "",
      "outlined": "",
      "data-test-form-zip": ""
    },
    model: {
      value: _vm.chargingStation.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "zip", $$v);
      },
      expression: "chargingStation.address.zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "disabled": _vm.isEdit,
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.ThgChargingStationForm.city'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.addressHint'),
      "required": "",
      "outlined": "",
      "data-test-form-city": ""
    },
    model: {
      value: _vm.chargingStation.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "city", $$v);
      },
      expression: "chargingStation.address.city"
    }
  }), _vm.detail ? _c('v-text-field', {
    attrs: {
      "disabled": _vm.isEdit,
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.ThgChargingStationForm.countryCode'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.countryCodeHint'),
      "required": "",
      "outlined": "",
      "data-test-form-country-code": ""
    },
    model: {
      value: _vm.chargingStation.address.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "countryCode", $$v);
      },
      expression: "chargingStation.address.countryCode"
    }
  }) : _vm._e(), _vm.detail ? _c('geo-finder-field', {
    attrs: {
      "address": _vm.chargingStation.address,
      "value": _vm.chargingStation.address.geo.lat,
      "type": "lat",
      "label": _vm.$t('components.thg.ThgChargingStationForm.geoLat'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.geoLatHint'),
      "disabled": _vm.isEdit,
      "data-test-form-lat": ""
    },
    on: {
      "update:address": function updateAddress($event) {
        return _vm.$set(_vm.chargingStation, "address", $event);
      },
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.chargingStation.address.geo, "lat", $event);
      }
    }
  }) : _vm._e(), _vm.detail ? _c('geo-finder-field', {
    attrs: {
      "address": _vm.chargingStation.address,
      "value": _vm.chargingStation.address.geo.lng,
      "type": "lng",
      "label": _vm.$t('components.thg.ThgChargingStationForm.geoLng'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.geoLngHint'),
      "disabled": _vm.isEdit,
      "data-test-form-lng": ""
    },
    on: {
      "update:address": function updateAddress($event) {
        return _vm.$set(_vm.chargingStation, "address", $event);
      },
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.chargingStation.address.geo, "lng", $event);
      }
    }
  }) : _vm._e(), _c('v-card-text', [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationForm.chargingStationData")) + " ")])]), _c('v-slider', {
    staticClass: "mt-8 mb-4 align-center",
    attrs: {
      "label": _vm.$t('components.thg.ThgChargingStationForm.chargingPointCount'),
      "hint": _vm.chargingPointAmountHint,
      "persistent-hint": "",
      "rules": _vm.chargingPointNumberRule,
      "min": _vm.minChargingPointAmount,
      "max": _vm.maxChargingPointAmount,
      "disabled": _vm.isEdit,
      "color": _vm.chargingPointAmountSliderColor,
      "type": "number",
      "thumb-label": "always"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-0 pt-0",
          staticStyle: {
            "width": "80px"
          },
          attrs: {
            "rules": _vm.chargingPointNumberRule,
            "type": "number",
            "min": _vm.minChargingPointAmount,
            "max": _vm.maxChargingPointAmount,
            "disabled": _vm.isEdit,
            "outlined": "",
            "data-test-form-charging-point-count": ""
          },
          model: {
            value: _vm.chargingStation.chargingPointCount,
            callback: function callback($$v) {
              _vm.$set(_vm.chargingStation, "chargingPointCount", $$v);
            },
            expression: "chargingStation.chargingPointCount"
          }
        })];
      },
      proxy: true
    }], null, false, 428425145),
    model: {
      value: _vm.chargingStation.chargingPointCount,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "chargingPointCount", _vm._n($$v));
      },
      expression: "chargingStation.chargingPointCount"
    }
  }), _vm.isEvseEnabled ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.thg.ThgChargingStationForm.evseOperatorId'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.evseOperatorIdHint'),
      "disabled": _vm.isEdit,
      "persistent-hint": "",
      "required": "",
      "outlined": "",
      "data-test-form-evseId-id": "",
      "minlength": "3",
      "maxlength": "3"
    },
    model: {
      value: _vm.chargingStation.chargingPointOperator.evseOperatorId,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.chargingPointOperator, "evseOperatorId", $$v);
      },
      expression: "chargingStation.chargingPointOperator.evseOperatorId"
    }
  }) : _vm._e(), _vm.chargingStation.chargingPointOperator.evseOperatorId ? _c('div', _vm._l(_vm.chargingStation.chargingPointCount, function (i) {
    return _c('v-text-field', {
      key: i,
      ref: "powerOutletId",
      refInFor: true,
      attrs: {
        "rules": _vm.evseOutletRule,
        "label": _vm.$t('components.thg.ThgChargingStationForm.evseOutletId'),
        "hint": _vm.$t('components.thg.ThgChargingStationForm.evseOutletIdHint'),
        "disabled": _vm.isEdit,
        "minlength": "1",
        "maxlength": "30",
        "autocomplete": "off",
        "type": "text",
        "outlined": "",
        "prefix": _vm.evseId,
        "data-test-form-evse-power-outlet-id": ""
      },
      model: {
        value: _vm.powerOutletId[i - 1],
        callback: function callback($$v) {
          _vm.$set(_vm.powerOutletId, i - 1, $$v);
        },
        expression: "powerOutletId[i - 1]"
      }
    });
  }), 1) : _vm._e(), _c('v-text-field', {
    staticClass: "pt-4",
    attrs: {
      "rules": _vm.connectionPowerRule,
      "min": _vm.minConnectionPower,
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".connectionPowerInKwh")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".connectionPowerInKwhHint")),
      "disabled": _vm.isEdit,
      "required": "",
      "outlined": "",
      "type": "number",
      "thumb-label": "always",
      "suffix": "kW",
      "data-test-form-connection-power": ""
    },
    model: {
      value: _vm.chargingStation.connectionPowerInKwh,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "connectionPowerInKwh", _vm._n($$v));
      },
      expression: "chargingStation.connectionPowerInKwh"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "date",
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.ThgChargingStationForm.commissioningDate'),
      "hint": _vm.$t('components.thg.ThgChargingStationForm.commissioningDateHint'),
      "disabled": _vm.isEdit,
      "outlined": "",
      "persistent-hint": "",
      "data-test-form-commissioningDate": ""
    },
    on: {
      "blur": function blur($event) {
        _vm.chargingStation.commissioningDate = $event.target.value;
      }
    },
    model: {
      value: _vm.commissioningDate,
      callback: function callback($$v) {
        _vm.commissioningDate = $$v;
      },
      expression: "commissioningDate"
    }
  }), _c('v-card-text', {
    staticClass: "px-0 pb-0"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".registration"))))])]), _c('v-select', {
    staticClass: "mb-8 pt-4",
    attrs: {
      "items": _vm.isRegisteredItems,
      "persistent-hint": "",
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".isRegistered")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".isRegisteredHint")),
      "disabled": _vm.isEdit,
      "outlined": "",
      "data-test-form-registered": ""
    },
    model: {
      value: _vm.chargingStation.isRegistered,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "isRegistered", $$v);
      },
      expression: "chargingStation.isRegistered"
    }
  }), _vm.chargingStation.isRegistered ? _c('v-text-field', {
    attrs: {
      "type": "date",
      "rules": _vm.requiredRule,
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".registrationDate")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".registrationDateHint")),
      "disabled": _vm.isEdit,
      "outlined": "",
      "persistent-hint": "",
      "data-test-form-registrationDate": ""
    },
    on: {
      "blur": function blur($event) {
        _vm.chargingStation.registrationDate = $event.target.value;
      }
    },
    model: {
      value: _vm.registrationDate,
      callback: function callback($$v) {
        _vm.registrationDate = $$v;
      },
      expression: "registrationDate"
    }
  }) : _vm._e(), _c('v-card-text', {
    staticClass: "px-0 pb-0"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("components.thg.ThgChargingStationForm.renewableEnergy")))])]), _c('v-select', {
    staticClass: "mb-8 pt-4",
    attrs: {
      "items": _vm.isRenewableEnergyItems,
      "persistent-hint": "",
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".isRenewableEnergy")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".isRenewableEnergyHint")),
      "disabled": _vm.isEdit,
      "outlined": "",
      "data-test-form-renewable-energy": ""
    },
    model: {
      value: _vm.chargingStation.isFromRenewableEnergy,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "isFromRenewableEnergy", $$v);
      },
      expression: "chargingStation.isFromRenewableEnergy"
    }
  }), _vm.chargingStation.isFromRenewableEnergy && _vm.isRenewableChargingStationEnabled ? _c('div', [_vm.chargingStation.isFromRenewableEnergy ? _c('v-textarea', {
    attrs: {
      "rules": _vm.requiredRule,
      "disabled": _vm.isEdit,
      "outlined": "",
      "persistent-hint": "",
      "label": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".renewableEnergyDescription")),
      "hint": _vm.$t("components.thg.ThgChargingStationForm.".concat(_vm.countryCode, ".renewableEnergyDescriptionHint"))
    },
    model: {
      value: _vm.chargingStation.renewableEnergySourceDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "renewableEnergySourceDescription", $$v);
      },
      expression: "chargingStation.renewableEnergySourceDescription"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "primary",
      "disabled": !_vm.valid,
      "data-test-form-submit": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationForm.submit")) + " ")])], 1)], 1) : _vm._e(), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [!_vm.isCompany ? _c('v-card-text', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.thg.ThgChargingStationForm.isPrivatePerson'))
    }
  })]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }