











































































































































































import { requiredRule } from "@/lib/rules/requiredRule";
import { isNumberWithinRangeRule } from "@/lib/rules/isNumberWithinRangeRule";
import { IThg } from "@/models/thg.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { PromotionTypeEnum } from "@/lib/enum/promotion-type.enum";
import { IUpdateThg, UpdateThg } from "@/models/update-thg.entity.ts";
import { deepCopy } from "@/lib/utility/deep-copy";
import { UnitEnum } from "@/lib/enum/unit.enum";

@Component({
  components: { Debug }
})
export default class ThgAdminUpdatePayoutConfigurationDialog extends Vue {
  @Prop()
  thg!: IThg;

  /**
   * Displays loading animation
   */
  @Prop({ default: false })
  loading!: boolean;

  readonly PromotionTypeEnum = PromotionTypeEnum;

  /**
   * Force refresh to fix reactivity after promotion object was added/removed from THG
   */
  promoUpdateKey = 0;

  /**
   * Form valid
   */
  isValid = false;

  /**
   * Show Dialog
   */
  isDialogActive = false;

  updateModel: IUpdateThg | null = null;

  openDialog() {
    this.isDialogActive = true;
    this.updateModel = new UpdateThg(deepCopy(this.thg));
  }

  get units() {
    return Object.values(UnitEnum);
  }

  get requiredRule() {
    return requiredRule();
  }

  get minZeroNumberRule() {
    return isNumberWithinRangeRule(0);
  }
  removePromo() {
    if (!this.updateModel?.promotionConfiguration) return;
    this.updateModel.promotionConfiguration = null;
    this.promoUpdateKey++;
  }

  addPromo() {
    if (!this.updateModel) return;
    this.updateModel.promotionConfiguration = { type: PromotionTypeEnum.FIRST_PURCHASE, value: 0 };
    this.promoUpdateKey++;
  }

  async save(): Promise<IThg> {
    this.isDialogActive = false;

    this.$emit("save", this.updateModel);
    this.close();

    return this.thg;
  }

  async close() {
    this.isDialogActive = false;
    this.$emit("refresh");
  }
}
