
import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IThg } from "@/models/thg.entity";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ThgModule } from "@/store/modules/thg.store";
import { Component, Prop } from "vue-property-decorator";
import ThgQuoteUpdateRegistrationImageDialog from "./ThgQuoteUpdateRegistrationImageDialog.vue";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard,
    FileUploadMulti
  }
})
export default class ThgQuoteUpdateMyRegistrationImageDialog extends ThgQuoteUpdateRegistrationImageDialog {
  @Prop()
  myThg!: IThg;

  bigButton = true;

  multiSteps = {
    frontLeft: {
      example: AssetEnum.registration,
      overlay: AssetEnum.registrationSchema,
      text: "report.thgRegistration.registrationFront",
      textAlt: "report.thgRegistration.registrationFrontAlt",
      filesCb: ThgCreateModule.registrationImageFront,
      handleUpload: ThgCreateModule.addRegistrationFront,
      handleDelete: ThgCreateModule.removeRegistrationFront
    },
    rearLeft: {
      example: AssetEnum.registrationBack,
      overlay: AssetEnum.registrationBackOutline,
      text: "report.thgRegistration.registrationBack",
      textAlt: "report.thgRegistration.registrationBackAlt",
      filesCb: ThgCreateModule.registrationImageBack,
      handleUpload: ThgCreateModule.addRegistrationBack,
      handleDelete: ThgCreateModule.removeFromRegistrationImageBack
    }
  };

  async update() {
    this.isLoading = true;
    try {
      await ThgModule.updateMyRegistrationImages(this.myThg);
      this.$router.push({ name: "ThgSuccess" });
    } catch (error) {
      this.$toast.error(this.$t("components.thg.ThgUpdateRegistrationImagesDialog.error").toString());
      ErrorLogModule.addErrorLog({
        name: "Update myregistrationimage error",
        message: "Could not update my registration image."
      });
    } finally {
      this.isLoading = false;
      this.dialog = false;
    }
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }
}
