

































import { Component, Vue } from "vue-property-decorator";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import FileUploadMulti from "../files/FileUploadMulti.vue";

@Component({
  components: { FileUploadMulti }
})
export default class ThgRegistrationUploadCard extends Vue {
  multiSteps = {
    frontLeft: {
      example: AssetEnum.registration,
      overlay: AssetEnum.registrationSchema,
      text: "report.thgRegistration.registrationFront",
      textAlt: "report.thgRegistration.registrationFrontAlt",
      filesCb: ThgCreateModule.registrationImageFront,
      handleUpload: async (file: File) => {
        const upload = await ThgCreateModule.addRegistrationFront(file);
        this.$emit("front-uploaded", upload);
      },
      handleDelete: ThgCreateModule.removeRegistrationFront
    },
    rearLeft: {
      example: AssetEnum.registrationBack,
      overlay: AssetEnum.registrationBackOutline,
      text: "report.thgRegistration.registrationBack",
      textAlt: "report.thgRegistration.registrationBackAlt",
      filesCb: ThgCreateModule.registrationImageBack,
      handleUpload: async (file: File) => {
        const upload = await ThgCreateModule.addRegistrationBack(file);
        this.$emit("back-uploaded", upload);
      },
      handleDelete: ThgCreateModule.removeFromRegistrationImageBack
    }
  };

  get getRegistrationImageFront() {
    return ThgCreateModule.registrationImageFront;
  }

  get getRegistrationImageBack() {
    return ThgCreateModule.registrationImageBack;
  }

  instruction: IReportInstructionData = {
    title: "report.thgRegistration.title",
    text: "report.thgRegistration.instructionText",
    pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
    example: AssetEnum.registrationExample,
    display: true
  };
}
