

































































import ThgImpactFactorSlider from "@/components/thg/ThgImpactFactorSlider.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import { ImpactTypeEnum } from "../../views/thg/enum/impact-type.enum";

@Component({
  components: {
    LayoutReportStep,
    ThgImpactFactorSlider
  }
})
export default class ThgImpactFactor extends ReportStepMixin implements IReportStep {
  [x: string]: any;
  header: IReportHeaderData = {
    title: "report.thgImpactFactor.title",
    description: ThgCreateModule.impactTypeItem.description
  };

  screen = ReportScreenEnum.thgimpactfactor;

  get isWhiteLabelPartner(): boolean {
    return PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_QUOTE_AS_A_SERVICE;
  }

  get sliderValue(): number {
    return ThgCreateModule.impactFactor;
  }

  get company() {
    return ReportModule.partner.companyName;
  }

  get icon(): string {
    return ThgCreateModule.impactTypeItem.icon;
  }

  get impactType(): ImpactTypeEnum {
    return ThgCreateModule.impactType;
  }

  get showImpactSlider(): boolean {
    return ThgCreateModule.impactTypeItem.showImpactFactorSlider;
  }

  get showImpactFactorSlider(): boolean {
    return ThgCreateModule.impactTypeItem.showImpactFactorSlider;
  }

  get cashImpactInvestment() {
    return this.sliderValue;
  }

  get cashPayment() {
    return 100 - this.sliderValue;
  }

  get item() {
    return ThgCreateModule.impactTypeItem;
  }

  get itemPayout() {
    return ThgCreateModule.itemPayout;
  }

  get isDone() {
    return true;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isRetried() {
    return this.item.impactType === ImpactTypeEnum.retire;
  }

  updateImpactFactor() {
    ThgCreateModule.setImpactFactor(this.sliderValue);
  }

  disablePastDates(val: string) {
    return val >= new Date().toISOString().substr(0, 10);
  }
}
