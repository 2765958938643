var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-10 mx-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    style: _vm.partnerColor.gradientStyle
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "append-icon": "mdi-magnify",
      "label": _vm.$t('components.analytics.AnalyticsDataTableDialog.search'),
      "single-line": "",
      "hide-details": "",
      "solo": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.selection.length === 0,
      "color": _vm.partnerColor.primaryDarken_2,
      "dark": ""
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(" Herunterladen ")]), _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "color": _vm.partnerColor.primaryDarken_2,
      "dark": ""
    },
    on: {
      "click": _vm.goToBanking
    }
  }, [_vm._v(" Auszahlungskonto ")])], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.myBillings,
      "search": _vm.search,
      "items-per-page": 10,
      "show-select": "",
      "custom-filter": _vm.filterAll,
      "item-key": "billingNumber",
      "sort-by": "timestamp.created",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.timestamp.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.timestamp.created).toLocaleString()))])];
      }
    }, {
      key: "item.thgId",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.thgYearMap.get(item.thgId)) + " ")];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.i18nBilling[item.type]) + " ")];
      }
    }, {
      key: "item.billingNumber",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          on: {
            "click": function click() {
              return _vm.downloadBilling(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.billingNumber) + " ")])];
      }
    }], null, true),
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  }, [_c('template', {
    slot: "no-data"
  }, [_c('latest-entries-card-empty', {
    staticClass: "ma-6"
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }