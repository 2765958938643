
import ThgCreateOverviewCard from "@/components/thg/ThgCreateOverviewCard.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import ThgCheckout from "./ThgCheckout.vue";
import ThgCheckoutCard from "./ThgCheckoutCard.vue";

@Component({
  components: {
    LayoutReport,
    ThgCreateOverviewCard,
    ThgCheckoutCard
  }
})
export default class ThgCheckoutWithHint extends ThgCheckout {
  screen = ReportScreenEnum.thgCheckoutWithHint;

  get hint() {
    return this.$t("report.thgClosing.hint", { partner: this.partnerName }).toString();
  }
}
