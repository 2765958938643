







































import { PartnerColor } from "@/lib/partnerColor";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgVehicleTableLegendDialog extends DarkModeHighlightMixin {
  dialog = false;

  close() {
    this.dialog = false;
  }

  partnerColor = new PartnerColor();
}
