



































































import ImageDialog from "@/components/utility/ImageDialog.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { IThgImpact } from "@/views/thg/interface/ThgImpact.interface";
import { Component, Prop } from "vue-property-decorator";
import NumberplateForm from "../utility/NumberplateForm.vue";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import ThgCheckoutCard from "@/views/thg/ThgCheckoutCard.vue";
import ThgPromotionListItem from "./ThgPromotionListItem.vue";
import { mixins } from "vue-class-component";
import ThgImpactSelectorScreensMixin from "./mixins/ThgImpactSelectorScreensMixin.vue";
import ThgYearSelectionScreensMixin from "./mixins/ThgYearSelectionScreensMixin.vue";

@Component({
  components: {
    ImageDialog,
    NumberplateForm,
    ThgCheckoutCard,
    ThgPromotionListItem
  }
})
export default class ThgCheckoutOrderCard extends mixins(ThgImpactSelectorScreensMixin, ThgYearSelectionScreensMixin) {
  @Prop({ default: false })
  hideEdit?: boolean;

  VehicleTypes = ThgVehicleTypes;

  price(year: number) {
    return PartnerActiveOfferModule.priceConfig.get({
      year: year,
      vehicleClass: ThgCreateModule.vehicleClass || ThgVehicleTypes.M1
    });
  }

  get activePromotion() {
    return PartnerActiveOfferModule.currentSignUpPromotion;
  }

  get countryCode() {
    return ReportModule?.partner?.countryCode;
  }

  partnerColor = new PartnerColor();

  get years(): number[] {
    return ThgCreateModule.years;
  }

  get showImpact(): number {
    if (ThgCreateModule.impactType === ImpactTypeEnum.customerAccount) {
      return 0;
    }
    return ThgCreateModule.impactFactor;
  }

  showValueOrDefault(value: string, title: string) {
    if (!value.replace(" ", "")) {
      return this.$t("components.thg.ThgCreateOverviewCard.defaultValue", { title: this.$t(title) });
    }

    return value;
  }

  goToImpactSelector() {
    const screenName = this.impactSelectorScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.impactSelector"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.impactSelector"));
    }
  }

  goToYearSelection() {
    const screenName = this.yearSelectionScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.yearSelection"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.yearSelection"));
    }
  }

  goToScreen(screenName: ReportScreenEnum) {
    ReportModule.setStep(screenName);
    this.$router.push({ name: screenName });
  }

  get impact(): string {
    return ThgCreateModule.impactFactor + " %";
  }

  get vehicleClass() {
    if (ThgCreateModule.vehicleClass) {
      const vehicle = PartnerActiveOfferModule.priceConfig.vehicleClassInformation(ThgCreateModule.vehicleClass);
      if (vehicle) {
        return vehicle;
      }
    }
    return undefined;
  }

  get src() {
    if (this.vehicleClass) {
      return this.vehicleClass.src;
    }

    return "https://static.mmmint.ai/wirkaufendeinethg/photo-1580048915913-4f8f5cb481c4-1.jpeg";
  }

  get impactType(): IThgImpact {
    return ThgCreateModule.impactTypeItem;
  }
}
