






import SignUpCard from "@/components/login/SignUpCard.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport, SignUpCard }
})
export default class ThgSignUp extends Vue {
  logo = "https://static.mmmint.ai/wirkaufendeinethg/wirkaufendeinethg_logo.png";

  get title() {
    return this.$t("components.login.ThgSignUp.title");
  }

  get text() {
    return this.$t("components.login.ThgSignUp.text");
  }

  /**
   * Try to set the Logo by priority
   */
  get setLogo() {
    if (PartnerModule.partner.settings?.reportSettings?.logoUrl) {
      return PartnerModule.partner.settings?.reportSettings?.logoUrl;
    }

    if (PartnerModule.partner.settings?.logoUrl) {
      return PartnerModule.partner.settings?.logoUrl;
    }

    return this.logo;
  }
}
