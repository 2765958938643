
































































































import ThgBillingOverviewCard from "@/components/thg/ThgBillingOverviewCard.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { Component, Vue } from "vue-property-decorator";
import { BillingModule } from "@/store/modules/billing.store";
import { ThgModule } from "@/store/modules/thg.store";
import { AccountModule } from "@/store/modules/account.store";
import { handleError } from "@/lib/utility/handleError";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { FeatureModule } from "@/store/modules/feature.store";
import { formatCommaEuroToDottedComma } from "@/lib/utility/number-helper";

@Component({
  components: {
    ThgBillingOverviewCard,
    LayoutReport
  }
})
export default class ThgBillingOverview extends Vue {
  CHARGING_ACCOUNT = 16160;
  TREES_ACCOUNT = 16150;

  isLoading = false;
  accountNumber: number | null = null;

  incomingTotal = "0,00";
  incomingCustomerAccount = "0,00";
  incomingAffiliate = "0,00";
  outgoingTrees = "0,00";
  outgoingCharging = "0,00";

  get isEnabled() {
    return FeatureModule.isBillingDistributionEnabled;
  }

  get myBillings() {
    return BillingModule.myBillings;
  }
  async mounted() {
    this.isLoading = true;

    await Promise.all([this.loadBillings(), this.loadThgs()]);
    if (this.myBillings.length) {
      await this.getUserAccountNumber();
    }
    this.calculatePayoutDistribution();

    this.isLoading = false;
  }

  euroCentSplit(s: string) {
    return s.split(",");
  }

  async getUserAccountNumber() {
    try {
      this.accountNumber = await AccountModule.getUserAccountNumber();
    } catch (e) {
      handleError(e);
    }
  }

  calculatePayoutDistribution() {
    let incomingTotal = 0;
    let incomingCustomerAccount = 0;
    let incomingAffiliate = 0;
    let outgoingTrees = 0;
    let outgoingCharging = 0;

    this.myBillings.forEach(b => [
      b.accountRecord.forEach((ar: any) => {
        if (ar?.account === this.accountNumber) {
          incomingTotal += ar.amount;
          if (b.type === BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT) {
            incomingCustomerAccount += ar?.amount;
          }
          if (b.type === BillingTypeEnum.AFFILIATE) {
            incomingAffiliate += ar?.amount;
          }
        }
        if (ar?.contraAccount === this.accountNumber) {
          if (ar?.account === this.TREES_ACCOUNT) {
            outgoingTrees += ar?.amount;
          }
          if (ar?.account === this.CHARGING_ACCOUNT) {
            outgoingCharging += ar?.amount;
          }
        }
      })
    ]);

    this.incomingTotal = formatCommaEuroToDottedComma(incomingTotal);
    this.incomingCustomerAccount = formatCommaEuroToDottedComma(incomingCustomerAccount);
    this.incomingAffiliate = formatCommaEuroToDottedComma(incomingAffiliate);
    this.outgoingTrees = formatCommaEuroToDottedComma(outgoingTrees);
    this.outgoingCharging = formatCommaEuroToDottedComma(outgoingCharging);
  }

  async loadThgs() {
    try {
      if (!ThgModule.myThgs?.length) {
        await ThgModule.getMyThgs();
      }
    } catch (e) {
      handleError(e);
    }
  }

  async loadBillings() {
    try {
      await BillingModule.setMyBillings();
    } catch (e) {
      handleError(e);
    }
  }
}
