var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('p', {
    staticClass: "display-1 text--primary"
  }, [_vm._v(_vm._s(_vm.$t("report.thgClosing.title")))]), _c('p', {
    staticClass: "mb-0 pb-4 autoHypen",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('report.thgClosing.description'))
    }
  })]), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('thg-checkout-vehicle-card'), !_vm.isAuthenticated ? _c('thg-checkout-personal-data-card', {
    attrs: {
      "dialogCloseButtonFunction": _vm.dialogCloseButtonFunction,
      "hideTitle": _vm.hideTitle,
      "hideEdit": _vm.hideEdit
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "mb-10",
    attrs: {
      "max-width": "850",
      "flat": "",
      "outlined": ""
    }
  }, [!_vm.hideTitle ? _c('v-card-title', {
    staticClass: "title pt-4",
    style: _vm.partnerColor.gradientStyle
  }, [_c('span', {
    style: "color:white"
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgCheckoutCard.title")))]), _c('v-spacer')], 1) : _vm._e(), _c('thg-checkout-order-card'), _c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-checkbox', {
    ref: "reviewed",
    staticClass: "mx-4",
    attrs: {
      "disabled": _vm.userExists,
      "dense": "",
      "rules": _vm.reportReviewedRules,
      "label": _vm.$t('report.thgClosing.confirmation'),
      "data-test-review-checkbox": ""
    },
    model: {
      value: _vm.isConfirmed,
      callback: function callback($$v) {
        _vm.isConfirmed = $$v;
      },
      expression: "isConfirmed"
    }
  }), _c('v-checkbox', {
    ref: "privacy",
    staticClass: "mx-4",
    attrs: {
      "rules": [function (v) {
        return !!v || _vm.$t('report.thgClosing.readPrivacy');
      }],
      "dense": "",
      "disabled": _vm.userExists,
      "label": _vm.$t('report.thgClosing.confirmationPrivacy'),
      "data-test-privacy-checkbox": ""
    },
    model: {
      value: _vm.isTermsAccepted,
      callback: function callback($$v) {
        _vm.isTermsAccepted = $$v;
      },
      expression: "isTermsAccepted"
    }
  }), !_vm.isAuthenticated ? _c('v-checkbox', {
    staticClass: "mx-4",
    attrs: {
      "disabled": _vm.userExists,
      "dense": "",
      "label": _vm.$t('report.thgClosing.marketing'),
      "data-test-marketing-checkbox": ""
    },
    model: {
      value: _vm.isMarketingContactAllowed,
      callback: function callback($$v) {
        _vm.isMarketingContactAllowed = $$v;
      },
      expression: "isMarketingContactAllowed"
    }
  }) : _vm._e(), _vm.hint ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.hint))]) : _vm._e(), _c('div', {
    staticClass: "px-4 mb-4"
  }, [!_vm.userExists && !_vm.hideSubmit ? _c('v-btn', {
    attrs: {
      "color": "success",
      "block": "",
      "x-large": "",
      "disabled": !_vm.isDone,
      "loading": _vm.isLoading,
      "data-test-submit-button": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-send ")]), _vm._v(" " + _vm._s(_vm.$t("report.thgCheckoutCard.submit")) + " ")], 1) : _vm._e()], 1)], 1), _vm.userExists ? _c('v-card-text', {
    staticClass: "red--text"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.userExistsText)))]) : _vm._e(), _c('div', {
    staticClass: "px-4 mb-4"
  }, [_vm.userExists ? _c('v-btn', {
    attrs: {
      "color": "success",
      "x-large": "",
      "block": "",
      "loading": _vm.isLoading,
      "data-test-submit-button": ""
    },
    on: {
      "click": _vm.goToLogin
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgClosing.login")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "text-center text-caption mb-4 mx-4"
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-shield-check")]), _vm._v(_vm._s(_vm.$t("report.trustBanner.privacy")) + " "), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-lock")]), _vm._v(" " + _vm._s(_vm.$t("report.trustBanner.encryption")) + " "), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check-decagram")]), _vm._v(" " + _vm._s(_vm.$t("report.trustBanner.datacenter")) + " ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }