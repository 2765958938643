var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "proof-container"
  }, [_c('p', {
    staticClass: "px-0 pb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationProof.addProofText")) + " ")]), _c('v-file-input', {
    attrs: {
      "show-size": "",
      "accept": "application/pdf, .pdf, image/jpeg, image/png",
      "label": _vm.$t('components.thg.ThgChargingStationProof.filePlaceholder'),
      "rules": _vm.rules,
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "readonly": _vm.disabled
    },
    on: {
      "change": _vm.onChange
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }