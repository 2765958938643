var render, staticRenderFns
import script from "./ThgCheckoutWithHint.vue?vue&type=script&lang=ts&"
export * from "./ThgCheckoutWithHint.vue?vue&type=script&lang=ts&"
import style0 from "./ThgCheckoutWithHint.vue?vue&type=style&index=0&id=2141a45d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2141a45d",
  null
  
)

export default component.exports