import zipService from "@/services/thg/services/zipService";
import {
  ThgCheckZipViewmodelGen,
  ThgCreatePartnerZipDtoGen,
  ThgPartnerZipViewmodelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "zip",
  store
})
export class ZipStore extends VuexModule {
  private _zip: ThgPartnerZipViewmodelGen | undefined;
  private _loadedPartnerZip = false;
  private _checkZipResult: ThgCheckZipViewmodelGen = {
    partnerId: "",
    isServiceAvailable: false,
    zip: {
      zip: "",
      city: ""
    }
  };

  get zip() {
    return this._zip;
  }

  get checkZipResult() {
    return this._checkZipResult;
  }
  get loadedPartnerZip() {
    return this._loadedPartnerZip;
  }

  @Mutation
  mutateZip(zip: ThgPartnerZipViewmodelGen) {
    this._zip = zip;
  }

  @Mutation
  mutateCheckZipResult(checkZipResult: ThgCheckZipViewmodelGen) {
    this._checkZipResult = checkZipResult;
  }

  @Mutation
  mutateLoadedPartnerZip(loadedPartnerZip: boolean) {
    this._loadedPartnerZip = loadedPartnerZip;
  }

  @Action
  async create(data: { partnerId: string; data: ThgCreatePartnerZipDtoGen }): Promise<ThgPartnerZipViewmodelGen> {
    const res = await zipService.createOrUpdateForPartner(data.partnerId, data.data);

    this.context.commit("mutateZip", res);

    return res;
  }

  @Action
  async update(data: { partnerId: string; data: ThgCreatePartnerZipDtoGen }): Promise<ThgPartnerZipViewmodelGen> {
    const res = await zipService.createOrUpdateForPartner(data.partnerId, data.data);

    this.context.commit("mutateZip", res);

    return res;
  }

  @Action
  async checkZip(data: { partnerId: string; zip: string }): Promise<ThgCheckZipViewmodelGen> {
    const res = await zipService.checkZip(data.partnerId, data.zip);

    this.context.commit("mutateCheckZipResult", res);

    return res;
  }

  @Action
  async getForPartner(data: { partnerId: string }) {
    this.context.commit("mutateLoadedPartnerZip", false);

    const res = await zipService.getForPartner(data.partnerId);

    this.context.commit("mutateZip", res);
    this.context.commit("mutateLoadedPartnerZip", true);

    return res;
  }
}

export const ZipModule = getModule(ZipStore);
