import { $t } from "../utility/t";

export function isNumberWithinRangeRule(min: number, max?: number, message?: string): (value: any) => boolean | string {
  if (!message && max) {
    message = $t("components.partner.PartnerReportDetail.SendToKsrDialog.isNumberWithinMinMaxRange", { min, max });
  } else if (!message) {
    message = $t("components.partner.PartnerReportDetail.SendToKsrDialog.isNumberWithinMinRange", { min });
  }

  return (value: string) => {
    if (isNaN(min)) {
      return $t(message as string);
    }

    if (Number(value) < min || (max && Number(value) > max)) {
      return $t(message as string);
    }

    return true;
  };
}
