






























import LayoutReport from "@/layouts/LayoutReport.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport }
})
export default class ThgInsuranceCheck extends Vue {
  get color() {
    return getDefaultPartnerColor();
  }

  get partner() {
    return ReportModule.partner;
  }

  mounted() {
    const widgetScript = document.createElement("script");
    widgetScript.setAttribute(
      "src",
      "https://form.partner-versicherung.de/widgets/133924/tcpp-iframe-kfz/kfz-iframe.js"
    );
    document.head.appendChild(widgetScript);
  }
}
