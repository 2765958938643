













import { Component, Vue, Prop } from "vue-property-decorator";
import ImageOnHoverChangeColor from "@/components/utility/ImageOnHoverChangeColor.vue";

@Component({
  components: {
    ImageOnHoverChangeColor
  }
})
export default class KnownFormCard extends Vue {
  @Prop({ default: 800 })
  maxWidth!: number;

  @Prop()
  headline!: "";

  @Prop()
  logos!: string[];

  @Prop({ default: "text-caption font-weight-light font-italic" })
  textStyle!: string[];
}
