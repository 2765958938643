






















































import CreateOrUpdateBankDialog from "@/components/profile/CreateOrUpdateBankDialog.vue";
import ThgDescriptionSvgCar from "@/components/thg/ThgDescriptionSvgCar.vue";
import ThgDescriptionSvgAgency from "@/components/thg/ThgDescriptionSvgAgency.vue";
import ThgDescriptionSvgRegistration from "@/components/thg/ThgDescriptionSvgRegistration.vue";
import ThgDescriptionSvgSustainability from "@/components/thg/ThgDescriptionSvgSustainability.vue";
import ThgDescriptionSvgQuote from "@/components/thg/ThgDescriptionSvgQuote.vue";
import ThgDescriptionSvgYearly from "@/components/thg/ThgDescriptionSvgYearly.vue";
import DescriptionCard from "@/components/thg/DescriptionCard.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    CreateOrUpdateBankDialog,
    ThgDescriptionSvgRegistration,
    ThgDescriptionSvgCar,
    DescriptionCard,
    ThgDescriptionSvgAgency,
    ThgDescriptionSvgQuote,
    ThgDescriptionSvgSustainability,
    ThgDescriptionSvgYearly
  }
})
export default class ThgProcessDescriptionCard extends Vue {}
