































































































































import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { IBankingDto } from "@/store/interface/thg/banking.interface";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { Component, Prop } from "vue-property-decorator";

import ThgContactScreensMixin from "@/components/thg/mixins/ThgContactScreensMixin.vue";
@Component({
  components: {}
})
export default class ThgCheckoutPersonalDataCard extends ThgContactScreensMixin {
  @Prop({ default: false })
  hideEdit?: boolean;

  get banking(): IBankingDto {
    return ThgCreateModule.banking;
  }

  get name(): string {
    return ThgCreateModule.user.firstName + " " + ThgCreateModule.user.lastName;
  }

  get email(): string {
    return ThgCreateModule.contact.email;
  }

  get isCompany(): boolean {
    return ThgCreateModule.isCompany;
  }

  get company(): string {
    return ThgCreateModule.company;
  }

  get isTaxDeductible(): boolean {
    return ThgCreateModule.isTaxDeductible;
  }

  get taxNumber(): string {
    return ThgCreateModule.taxnumber;
  }

  get showCustomerAccount(): boolean {
    return ThgCreateModule.impactType === ImpactTypeEnum.customerAccount;
  }

  showValueOrDefault(value: string, title: string) {
    if (!value.replace(" ", "")) {
      return this.$t("components.thg.ThgCreateOverviewCard.defaultValue", { title: this.$t(title) });
    }

    return value;
  }

  goToContactData() {
    const screenName = this.contactScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.thgcontact"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.thgcontact"));
    }
  }

  goToScreen(screenName: ReportScreenEnum) {
    ReportModule.setStep(screenName);
    this.$router.push({ name: screenName });
  }

  get phone(): string {
    return ThgCreateModule.contact.phone;
  }

  get isResubmitAddress(): boolean {
    return ThgCreateModule.isResubmitAddress;
  }

  get isResubmitTaxnumber(): boolean {
    return ThgCreateModule.isResubmitTaxnumber;
  }

  get isResubmitBankingInformation(): boolean {
    return ThgCreateModule.isResubmitBankingInformation;
  }

  get street(): string {
    return ThgCreateModule.address.street;
  }

  get address(): string {
    return ThgCreateModule.address.zip + " " + ThgCreateModule.address.city;
  }

  get partnerName(): string {
    return ReportModule.partner.companyName;
  }
}
