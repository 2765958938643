






































import { autoCompleteCountryCodes } from "@/lib/CountryCodeHelper";
import { emailRule, extendetPhoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivUpdateUserDtoGen, MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ProfileFormFields, { ProfileFormFieldsDisplayOptions } from "./ProfileFormFields.vue";

/** Same as profile form. Difference is:
 *  Save, edit buttons layout is different
 *  Pass display configuration to show/hide certain sections of the profile
 *  Visually, don't show it as card but integrate the fields into the current flow
 */
@Component({
  components: { ProfileFormFields }
})
export default class ProfileFormEmbedded extends DarkModeHighlightMixin {
  @Prop()
  userToUpdate!: MrfiktivUserViewmodelGen;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop({ default: false })
  showEdit!: boolean;

  @Prop({ default: { isGeneralEnabled: true, isCompanyEnabled: true, isContactEnabled: true, isAddressEnabled: true } })
  displayOptions!: ProfileFormFieldsDisplayOptions;

  @Prop({ default: false })
  isAddressRequired!: boolean;

  @Prop({ default: false })
  isTaxnumberRequired!: boolean;

  @Prop({ default: true })
  isTaxDeductibleEnabled!: boolean;

  @Prop({ default: false })
  hideState!: boolean;

  countryCodes = autoCompleteCountryCodes;

  valid = false;

  user: MrfiktivUpdateUserDtoGen | null = null;

  get isDisabled() {
    return this.disabled;
  }

  set isDisabled(disabled: boolean) {
    this.$emit("update:disabled", disabled);
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }

  mounted() {
    this.initialize();
  }

  initialize() {
    this.user = new UpdateUserDtoFactory().createInitialFromExistingUser(this.userToUpdate, this.isTaxnumberRequired);
  }

  save(): MrfiktivUpdateUserDtoGen {
    if (!this.user) {
      throw new Error("User is not initialized");
    }

    this.$emit("save", this.user);

    return this.user;
  }

  abort() {
    this.initialize();
    this.isDisabled = true;
  }
}
