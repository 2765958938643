
































































import ThgChargingStationMeterReadingFormDialog from "@/components/thg/ThgChargingStationMeterReadingFormDialog.vue";
import ThgVehicleTableLegendDialog from "@/components/thg/ThgVehicleTableLegendDialog.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { PartnerColor } from "@/lib/partnerColor";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { ThgChargingStationViewModelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Debug from "../utility/Debug.vue";
import ThgQuoteExportDialog from "./ThgQuoteExportDialog.vue";

@Component({
  components: {
    Debug,
    ThgQuoteExportDialog,
    LatestEntriesCardEmpty,
    ThgVehicleTableLegendDialog,
    ThgChargingStationMeterReadingFormDialog
  }
})
export default class extends Vue {
  @Prop()
  selection!: ThgChargingStationViewModelGen;

  @Prop()
  meterReadings!: ThgThgMeterReadingViewModelGen[];

  search = "";

  partnerColor = new PartnerColor();
  thgStatusMap = thgStatusMap;

  get formDialog(): Vue & { openDialog: () => void } {
    return this.$refs.meterReadingFormDialog as Vue & { openDialog: () => void };
  }

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  openDialog() {
    this.formDialog.openDialog();
  }

  get meterReadingHeaders() {
    return [
      { text: "", value: null },
      { text: this.$t("components.thg.ThgChargingStationMeterReadingTable.status"), value: "status" },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.created"),
        value: "timestamp.created"
      },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.startDate"),
        value: "meterReading.startDate"
      },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.endDate"),
        value: "meterReading.endDate"
      },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.amountInKwh"),
        value: "meterReading.amountInKwh"
      }
    ];
  }

  addMeterReading() {
    this.$router.push({ path: `/charging-station/${this.selection.id}/meter-reading` });
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }
}
