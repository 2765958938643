var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": _vm.btnConfig.color
    },
    on: {
      "click": _vm.openUpdateDialog
    }
  }, [_vm._v(_vm._s(_vm.btnConfig.text))]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.updateDialog,
      "title": _vm.$t('components.thg.ThgEmissionCalculationDialog.title'),
      "subtitle": _vm.$t('components.thg.ThgEmissionCalculationDialog.subtitle'),
      "rightLoading": _vm.loading,
      "rightDisabled": _vm.formDisabled
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.updateDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.updateDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.updateDialog = false;
      },
      "rightClick": _vm.calculateEmissionSavings
    }
  }, [_c('v-form', {
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, [_c('v-card-text', [_vm._v(_vm._s(_vm.$t("components.thg.ThgEmissionCalculationDialog.decomissionQuestion")))]), _c('v-radio-group', {
    model: {
      value: _vm.isDecommissioned,
      callback: function callback($$v) {
        _vm.isDecommissioned = $$v;
      },
      expression: "isDecommissioned"
    }
  }, [_c('v-radio', {
    staticClass: "ml-3",
    attrs: {
      "label": "Nein",
      "value": false
    }
  }), _c('v-radio', {
    staticClass: "ml-3",
    attrs: {
      "label": "Ja",
      "value": true
    }
  })], 1), _vm.isDecommissioned ? _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "type": "date",
      "label": _vm.$t('components.thg.ThgEmissionCalculationDialog.decomissioningDate'),
      "hint": _vm.$t('components.thg.ThgEmissionCalculationDialog.decomissioningHint'),
      "outlined": "",
      "persistent-hint": "",
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.decommissioningDate,
      callback: function callback($$v) {
        _vm.decommissioningDate = $$v;
      },
      expression: "decommissioningDate"
    }
  }) : _vm._e(), _c('v-card-text', [_vm._v(_vm._s(_vm.$t("components.thg.ThgEmissionCalculationDialog.calculationMethodQuestion")))]), _c('v-radio-group', {
    attrs: {
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.dto.method,
      callback: function callback($$v) {
        _vm.$set(_vm.dto, "method", $$v);
      },
      expression: "dto.method"
    }
  }, [_c('v-radio', {
    staticClass: "ml-3",
    attrs: {
      "label": _vm.$t('yes'),
      "value": _vm.calculationMethodEnum.EXACT
    }
  }), _c('v-radio', {
    staticClass: "ml-3",
    attrs: {
      "label": _vm.$t('no'),
      "value": _vm.calculationMethodEnum.STATISTIC
    }
  })], 1), _vm.dto.method === _vm.calculationMethodEnum.EXACT ? _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "type": "number",
      "step": "1",
      "label": _vm.$t('components.thg.ThgEmissionCalculationDialog.exactEmission'),
      "outlined": "",
      "dense": "",
      "rules": _vm.amountRule
    },
    model: {
      value: _vm.dto.amountInKwh,
      callback: function callback($$v) {
        _vm.$set(_vm.dto, "amountInKwh", $$v);
      },
      expression: "dto.amountInKwh"
    }
  }) : _vm._e(), _c('span', [_vm.dto.method === _vm.calculationMethodEnum.EXACT ? _c('thg-proof') : _vm._e()], 1), _vm.dto.method === _vm.calculationMethodEnum.STATISTIC ? _c('v-card-text', [_vm.isHybridStatus ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgEmissionCalculationDialog.hybridNoChargingDetails")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgEmissionCalculationDialog.bevStatistic")) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgEmissionCalculationDialog.noProof")) + " ")])]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }