var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "md": _vm.md,
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "debug": [_vm.numberplate, _vm.user, _vm.contact, _vm.address],
      "showFooter": false
    },
    on: {
      "next": _vm.next
    }
  }, [_c('v-card', {
    staticClass: "pl-4 pr-4",
    attrs: {
      "flat": "",
      "max-width": "1200"
    }
  }, [!_vm.isAuthenticated ? _c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "ml-n7 mr-n7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("report.thgContactData.numberplate")))]), _c('v-card-text', [_c('numberplate-form', {
    attrs: {
      "valid": _vm.numberplateValid,
      "countryCode": _vm.countryCode
    },
    on: {
      "valid": _vm.setValidNumberplate
    },
    model: {
      value: _vm.numberplate,
      callback: function callback($$v) {
        _vm.numberplate = $$v;
      },
      expression: "numberplate"
    }
  })], 1)], 1), _c('profile-form-fields', {
    attrs: {
      "showUserNameField": false,
      "user": _vm.userInfo,
      "disabled": false,
      "isResubmitAddressEnabled": false,
      "isAddressRequired": true,
      "isResubmitTaxnumberEnabled": false,
      "isTaxnumberRequired": true,
      "isTaxDeductibleEnabled": false,
      "isResubmitAddress": _vm.isResubmitAddress,
      "isResubmitTaxnumber": _vm.isResubmittingTaxnumber,
      "hideState": true,
      "hideCountry": true
    },
    on: {
      "update:isResubmitAddress": function updateIsResubmitAddress($event) {
        _vm.isResubmitAddress = $event;
      },
      "update:is-resubmit-address": function updateIsResubmitAddress($event) {
        _vm.isResubmitAddress = $event;
      },
      "update:isResubmitTaxnumber": function updateIsResubmitTaxnumber($event) {
        _vm.isResubmittingTaxnumber = $event;
      },
      "update:is-resubmit-taxnumber": function updateIsResubmitTaxnumber($event) {
        _vm.isResubmittingTaxnumber = $event;
      }
    }
  }), _vm.showBankingInfo ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("report.thgContactData.bankDetailsTitle")))]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgContactData.bankDetailsDescription")) + " ")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-n6 mb-n8"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isResubmittingBankingData,
      callback: function callback($$v) {
        _vm.isResubmittingBankingData = $$v;
      },
      expression: "isResubmittingBankingData"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.thgContactData.register'),
      "value": false,
      "data-test-form-isNotResubmittingBankingData": ""
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "8"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isResubmittingBankingData,
      callback: function callback($$v) {
        _vm.isResubmittingBankingData = $$v;
      },
      expression: "isResubmittingBankingData"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.thgContactData.resubmit'),
      "value": true,
      "data-test-form-isResubmittingBankingData": ""
    }
  })], 1)], 1)], 1)], 1), _c('v-card-text', [!_vm.isResubmittingBankingData ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.thgContactData.accountHolder'),
      "value": _vm.banking.name,
      "rules": [function (v) {
        return !!v || _this.$t('report.thgContactData.mandatoryInput');
      }],
      "outlined": "",
      "data-test-form-banking-name": ""
    },
    on: {
      "blur": _vm.updateBankingName
    }
  }) : _vm._e(), !_vm.isResubmittingBankingData ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.thgContactData.iban'),
      "value": _vm.banking.iban,
      "rules": _vm.ibanRules,
      "outlined": "",
      "data-test-form-banking-iban": ""
    },
    on: {
      "blur": _vm.updateBankingIban
    }
  }) : _vm._e(), !_vm.isResubmittingBankingData ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.thgContactData.bankingInstitution'),
      "value": _vm.banking.bank,
      "rules": [function (v) {
        return !!v || _vm.$t('report.thgContactData.mandatoryInput');
      }],
      "outlined": "",
      "data-test-form-banking-bank": ""
    },
    on: {
      "blur": _vm.updateBankingBank
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1)]) : _c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.goNextScreen.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('numberplate-form', {
    attrs: {
      "valid": _vm.numberplateValid,
      "countryCode": _vm.countryCode
    },
    on: {
      "valid": _vm.setValidNumberplate
    },
    model: {
      value: _vm.numberplate,
      callback: function callback($$v) {
        _vm.numberplate = $$v;
      },
      expression: "numberplate"
    }
  }), !_vm.authenticatedUserHasAddress || !_vm.authenticatedUserHasTaxNumber ? _c('profile-form-embedded', {
    attrs: {
      "userToUpdate": _vm.authenticatedUser,
      "isAddressRequired": !_vm.authenticatedUserHasAddress,
      "isTaxDeductibleEnabled": false,
      "isTaxnumberRequired": !_vm.authenticatedUserHasTaxNumber,
      "displayOptions": _vm.profileFieldsDisplayOptions,
      "isLoading": _vm.isUpdatingUser,
      "hideState": true
    },
    on: {
      "save": _vm.updateUser
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }