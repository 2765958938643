





































































import { PartnerColor } from "@/lib/partnerColor";
import {
  ThgChargingStationDocumentTypeDtoGen,
  ThgChargingStationProofDocumentDtoGen
} from "@/services/thg/v1/data-contracts";
import { saveAs } from "file-saver";
import { Component, Prop, Vue } from "vue-property-decorator";
import ThgChargingStationHelpDialog from "./ThgChargingStationHelpDialog.vue";
import ThgChargingStationProof from "./ThgChargingStationProof.vue";
import ThgChargingStationProofDocumentTable from "./ThgChargingStationProofDocumentTable.vue";

@Component({
  components: {
    ThgChargingStationProof,
    ThgChargingStationProofDocumentTable,
    ThgChargingStationHelpDialog
  }
})
export default class ThgChargingStationProofChipList extends Vue {
  @Prop()
  files!: ThgChargingStationProofDocumentDtoGen[];

  @Prop()
  refs!: ThgChargingStationDocumentTypeDtoGen[];

  showMenu = false;
  dialog = false;
  /** Files to display in the document table. Add to files only after save. */
  displayFiles: ThgChargingStationProofDocumentDtoGen[] = [];
  partnerColor = new PartnerColor();

  mounted() {
    if (this.files) {
      this.displayFiles.push(...this.files);
    }
  }

  openManageFilesMenu() {
    this.dialog = true;
  }

  onFileUpload(file: ThgChargingStationProofDocumentDtoGen) {
    this.displayFiles.push(file);
  }

  onFileRemove(file: ThgChargingStationProofDocumentDtoGen) {
    const indexOfFile = this.displayFiles.findIndex(uploadedFile => uploadedFile.id === file.id);

    if (indexOfFile !== -1) {
      this.displayFiles.splice(indexOfFile, 1);
    }
  }

  downloadFile(file: ThgChargingStationProofDocumentDtoGen) {
    saveAs(file.url, file.name);
  }

  updateChargingStationWithProofs() {
    this.$emit("proof-update", this.displayFiles);
    this.dialog = false;
  }

  close() {
    this.dialog = false;
  }
}
