import { IUser } from "@/models/user.entity";
import {
  ThgAddressWithGeoGen,
  ThgChargingPointGen,
  ThgChargingPointOperatorGen,
  ThgChargingStationDocumentTypeDtoGen,
  ThgCreateChargingStationDtoGen,
  ThgUpdateChargingStationDtoGen,
  ThgUpdateMyChargingStationShortDtoGen
} from "@/services/thg/v1/data-contracts";
import { CountryCodeEnum } from "./../../../lib/enum/country-code.enum";
import { ChargingStationOperatorUiDto } from "./create-charging-station-opereator";

/**
 * @inheritdoc
 */
export class ChargingStationUiDto implements ThgCreateChargingStationDtoGen {
  /**
   * @inheritdoc
   */
  name: string;

  /**
   * @inheritdoc
   */
  chargingPointOperator: ThgChargingPointOperatorGen;

  /**
   * Indicates if charging station has been registered at BNetz-A
   */
  isRegistered = true;

  /**
   * @inheritdoc
   */
  registrationDate: string;

  /**
   * @inheritdoc
   */
  address: ThgAddressWithGeoGen;

  /**
   * @inheritdoc
   */
  commissioningDate: string;

  /**
   * @inheritdoc
   */
  connectionPowerInKwh: number;

  /**
   * @inheritdoc
   */
  isFromRenewableEnergy: boolean;

  /**
   * @inheritdoc
   */
  renewableEnergySourceDescription: string;

  /**
   * @inheritdoc
   */
  chargingPointCount: number;

  /**
   * @inheritdoc
   */
  chargingPoints: ThgChargingPointGen[];

  /**
   * @inheritdoc
   */
  isPublic?: boolean;

  /**
   * @inheritdoc
   */
  evseId?: string;

  /**
   * @inheritdoc
   */
  meLoId?: string;

  /**
   * @inheritdoc
   */
  maLoId?: string;

  /**
   * the decommissioning date
   */
  decommissioningDate?: string;

  refs?: ThgChargingStationDocumentTypeDtoGen[] = [];

  /**
   *
   */
  constructor(value?: ThgCreateChargingStationDtoGen, user?: IUser, countryCode: string = CountryCodeEnum.germany) {
    this.name = "";
    this.isFromRenewableEnergy = false;
    this.renewableEnergySourceDescription = "";

    this.address = {
      street: "",
      zip: "",
      city: "",
      state: "",
      countryCode: countryCode,
      geo: {
        lat: 0,
        lng: 0
      }
    };
    this.registrationDate = "";
    this.commissioningDate = "";
    this.decommissioningDate = "";
    this.connectionPowerInKwh = 22;
    this.chargingPointCount = 1;
    this.chargingPoints = [];
    this.chargingPointOperator = new ChargingStationOperatorUiDto(undefined, user, countryCode);

    if (value) {
      this.name = value.name;
      this.isFromRenewableEnergy = value.isFromRenewableEnergy;
      this.renewableEnergySourceDescription = value.renewableEnergySourceDescription;
      this.address = value.address;
      this.registrationDate = value.registrationDate;
      this.commissioningDate = value.commissioningDate;
      this.connectionPowerInKwh = value.connectionPowerInKwh;
      this.chargingPointCount = value.chargingPointCount;
      this.chargingPoints = value.chargingPoints || [];
      this.refs = value.refs;

      this.chargingPointOperator = value.chargingPointOperator;
    }
  }

  toCreateMyChargingStation(): ThgCreateChargingStationDtoGen {
    return {
      name: this.name,
      chargingPointCount: this.chargingPointCount,
      chargingPointOperator: this.chargingPointOperator,
      evseId: this.evseId,
      isPublic: this.isPublic,
      meLoId: this.meLoId,
      maLoId: this.maLoId,
      isRegistered: this.isRegistered,
      refs: this.refs,
      address: this.address,
      commissioningDate: this.commissioningDate,
      connectionPowerInKwh: this.connectionPowerInKwh,
      chargingPoints: this.chargingPoints,
      registrationDate: this.registrationDate
    } as ThgCreateChargingStationDtoGen;
  }

  toUpdateChargingStation(): ThgUpdateChargingStationDtoGen {
    return {
      name: this.name,
      chargingPointOperator: this.chargingPointOperator,
      address: this.address,
      isRegistered: this.isRegistered,
      isFromRenewableEnergy: this.isFromRenewableEnergy,
      renewableEnergySourceDescription: this.renewableEnergySourceDescription,
      commissioningDate: this.commissioningDate,
      registrationDate: this.registrationDate,
      connectionPowerInKwh: this.connectionPowerInKwh,
      chargingPointCount: this.chargingPointCount,
      chargingPoints: this.chargingPoints
    } as ThgUpdateChargingStationDtoGen;
  }

  toUpdateMyChargingStationShort(): ThgUpdateMyChargingStationShortDtoGen {
    return {
      name: this.name
    } as ThgUpdateMyChargingStationShortDtoGen;
  }

  getEvseId(powerOutletId: string) {
    if (!this.chargingPointOperator) {
      return "";
    }
    return `${this.address.countryCode}*${this.chargingPointOperator.evseOperatorId}*E${powerOutletId}`;
  }

  get evseIds(): string[] {
    if (!this.chargingPointOperator) {
      return [];
    }

    return this.chargingPoints.map(cp => {
      return `${this.address.countryCode}*${this.chargingPointOperator.evseOperatorId}*E${cp.evseId}`;
    });
  }
}
