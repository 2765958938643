






































































































































































































import SvgMixin from "@/mixins/SvgMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgDescriptionSvgQuote extends SvgMixin {}
