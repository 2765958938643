var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [!_vm.isLoading ? _c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 px-0"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-subtitle', {
    staticClass: "pl-0",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  }), !_vm.isThgs ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "success",
      "large": "",
      "outlined": "",
      "data-test-submit-register": ""
    },
    on: {
      "click": _vm.newCar
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgVehicleOverview.btnTxt")) + " ")])], 1) : _vm._e()], 1)], 1)], 1), _c('notifications'), _c('v-row', [_c('v-col', {
    staticClass: "px-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn-toggle', {
    staticClass: "mx-2",
    model: {
      value: _vm.activeView,
      callback: function callback($$v) {
        _vm.activeView = $$v;
      },
      expression: "activeView"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": _vm.changeToCardView
    }
  }, [_c('v-icon', [_vm._v("mdi-apps")])], 1), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": _vm.changeToListView
    }
  }, [_c('v-icon', [_vm._v("mdi-view-list")])], 1)], 1)], 1)], 1), !_vm.isLoadingAllThgs ? _c('v-row', [_c('v-col', {
    staticClass: "px-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm.showVehicleTable ? _c('thg-vehicle-table', {
    attrs: {
      "myThgs": _vm.myThgs
    },
    on: {
      "refresh": _vm.loadAll
    }
  }) : _c('thg-vehicle-card-iterator', {
    attrs: {
      "myThgs": _vm.myThgs
    },
    on: {
      "refresh": _vm.loadAll
    }
  })], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "offset": "0",
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mb-10",
    attrs: {
      "max-width": "515"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-avatar, article, article, actions"
    }
  })], 1)], 1)], 1), _c('v-btn', {
    attrs: {
      "elevation": "6",
      "color": _vm.color,
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": _vm.newCar
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" mdi-plus ")])], 1), _c('v-subheader', {
    staticClass: "mb-6 mt-6"
  }, [_c('small', {
    domProps: {
      "innerHTML": _vm._s(_vm.note)
    }
  })])], 1) : _vm.isLoading ? _c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "display-1 text--primary"
  }, [_vm._v(_vm._s(_vm.title))])])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": "0",
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mb-10",
    attrs: {
      "max-width": "515"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-avatar, article, article, actions"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }