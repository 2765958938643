import { render, staticRenderFns } from "./ThgPromotionListItem.vue?vue&type=template&id=72bd386c&"
import script from "./ThgPromotionListItem.vue?vue&type=script&lang=ts&"
export * from "./ThgPromotionListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VImg,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow})
