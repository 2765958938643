

















import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import ThgImpactSelectorCard from "./ThgImpactSelectorCard.vue";

@Component({
  components: {
    LayoutReportStep,
    ThgImpactSelectorCard
  }
})
export default class ThgImpactSelector extends ReportStepMixin implements IReportStep {
  public header: IReportHeaderData = {
    title: "report.thgImpactSelector.title",
    description: "report.thgImpactSelector.description"
  };
  screen = ReportScreenEnum.thgimpactselector;

  public instruction: IReportInstructionData = {
    title: "report.thgImpactSelector.instructionTitle",
    text: "report.thgImpactSelector.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  get isDone() {
    return true;
  }

  mounted() {
    this.setCurrentStep();
    ThgCreateModule.setActiveImpactTypeItems(ThgCreateModule.activeImpactTypes);
    if (this.$route.query.campaign) {
      ThgCreateModule.setCampaign((this.$route.query.campaign as string) || "");
    }
  }
}
