/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Type of status of an THG Document.
 */
export enum CurrencyUnitEnum {
  /**
   * currency in cent
   */
  CENT = "CENT",

  /**
   * currency in euro
   */
  EUR = "EUR",

  /**
   * currency in thousand euro
   */
  TEUR = "TEUR"
}
