var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug,
      "hasInstructions": _vm.instruction ? true : false,
      "instructionPictures": _vm.instruction.pictures,
      "instructionText": _vm.instruction.text,
      "instructionTitle": _vm.instruction.title,
      "showFooter": false
    }
  }, [_c('thg-meter-reading-form', {
    attrs: {
      "chargingStationId": _vm.chargingStationId,
      "isSaving": _vm.loading
    },
    on: {
      "save": _vm.save
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }