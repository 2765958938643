import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { ThgCreatePartnerZipDtoGen, ThgZipControllerFindAllParamsGen } from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { Zip } from "../v1/Zip";

/**
 * Communicates with thg backend
 */
class ZipService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Zip;

  /**
   * @class Initialize ZipService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Zip(this.client);
  }

  async checkZip(partnerId: string, zip: string) {
    return (await this.proxy.zipControllerCheckZipForPartner(partnerId, zip)).data;
  }

  async createOrUpdateForPartner(partnerId: string, data: ThgCreatePartnerZipDtoGen) {
    return (await this.proxy.zipControllerCreateOrUpdateForPartner(partnerId, data)).data;
  }

  async findAll(query: ThgZipControllerFindAllParamsGen) {
    return (await this.proxy.zipControllerFindAll(query)).data;
  }

  async getForPartner(partnerId: string) {
    return (await this.proxy.zipControllerGetForPartner(partnerId)).data;
  }
}

export default new ZipService(new ThgHttpClientProvider());
