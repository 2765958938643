
















import ThgPriceCard from "@/components/thg/ThgPriceCard.vue";
import { Component, Vue } from "vue-property-decorator";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

@Component({
  components: {
    ThgPriceCard
  }
})
export default class ThgPriceOverview extends Vue {
  get items() {
    return [
      {
        title: "components.thg.ThgPriceOverview.car.title",
        subTitle: "components.thg.ThgPriceOverview.car.subTitle",
        src: "https://static.mmmint.ai/wirkaufendeinethg/electric_car.png",
        price: "350€"
      },
      {
        title: "components.thg.ThgPriceOverview.scooter.title",
        subTitle: "components.thg.ThgPriceOverview.scooter.subTitle",
        src: "https://static.mmmint.ai/wirkaufendeinethg/electric_scooter.png",
        price: "350€"
      },
      {
        title: "components.thg.ThgPriceOverview.truck.title",
        subTitle: "components.thg.ThgPriceOverview.truck.subTitle",
        src: "https://www.wirkaufendeinethg.de/images/options/electric_truck.png",
        price: "530€"
      },
      {
        title: "components.thg.ThgPriceOverview.bus.title",
        subTitle: "components.thg.ThgPriceOverview.bus.subTitle",
        src: "https://www.wirkaufendeinethg.de/images/options/electric_bus.png",
        price: "12.650€"
      }
    ];
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }
}
