var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug,
      "showFooter": false,
      "hasInstructions": true,
      "instructionPictures": _vm.instruction.pictures,
      "instructionText": _vm.instruction.text,
      "instructionTitle": _vm.instruction.title
    },
    on: {
      "next": _vm.next
    }
  }, [_vm.isLoading ? _c('v-sheet', {
    staticClass: "mb-2 py-n6 mt-2",
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "article"
    }
  })], 1) : _c('div', [_vm.activePromotion && _vm.activePromotion.isApplicable ? _c('div', [_c('v-radio-group', {
    model: {
      value: _vm.promotion,
      callback: function callback($$v) {
        _vm.promotion = $$v;
      },
      expression: "promotion"
    }
  }, [_c('v-sheet', {
    staticClass: "mb-2 py-n6",
    attrs: {
      "outlined": "",
      "rounded": "",
      "color": "primary"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('thg-promotion-list-item', {
    attrs: {
      "value": _vm.activePromotion
    }
  })], 1)], 1)], 1), _c('v-list-item', {
    staticClass: "mt-n2"
  }, [_c('v-spacer'), _c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-plus")]), _c('v-spacer')], 1)], 1) : _vm._e(), _c('v-radio-group', {
    model: {
      value: _vm.vehicleClass,
      callback: function callback($$v) {
        _vm.vehicleClass = $$v;
      },
      expression: "vehicleClass"
    }
  }, [_vm._l(_vm.availableVehicleClasses, function (products, i) {
    return _c('v-sheet', {
      key: i,
      staticClass: "mb-2 py-n6",
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": _vm.activeCardStyle(products.vehicleType)
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      },
      on: {
        "click": function click($event) {
          _vm.vehicleClass = products.vehicleType;
        }
      }
    }, [_c('v-list', {
      attrs: {
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-avatar', {
      attrs: {
        "tile": "",
        "size": "60"
      }
    }, [products.src ? _c('v-img', {
      staticStyle: {
        "border-radius": "4px"
      },
      attrs: {
        "src": products.src
      }
    }) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(products.title)) + " ")]), _c('v-list-item-subtitle', [products.hint ? _c('span', [_vm._v(_vm._s(_vm.$t(products.hint) + " "))]) : _vm._e(), _vm._v(_vm._s(_vm.$t(products.classSpeaking)) + " ")]), _vm._l(_vm.selectedYears, function (year) {
      return _c('v-list-item-subtitle', {
        key: year
      }, [_vm._v(" " + _vm._s(year + ": " + _vm.$t(_vm.getPrice(year, products.vehicleType).prefix) + " " + _vm.getPrice(year, products.vehicleType).totalAmountAsString) + " ")]);
    })], 2), _c('v-list-item-action', [_c('v-radio', {
      attrs: {
        "value": products.vehicleType
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), _vm.vehicleClassPreview && _vm.showPreview ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.vehicleClassPreview = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("report.thgVehicleClass.loadAll")))])], 1) : _vm._e(), _vm.isCharging ? _c('div', [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.thgVehicleClass.charging.header")))]), _c('v-sheet', {
    staticClass: "mb-2 py-n6",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": _vm.goToChargingStationOverview
    }
  }, [_c('v-list', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "60"
    }
  }, [_c('v-img', {
    staticStyle: {
      "border-radius": "4px"
    },
    attrs: {
      "src": "https://static.mmmint.ai/public-thg/2023-09-14-logo-573e2ebe2e509db4908a6f33.jpg"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("report.thgVehicleClass.charging.title")) + " ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.$t("report.thgVehicleClass.charging.subtitle")) + " ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.$t("report.thgVehicleClass.charging.hint")) + " ")])], 1), _c('v-list-item-action', [_c('v-radio', {
    on: {
      "click": _vm.goToChargingStationOverview
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }