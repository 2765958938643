
import { Component, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({})
export default class ThgRegistrationScreensMixin extends Vue {
  /**
   * Screens for uploading images.
   * @example `thgregistration`
   */
  registrationScreens = [ReportScreenEnum.thgregistration, ReportScreenEnum.thgregistrationat];
}
