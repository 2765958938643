

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport }
})
export default class ThgPrivacy extends Vue {
  get partner(): PartnerEntity {
    return ReportModule?.partner;
  }

  get isDatasharingActive(): boolean {
    return !!this.partner?.settings?.isDatasharingActive;
  }

  get companyName(): string {
    return this.partner?.companyName;
  }

  get street(): string {
    return this.partner?.address?.street || "";
  }

  get city(): string {
    return this.partner?.address?.city || "";
  }

  get zip(): string {
    return this.partner?.address?.zip || "";
  }
}
