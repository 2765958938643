
























import { Component, Vue, Prop } from "vue-property-decorator";
import { IThgImpact } from "@/views/thg/interface/ThgImpact.interface";

@Component
export default class BadConscienceDialog extends Vue {
  @Prop()
  impactTypeItem!: IThgImpact;

  dialog = false;

  openDialog() {
    this.dialog = true;
  }

  closeDialog() {
    this.dialog = false;
  }
}
