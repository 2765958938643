
















































import BadConscienceDialog from "@/components/thg/BadConscienceDialog.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Ref } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ThgYearSelector extends ReportStepMixin implements IReportStep {
  @Ref("badConscienceDialog")
  readonly badConscienceDialog!: BadConscienceDialog;

  screen = ReportScreenEnum.thgYearSelection;

  public header: IReportHeaderData = {
    title: "report.thgYearSelector.title",
    description: "report.thgYearSelector.description"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgYearSelector.instructionTitle",
    text: "report.thgYearSelector.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  get bonus() {
    return ThgCreateModule.bonus;
  }

  get prefix() {
    return ThgCreateModule.bonusPrefix;
  }

  get years() {
    return ThgCreateModule.years;
  }

  get selected() {
    return ThgCreateModule.selectedYearId;
  }

  set selected(id) {
    ThgCreateModule.setSelectedYearId(id);
  }

  get company() {
    return ReportModule.partner.companyName;
  }

  get items() {
    return ThgCreateModule.yearSelections;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return true;
  }

  /**
   *
   * @param item
   */
  setYear(item: any) {
    ThgCreateModule.setYears(item.value);
  }

  mounted() {
    this.setCurrentStep();
    if (ThgCreateModule.years.length === 0) {
      ThgCreateModule.setYears(this.items[0].value);
    }
  }
}
