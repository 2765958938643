var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "md": _vm.md,
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "showFooter": false
    }
  }, [_c('v-card', {
    staticClass: "pl-4 pr-4",
    attrs: {
      "flat": "",
      "max-width": "1200"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "id": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.checkZip.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isValidForm,
      callback: function callback($$v) {
        _vm.isValidForm = $$v;
      },
      expression: "isValidForm"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": _vm.$t('report.thgzip.label'),
      "rules": _vm.rules,
      "maxlength": "5",
      "required": "",
      "outlined": "",
      "append-icon": _vm.icon,
      "data-test-form-zip": "",
      "loading": _vm.isValidationLoading
    },
    on: {
      "input": _vm.checkZip
    },
    model: {
      value: _vm.zip,
      callback: function callback($$v) {
        _vm.zip = $$v;
      },
      expression: "zip"
    }
  })], 1), !_vm.zip || _vm.zip.length < 5 || _vm.isValidationLoading || _vm.containsAlpha ? _c('v-card-text', {
    staticClass: "mx-n4"
  }) : !_vm.isValidForm ? _c('div', [_c('v-card-text', {
    staticClass: "mx-n4"
  }, [_vm._v(_vm._s(_vm.$t("report.thgzip.isNotAllowed")))]), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "success"
    },
    on: {
      "click": _vm.switchToWkthg
    }
  }, [_vm._v(_vm._s(_vm.$t("report.thgzip.altAction")))])], 1) : _c('v-card-text', {
    staticClass: "mx-n4"
  }, [_vm._v(_vm._s(_vm.$t("report.thgzip.isAllowed", {
    cityName: _vm.cityName
  })))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }