
























import ThgChargingStationCard from "@/components/thg/ThgChargingStationCard.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { ThgChargingStationViewModelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ThgChargingStationCard
  }
})
export default class ThgChargingStationCardIterator extends Vue {
  search = "";
  partnerColor = new PartnerColor();

  get myChargingStation() {
    return ChargingStationModule.charingStations;
  }

  meterReadingsByChargingStation(chargingStation: ThgChargingStationViewModelGen): ThgThgMeterReadingViewModelGen[] {
    const mr = MeterReadingModule.meterReadingsPerChargingStationLookup.get(chargingStation.id);
    if (!mr) {
      return [];
    }
    return mr;
  }
}
