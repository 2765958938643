var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "proof-container"
  }, [_c('v-card-text', [_vm._v(" Bitte Nachweis/Nachweise hochladen (PDF oder JPEG/PNG, maximal 10 MB je Datei). ")]), _c('file-input', {
    attrs: {
      "show-size": "",
      "accept": "application/pdf, .pdf, image/jpeg, image/png",
      "label": "Dateien einzeln auswählen oder hier ablegen",
      "rules": _vm.rules,
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading,
      "readonly": _vm.disabled,
      "maxFileSizeInMb": 10,
      "onlyPdf": false,
      "multiple": true,
      "outlined": true
    },
    on: {
      "change": _vm.onChange
    }
  }), _vm._l(_vm.fileProofs, function (proof, idx) {
    return _c('v-chip', {
      key: idx,
      staticClass: "mr-2 mb-2",
      on: {
        "click": function click($event) {
          return _vm.downloadFile(proof);
        }
      }
    }, [_vm._v(_vm._s(proof.originalName))]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }