


































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { thgQueryParams } from "@/lib/queryParams/thgQueryParams";
import { germanZipRule } from "@/lib/rules/contactRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ZipModule } from "@/store/modules/zip.store";
import { Component, Vue } from "vue-property-decorator";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ThgZip extends ReportStepMixin implements IReportStep {
  md = 4;
  header: IReportHeaderData = {
    title: "report.thgzip.title",
    description: "report.thgzip.description"
  };

  isValidationLoading = false;
  isValidZip = false;
  isValidForm = false;

  screen = ReportScreenEnum.thgzip;

  get cityName() {
    return ZipModule.checkZipResult.zip.city;
  }

  get zip() {
    return ThgCreateModule.address.zip;
  }

  set zip(zip: string) {
    ThgCreateModule.setAddress({ zip: zip });
  }

  get containsAlpha() {
    return !/^\d+$/.test(this.zip);
  }

  get rules() {
    return [requiredRule(), isNumberRule(), germanZipRule(), () => this.isValidZip || ""];
  }

  get icon(): string {
    if (this.isValidationLoading) {
      return "mdi-spin mdi-refresh";
    }
    if (this.isValidForm) {
      return "mdi-check-circle-outline";
    }
    return "mdi-close-circle-outline";
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get isDone() {
    return this.isValidForm;
  }

  get partnerId() {
    return ReportModule.partner.id || ReportModule.partner._id;
  }

  /**
   *
   */
  switchToWkthg() {
    const queryParams: string[] = [];
    thgQueryParams.forEach(q => {
      const value = q.get();
      if (value) {
        if (q.key != "headless") {
          queryParams.push(`${q.key}=${q.get()}`);
        }
      }
    });
    const queryParamString = queryParams.join("&");

    window.open(`https://app.wirkaufendeinethg.de/#/?code=${this.partnerId}&${queryParamString}`, "_blank");
  }

  /**
   * Validate zip if it is already set e.g. from store
   */
  async mounted() {
    this.setCurrentStep();
    if (this.zip) {
      await this.checkZip();
    }
  }

  /**
   * Validate zip
   */
  async checkZip() {
    try {
      if (!this.zip || this.zip.length !== 5) {
        this.isValidZip = false;
        return;
      }
      this.isValidationLoading = true;
      const res = await ZipModule.checkZip({
        partnerId: this.partnerId,
        zip: this.zip
      });

      this.isValidZip = res.isServiceAvailable;
    } catch (e) {
      this.$log.error(e);
      this.$toast((e as any).message);
    } finally {
      this.isValidationLoading = false;
      this.form.validate();
    }
  }
}
