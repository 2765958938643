



























































































































































































































































import { IBankingDto } from "@/store/interface/thg/banking.interface";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop } from "vue-property-decorator";
import { ReportModule } from "@/store/modules/report.store";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { PartnerColor } from "@/lib/partnerColor";
import ImageDialog from "@/components/utility/ImageDialog.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import ThgContactScreensMixin from "./mixins/ThgContactScreensMixin.vue";
import { mixins } from "vue-class-component";
import ThgRegistrationScreensMixin from "./mixins/ThgRegistrationScreensMixin.vue";
import ThgImpactSelectorScreensMixin from "./mixins/ThgImpactSelectorScreensMixin.vue";

@Component({
  components: {
    ImageDialog
  }
})
export default class ThgCreateOverviewCard extends mixins(
  ThgContactScreensMixin,
  ThgRegistrationScreensMixin,
  ThgImpactSelectorScreensMixin
) {
  @Prop({ default: false })
  hideTitle?: boolean;

  @Prop({ default: false })
  hideEdit?: boolean;

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  partnerColor = new PartnerColor();

  get numberplate(): string {
    return ThgCreateModule.numberplate;
  }

  get banking(): IBankingDto {
    return ThgCreateModule.banking;
  }

  get name(): string {
    return ThgCreateModule.user.firstName + " " + ThgCreateModule.user.lastName;
  }

  get email(): string {
    return ThgCreateModule.contact.email;
  }

  get years(): number[] {
    return ThgCreateModule.years;
  }

  get isCompany(): boolean {
    return ThgCreateModule.isCompany;
  }

  get company(): string {
    return ThgCreateModule.company;
  }

  get taxNumber(): string {
    return ThgCreateModule.taxnumber;
  }

  get showYears(): number {
    return ThgCreateModule.years.length;
  }

  get isShowingPriceInformationForNextYear() {
    return ThgCreateModule.years.includes(2024);
  }

  get calenderYearsText(): string {
    if (this.showYears) {
      return "je Kalenderjahr";
    }
    return "";
  }

  get payout(): string {
    return 100 - ThgCreateModule.impactFactor + " %";
  }

  get showCustomerAccount(): boolean {
    return ThgCreateModule.impactType === ImpactTypeEnum.customerAccount;
  }

  get showImpact(): number {
    if (ThgCreateModule.impactType === ImpactTypeEnum.customerAccount) {
      return 0;
    }
    return ThgCreateModule.impactFactor;
  }

  showValueOrDefault(value: string, title: string) {
    if (!value.replace(" ", "")) {
      return this.$t("components.thg.ThgCreateOverviewCard.defaultValue", { title: this.$t(title) });
    }

    return value;
  }

  goToImpactSelector() {
    const screenName = this.impactSelectorScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.impactSelector"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.impactSelector"));
    }
  }

  goToContactData() {
    const screenName = this.contactScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.thgcontact"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.thgcontact"));
    }
  }

  goToRegistration() {
    const screenName = this.registrationScreens.find(screen => ReportModule.screenOder.includes(screen));

    if (screenName) {
      this.goToScreen(screenName);
    } else {
      this.$toast.error(this.$t("components.thg.ThgCreateOverviewCard.errors.registration"));
      this.$log.error(this.$t("components.thg.ThgCreateOverviewCard.errors.registration"));
    }
  }

  goToYearSelection() {
    this.goToScreen(ReportScreenEnum.thgYearSelection);
  }

  goToScreen(screenName: ReportScreenEnum) {
    ReportModule.setStep(screenName);
    this.$router.push({ name: screenName });
  }

  get impact(): string {
    return ThgCreateModule.impactFactor + " %";
  }

  get impactType(): string {
    return ThgCreateModule.impactTypeItem.title;
  }

  get phone(): string {
    return ThgCreateModule.contact.phone;
  }

  get isResubmitAddress(): boolean {
    return ThgCreateModule.isResubmitAddress;
  }

  get isResubmitTaxnumber(): boolean {
    return ThgCreateModule.isResubmitTaxnumber;
  }

  get isResubmitBankingInformation(): boolean {
    return ThgCreateModule.isResubmitBankingInformation;
  }

  get street(): string {
    return ThgCreateModule.address.street;
  }

  get address(): string {
    return ThgCreateModule.address.zip + " " + ThgCreateModule.address.city;
  }

  get isRegistrationFront(): number {
    return ThgCreateModule.registrationImageFront.length;
  }

  get isRegistrationBack(): number {
    return ThgCreateModule.registrationImageBack.length;
  }

  fileToUrl(imageUploaded: IImageUploaded) {
    if (!imageUploaded || !imageUploaded.file) {
      return "";
    }

    let url = "";
    try {
      url = URL.createObjectURL(imageUploaded.file);
    } catch (error) {
      this.$log.error(error);
    }

    return url;
  }

  get getRegistrationImageFront(): string {
    if (ThgCreateModule.registrationImageFront.length === 0) {
      return "";
    }
    return this.fileToUrl(ThgCreateModule.registrationImageFront[0]);
  }

  get getRegistrationImageBack(): string {
    if (ThgCreateModule.registrationImageBack.length === 0) {
      return "";
    }
    return this.fileToUrl(ThgCreateModule.registrationImageBack[0]);
  }
}
