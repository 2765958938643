
























































import CreateOrUpdateBankDialog from "@/components/profile/CreateOrUpdateBankDialog.vue";
import EditProfileDialog from "@/components/profile/EditProfileDialog.vue";
import { AffiliateTypeEnum } from "@/lib/enum/AffiliateType.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ThgShortAffiliateViewmodelGen } from "@/services/thg/v1/data-contracts";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { CreateOrUpdateBankDialog, EditProfileDialog }
})
export default class ThgInviteFriendsCard extends Vue {
  @Prop()
  myAffiliate!: ThgShortAffiliateViewmodelGen;

  get isRenderingThreeLines() {
    return this.$vuetify.breakpoint.name === "xs";
  }

  get items() {
    if (this.myAffiliate.type === AffiliateTypeEnum.FREINDS) {
      return this.itemsFriends;
    }
    return this.itemsAlt;
  }

  get itemsFriends() {
    return [
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("components.thg.ThgInviteFriendsCard.explainationTitle1"),
        subtitle: this.$t("components.thg.ThgInviteFriendsCard.explainationText1")
      },
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("components.thg.ThgInviteFriendsCard.explainationTitle2"),
        subtitle: this.$t("components.thg.ThgInviteFriendsCard.explainationText2")
      },
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("components.thg.ThgInviteFriendsCard.explainationTitle3"),
        subtitle: this.$t("components.thg.ThgInviteFriendsCard.explainationText3")
      }
    ];
  }

  get itemsAlt() {
    return [
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("components.thg.ThgInviteFriendsCard.explainationTitleAlt1"),
        subtitle: this.$t("components.thg.ThgInviteFriendsCard.explainationTextAlt1")
      },
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("components.thg.ThgInviteFriendsCard.explainationTitleAlt2"),
        subtitle: this.$t("components.thg.ThgInviteFriendsCard.explainationTextAlt2")
      },
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("components.thg.ThgInviteFriendsCard.explainationTitleAlt3"),
        subtitle: this.$t("components.thg.ThgInviteFriendsCard.explainationTextAlt3")
      }
    ];
  }

  get inviteText() {
    return this.$t("components.thg.ThgInviteFriendsCard.inviteText", {
      prefix: ThgCreateModule.bonusPrefix,
      bonus: ThgCreateModule.bonus
    });
  }

  createAffiliateLink(code: string) {
    return this.inviteText + "https://" + ReportModule.partner.settings?.defaultUrl + "/#/?code=" + code;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }

  copyLinkToClipboard(code: string) {
    navigator.clipboard.writeText(this.createAffiliateLink(code));
    this.$toast.info("In Zwischenablage kopiert.");
  }
}
