
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IFullOptions, json2csvAsync } from "json-2-csv";
import { Component } from "vue-property-decorator";

@Component({})
export default class CsvExportMixin extends DarkModeHighlightMixin {
  isLoading = false;

  get loading() {
    return this.isLoading;
  }

  async download(data: any, options: IFullOptions) {
    this.isLoading = true;
    const csvString = await this.parseJsonToCsv(data, options);
    this.create_csv_file(csvString);
    this.isLoading = false;
  }

  async parseJsonToCsv(data: any, options: IFullOptions): Promise<string> {
    let csv = "";
    try {
      csv = await json2csvAsync(data, options);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim konvertieren.");
    }
    return csv;
  }

  create_csv_file(csvString: string) {
    const blob = new Blob([csvString], { type: "csv" });
    const url = window.URL.createObjectURL(blob);
    const dateString = new Date().toISOString();
    this.downloadURI(url, `thg_export_${dateString}.csv`);
  }

  downloadURI(uri: string, name: string) {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
