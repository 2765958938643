var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [!_vm.isLoading ? _c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 px-0"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-subtitle', {
    staticClass: "pl-0",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })], 1)], 1)], 1), _c('notifications'), _c('v-row', [_c('v-col', {
    staticClass: "px-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-charging-station-table')], 1), _c('v-col', {
    staticClass: "pb-12"
  }, [_c('debug', {
    attrs: {
      "debug": _vm.ChargingStations
    }
  })], 1), _c('v-col', [_c('debug', {
    attrs: {
      "debug": _vm.MeterReadings
    }
  })], 1)], 1)], 1) : _c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "display-1 text--primary"
  }, [_vm._v(_vm._s(_vm.$t("report.thgChargingStationOverview.title")))]), _c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": "0",
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mb-10"
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-heading, table-tbody, table-tfoot"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }