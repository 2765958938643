var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug,
      "showFooter": false,
      "hasInstructions": true,
      "instructionPictures": _vm.instruction.pictures,
      "instructionText": _vm.instruction.text,
      "instructionTitle": _vm.instruction.title
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "800",
      "flat": ""
    }
  }, [_c('v-container', [_c('v-item-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_c('v-row', _vm._l(_vm.items, function (item, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-item', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var active = _ref.active,
              toggle = _ref.toggle;
          return [_c('v-card', {
            staticClass: "mx-auto",
            attrs: {
              "max-width": "500",
              "elevation": active ? 4 : 2
            },
            on: {
              "click": toggle
            }
          }, [_c('v-img', {
            staticClass: "white--text",
            style: active ? "border: 4px solid ".concat(_vm.color, ";") : 'filter: grayscale(100%);',
            attrs: {
              "height": active ? "190" : '85',
              "src": item.src,
              "gradient": "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)"
            },
            on: {
              "click": function click($event) {
                return _vm.setYear(item);
              }
            }
          }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t(item.title, {
            bonusPrefix: _vm.prefix,
            bonus: _vm.bonus
          })))]), active ? _c('v-card-subtitle', {
            staticClass: "pb-0"
          }, [_vm._v(" " + _vm._s(_vm.$t(item.text, {
            bonusPrefix: _vm.prefix,
            bonus: _vm.bonus
          })) + " ")]) : _vm._e(), active ? _c('v-card-actions', [_c('v-btn', {
            attrs: {
              "icon": "",
              "dark": ""
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "white"
            }
          }, [_vm._v("mdi-heart")])], 1)], 1) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }