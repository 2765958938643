


















import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ThgImpactFactorSlider extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: ImpactTypeEnum.trees })
  impactType!: ImpactTypeEnum;

  get sliderValue(): number {
    return ThgCreateModule.impactFactor;
  }

  set sliderValue(value: number) {
    ThgCreateModule.setImpactFactor(value);
  }

  get icon(): string {
    return ThgCreateModule.impactTypeItem.icon;
  }

  updateImpactFactor() {
    ThgCreateModule.setImpactFactor(this.sliderValue);
  }

  satisfactionEmojis = ["😢", "🙂", "😊", "😀", "😄", "😁", "😎", "🤩", "😍", "🚀", "💚"];
}
