var render = function () {
  var _vm$thg;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": _vm.isLoading ? 'Loading...' : (_vm$thg = _vm.thg) === null || _vm$thg === void 0 ? void 0 : _vm$thg.numberplate
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [!_vm.isLoading && _vm.thg ? _c('thg-vehicle-card', {
    attrs: {
      "myThg": _vm.thg
    }
  }) : _c('v-card', {
    staticClass: "mb-10",
    attrs: {
      "max-width": "515"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-avatar, article, article, actions"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }