import {
  ThgCreateMeterReadingDtoGen,
  ThgCreateThgMeterReadingAdminDtoGen,
  ThgCreateThgMeterReadingDtoGen
} from "@/services/thg/v1/data-contracts";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";

/**
 * @inheritdoc
 */
export class CreateMeterReading implements ThgCreateThgMeterReadingDtoGen {
  /**
   * @inheritdoc
   */
  impactType: "trees" | "renewable" | "charging" | "customerAccount" | "payout" | "retire" = ImpactTypeEnum.payout;

  /**
   * @inheritdoc
   */
  impactFactor = 100;

  /**
   * @inheritdoc
   */
  isConfirmed?: boolean;

  /**
   * @inheritdoc
   */
  code?: string;

  /**
   * @inheritdoc
   */
  campaign?: string;

  /**
   * @inheritdoc
   */
  meterReading: ThgCreateMeterReadingDtoGen;

  /**
   * Creates an instance
   */
  constructor(value?: ThgCreateThgMeterReadingDtoGen) {
    this.meterReading = {
      amountInKwh: 0,
      chargingStationId: "",
      endDate: "",
      startDate: "",
      proof: [""]
    };

    if (value) {
      this.impactType = value.impactType;
      this.impactFactor = value.impactFactor;
      this.isConfirmed = value.isConfirmed;
      if (value.meterReading) {
        this.meterReading = value.meterReading;
      }
    }
  }

  static new() {
    return new CreateMeterReading();
  }
}

/**
 * @inheritdoc
 */
export class CreateMeterReadingAdmin extends CreateMeterReading implements ThgCreateThgMeterReadingAdminDtoGen {
  /**
   * @inheritdoc
   */
  userId: string;

  constructor(value?: ThgCreateThgMeterReadingAdminDtoGen) {
    super(value);
    this.userId = value?.userId || "";
  }
}
