/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgBasePdfViewmodelGen,
  ThgBillingControllerGetAllBillingsParamsGen,
  ThgBillingControllerGetPartnerParamsGen,
  ThgBillingListDtoGen,
  ThgBillingPartnerViewModelGen,
  ThgBillingPdfDtoGen,
  ThgBillingPdfInputGen,
  ThgBillingViewmodelGen,
  ThgCreateBillingThgDtoGen,
  ThgCreditorListDtoGen,
  ThgExceptionViewmodelGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgPublishBillingDtoGen,
  ThgPublishBillingsDtoGen,
  ThgUpdateAccountingRecordsDtoGen,
  ThgUrlViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Billing<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerCreateBilling
   * @summary (ADMIN) Creates a billing
   * @request POST:/billing
   * @secure
   * @response `201` `ThgBillingViewmodelGen` Created billing
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerCreateBilling = (data: ThgCreateBillingThgDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgBillingViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerGetAllBillings
   * @summary (ADMIN) Gets all billings
   * @request GET:/billing
   * @secure
   * @response `200` `((ThgPageViewModelGen & { data?: (ThgBillingViewmodelGen)[] }) | (ThgBillingViewmodelGen)[])`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerGetAllBillings = (query: ThgBillingControllerGetAllBillingsParamsGen, params: RequestParams = {}) =>
    this.http.request<
      (ThgPageViewModelGen & { data?: ThgBillingViewmodelGen[] }) | ThgBillingViewmodelGen[],
      ThgExceptionViewmodelGen
    >({
      path: `/billing`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerCreateCreditorsAsync
   * @summary (ADMIN) Creates operation to create a csv for creditors
   * @request POST:/creditor/csv
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerCreateCreditorsAsync = (data: ThgCreditorListDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/creditor/csv`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerCreateCreditorBillingAsync
   * @summary (ADMIN) Creates operation to create a csv for creditors from a list of billingNumbers
   * @request POST:/creditor/csv/billing
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerCreateCreditorBillingAsync = (data: ThgBillingListDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/creditor/csv/billing`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerCreateBillingsAsync
   * @summary (ADMIN) Creates operation to handle a batch of billings
   * @request POST:/billing/csv
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerCreateBillingsAsync = (data: ThgBillingListDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/billing/csv`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerDispatchCreditAutoBilling
   * @summary (ADMIN) Creates operation to bill thgs  that are ready for credit billing asynchronously in an operation
   * @request POST:/billing/credit/async
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerDispatchCreditAutoBilling = (params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/billing/credit/async`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerValidityCheck
   * @summary (ADMIN) Check if all billing numbers are there and that there is no gap
   * @request POST:/billing/check
   * @secure
   * @response `201` `ThgOperationIdViewModelGen` ID of operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerValidityCheck = (params: RequestParams = {}) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/billing/check`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerAccessCsvFromOperation
   * @summary (ADMIN) Returns a csv file for a create csv operation
   * @request GET:/billing/csv/{operationId}
   * @secure
   * @response `200` `ThgUrlViewmodelGen`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerAccessCsvFromOperation = (operationId: string, params: RequestParams = {}) =>
    this.http.request<ThgUrlViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing/csv/${operationId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerGetMe
   * @summary (billed USER) Gets published billings of user
   * @request GET:/billing/me
   * @secure
   * @response `200` `(ThgBillingViewmodelGen)[]` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerGetMe = (params: RequestParams = {}) =>
    this.http.request<ThgBillingViewmodelGen[], ThgExceptionViewmodelGen>({
      path: `/billing/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerGetPartner
   * @summary (PARTNER) Gets published (CREDIT_PARTNER, CREDIT_CUSTOMER_ACCOUNT) billings of partner
   * @request GET:/partner/{partnerId}/billing
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgBillingPartnerViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerGetPartner = (
    { partnerId, ...query }: ThgBillingControllerGetPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgBillingPartnerViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/billing`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerGetOneByBillingNumberForPartner
   * @summary (PARTNER) Gets billing with partner and billing number
   * @request GET:/partner/{partnerId}/billing/{billingNumber}
   * @secure
   * @response `200` `ThgBillingPartnerViewModelGen` Gets billing for partner and billing number
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerGetOneByBillingNumberForPartner = (
    partnerId: string,
    billingNumber: number,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBillingPartnerViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/billing/${billingNumber}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerGetBillingByBillingNumber
   * @summary (ADMIN) Gets a billing by billing number
   * @request GET:/billing/{billingNumber}
   * @secure
   * @response `200` `ThgBillingViewmodelGen` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerGetBillingByBillingNumber = (billingNumber: number, params: RequestParams = {}) =>
    this.http.request<ThgBillingViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerDeleteBilling
   * @summary (ADMIN) Delete a billing
   * @request DELETE:/billing/{billingNumber}
   * @secure
   * @response `200` `void`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerDeleteBilling = (billingNumber: number, params: RequestParams = {}) =>
    this.http.request<void, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}`,
      method: "DELETE",
      secure: true,
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerGetBillingPdfAccess
   * @summary (ADMIN) Gets access link to pdf of billing
   * @request GET:/billing/{billingNumber}/pdf
   * @secure
   * @response `200` `ThgUrlViewmodelGen` Id of created operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerGetBillingPdfAccess = (billingNumber: number, params: RequestParams = {}) =>
    this.http.request<ThgUrlViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/pdf`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerAddPdfs
   * @summary (ADMIN) Add a pdf to a billing
   * @request PATCH:/billing/{billingNumber}/pdf
   * @secure
   * @response `200` `ThgBasePdfViewmodelGen` The record has been successfully added
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerAddPdfs = (billingNumber: number, data: ThgBillingPdfDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgBasePdfViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/pdf`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerReplacePdfs
   * @summary (ADMIN) Replace a pdf in a billing
   * @request PUT:/billing/{billingNumber}/pdf
   * @secure
   * @response `200` `ThgBasePdfViewmodelGen` The record has been successfully created
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerReplacePdfs = (billingNumber: number, data: ThgBillingPdfDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgBasePdfViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/pdf`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerPublishBilling
   * @summary (ADMIN) Publish a billing
   * @request PUT:/billing/{billingNumber}/publish
   * @secure
   * @response `200` `void`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerPublishBilling = (
    billingNumber: number,
    data: ThgPublishBillingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<void, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/publish`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerCreateBillingManually
   * @summary (ADMIN) Creates a billing manually
   * @request PATCH:/billing/{billingNumber}/pdf/manual
   * @secure
   * @response `201` `ThgBillingViewmodelGen` the updated billing document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerCreateBillingManually = (
    billingNumber: number,
    data: ThgBillingPdfInputGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBillingViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/pdf/manual`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerAddAccountingRecords
   * @summary (ADMIN) Add an accounting records to a billing
   * @request PATCH:/billing/{billingNumber}/record
   * @secure
   * @response `200` `void`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerAddAccountingRecords = (
    billingNumber: number,
    data: ThgUpdateAccountingRecordsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<void, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/record`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerReplaceAccountingRecords
   * @summary (ADMIN) Replaces the accounting records of a billing
   * @request PUT:/billing/{billingNumber}/record
   * @secure
   * @response `200` `void`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerReplaceAccountingRecords = (
    billingNumber: number,
    data: ThgUpdateAccountingRecordsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<void, ThgExceptionViewmodelGen>({
      path: `/billing/${billingNumber}/record`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags billing
   * @name BillingControllerPublishBillingBatch
   * @summary (ADMIN) Publish a batch of billings
   * @request PUT:/billing/publish
   * @secure
   * @response `200` `void`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingControllerPublishBillingBatch = (data: ThgPublishBillingsDtoGen, params: RequestParams = {}) =>
    this.http.request<void, ThgExceptionViewmodelGen>({
      path: `/billing/publish`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
}
