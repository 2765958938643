import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import meterReadingService from "@/services/thg/services/meterReadingService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  ThgCreateThgMeterReadingDtoGen,
  ThgOperationIdViewModelGen,
  ThgThgMeterReadingControllerUpdateStatusAsyncParamsGen,
  ThgThgMeterReadingViewModelGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "./../../services/thg/v1/data-contracts";
import { GtagModule } from "./gtag.store";
import { ReportModule } from "./report.store";
import { uploadReportImage } from "./thg.create.store";

/**
 * Store to handle meter readings for user
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "meter-reading",
  store
})
export class MeterReadingStore extends VuexModule {
  /**
   * A single active meter reading
   */
  private _activeMeterReading: ThgThgMeterReadingViewModelGen | undefined = undefined;

  /**
   * All fetched meter readings
   */
  private _meterReadings: ThgThgMeterReadingViewModelGen[] = [];

  /**
   * An efficient lookup table, where key is the meter reading id
   */
  private _meterReadingsLookup: Map<string, ThgThgMeterReadingViewModelGen> = new Map();

  /**
   * An efficient lookup table, where key is the charging station id
   */
  private _meterReadingsPerChargingStationLookup: Map<string, ThgThgMeterReadingViewModelGen[]> = new Map();

  /**
   * Image of the meter reading
   */
  private _meterReadingProof: IImageUploaded[] = [];

  /**
   * Meter Readings
   */

  /**
   * The minimum amount to create a meter reading with
   */
  get minAmountInKwh() {
    return 500;
  }

  /**
   * Gets all meter readings
   */
  get meterReadings(): ThgThgMeterReadingViewModelGen[] {
    return this._meterReadings;
  }

  /**
   * Gets all meter readings
   *
   * @param id meter reading id
   */
  get meterReadingLookup() {
    return this._meterReadingsLookup;
  }

  /**
   * Gets all meter readings for a charging station
   *
   * @param id charing station id
   */
  get meterReadingsPerChargingStationLookup() {
    return this._meterReadingsPerChargingStationLookup;
  }

  /**
   * mutation for all meter readings
   * @param chargingStations meter readings to put into store
   */
  @Mutation
  private _mutateMeterReadings(meterReadings: ThgThgMeterReadingViewModelGen[]): void {
    this._meterReadings = meterReadings;
    this._meterReadingsLookup = new Map(meterReadings.map(obj => [obj.id, obj]));

    this._meterReadingsPerChargingStationLookup = new Map();
    for (const reading of meterReadings) {
      const key = reading.meterReading.chargingStationId;
      if (!key) {
        continue;
      }

      const v = this._meterReadingsPerChargingStationLookup.get(key);
      if (v) {
        v.push(reading);
      } else {
        this._meterReadingsPerChargingStationLookup.set(key, [reading]);
      }
    }
  }

  /**
   * Fetches all meter readings for current user
   */
  @Action
  async getMeterReadings(): Promise<void> {
    const readings = await meterReadingService.getAllForUser();
    this.context.commit("_mutateMeterReadings", readings);
  }

  /**
   * Removes meter readings from store
   */
  @Action
  clearMeterReadings() {
    this.context.commit("_mutateMeterReadings", []);
  }

  /**
   * Active Meter Reading
   */

  /**
   * Returns the active meter reading
   */
  get activeMeterReading(): ThgThgMeterReadingViewModelGen | undefined {
    return this._activeMeterReading;
  }

  /**
   * Sets the active meter reading
   *
   * @param activeChargingStation the meter reading to put into active
   */
  @Mutation
  private _mutateActiveMeterReading(activeMeterReading: ThgThgMeterReadingViewModelGen): void {
    this._activeMeterReading = activeMeterReading;
  }

  /**
   * Creates a meter reading
   *
   * @param data dto to create meter reading
   */
  @Action
  async createMeterReading(data: { partnerId: string; data: ThgCreateThgMeterReadingDtoGen }): Promise<void> {
    const created = await meterReadingService.create(data.partnerId, data.data);

    GtagModule.dispatchPurchaseEventMeterReading({ thg: data.data });

    this.context.commit("_mutateActiveMeterReading", created);
  }

  /**
   * Gets one meter reading.
   *
   * @param id the id of the meter reading to be fetched
   */
  @Action
  async getMeterReading(id: string): Promise<void> {
    const reading = await meterReadingService.getOneForUser(id);
    this.context.commit("_mutateActiveMeterReading", reading);
  }

  /**
   * Gets one meter reading.
   *
   * @param id the id of the meter reading to be fetched
   */
  @Action
  async getMeterReadingsByChargingStation(id: string): Promise<void> {
    const reading = await meterReadingService.getMeterReadingByChargingStation(id);
    this.context.commit("_mutateMeterReadings", reading);
  }

  /**
   * Removes active meter reading from the store.
   */
  @Action
  clearMeterReading() {
    this.context.commit("_mutateActiveMeterReading", undefined);
  }

  @Mutation
  _mutateMeterReadingProof(file: IImageUploaded) {
    this._meterReadingProof = [file];
  }

  get meterReadingProof() {
    return this._meterReadingProof;
  }

  @Action
  async addMeterReading(file: File) {
    const imageResult = await uploadReportImage(
      file,
      ReportImageType.registration,
      ReportModule.partner.id || ReportModule.partner._id
    );
    this.context.commit("_mutateMeterReadingProof", imageResult);
  }

  @Action
  async removeMeterReadingProof(): Promise<boolean> {
    this.context.commit("_mutateMeterReadingProof", undefined);
    return true;
  }

  /**
   * creates async action to operate status of thgs
   *
   * @param data
   * @returns
   */
  @Action
  async updateStatus(data: {
    query: ThgThgMeterReadingControllerUpdateStatusAsyncParamsGen;
    data: ThgUpdateThgStatusOfThgIdsDtoGen;
  }): Promise<ThgOperationIdViewModelGen> {
    const operation = await meterReadingService.updateStatuses(data.query, data.data);

    return operation;
  }
}

export const MeterReadingModule = getModule(MeterReadingStore);
