






















































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import CsvExportMixin from "@/mixins/CsvExportMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { IFullOptions } from "json-2-csv";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class ThgQuoteExportDialog extends CsvExportMixin {
  @Prop()
  thgs!: IThg[];

  @Prop({ default: "" })
  titleStyle!: string;

  @Prop({ default: "" })
  titleTextStyle!: string;

  @Prop({ default: "grey lighten-1" })
  btnColor!: string;

  @Prop({ default: false })
  disableStatiSelector!: boolean;

  dialog = false;
  options: IFullOptions = {
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ";", // Comma field delimiter
      eol: "\n" // Newline delimiter
    },
    prependHeader: true,
    sortHeader: false,
    excelBOM: true,
    trimFieldValues: true,
    keys: []
  };

  selection: any[] = [];
  selectedStati: any[] = [];
  thgStati = Object.keys(ThgStatusEnum).map(k => ThgStatusEnum[k as any]);

  get selectedKeys() {
    const keys = [];
    for (const key of this.selection) {
      keys.push(key.objetRef);
    }
    return keys;
  }

  initialize() {
    if (this.disableStatiSelector) {
      this.selectedStati = this.thgStati;
    } else {
      this.selectedStati = ["confirmed"];
    }

    this.selection = [
      { id: 18, name: "firstname", objetRef: "registration.firstname" },
      { id: 19, name: "name", objetRef: "registration.name" },
      { id: 26, name: "firstregistrationDay", objetRef: "registration.firstregistrationDay" },
      { id: 27, name: "firstregistrationMonth", objetRef: "registration.firstregistrationMonth" },
      { id: 28, name: "firstregistrationYear", objetRef: "registration.firstregistrationYear" },
      { id: 23, name: "identificationnumber", objetRef: "registration.identificationnumber" },
      { id: 33, name: "driveTyp", objetRef: "registration.driveTyp" },
      { id: 34, name: "vehicleClass", objetRef: "registration.vehicleClass" },
      { id: 12, name: "created", objetRef: "timestamp.created" },
      { id: 15, name: "name", objetRef: "registrationImages.name" }
    ];
  }

  get filteredData() {
    return this.thgs.filter(thg => this.selectedStati.includes(thg.status));
  }

  close() {
    this.dialog = false;
  }

  exportData() {
    this.options.keys = this.selectedKeys;
    this.download(this.filteredData, this.options);
    this.dialog = false;
  }

  items = [
    {
      id: 1,
      name: "id",
      objetRef: "id"
    },
    {
      id: 2,
      name: "userId",
      objetRef: "userId"
    },
    {
      id: 3,
      name: "partnerId",
      objetRef: "partnerId"
    },
    {
      id: 4,
      name: "status",
      objetRef: "status"
    },
    {
      id: 5,
      name: "bonus",
      objetRef: "bonus"
    },
    {
      id: 6,
      name: "impactType",
      objetRef: "impactType"
    },
    {
      id: 7,
      name: "impactFactor",
      objetRef: "impactFactor"
    },
    {
      id: 8,
      name: "campaign",
      objetRef: "campaign"
    },
    {
      id: 9,
      name: "numberplate",
      objetRef: "numberplate"
    },
    {
      id: 10,
      name: "kownFrom",
      objetRef: "kownFrom"
    },
    {
      id: 11,
      name: "timestamp",
      objetRef: "timestamp.created",
      children: [{ id: 12, name: "created", objetRef: "timestamp.created" }]
    },
    {
      id: 13,
      name: "registrationImages",
      objetRef: "registrationImages",
      children: [
        { id: 14, name: "folderName", objetRef: "registrationImages.folderName" },
        { id: 15, name: "name", objetRef: "registrationImages.name" },
        { id: 16, name: "url", objetRef: "registrationImages.url" }
      ]
    },
    {
      id: 17,
      name: "registration",
      objetRef: "registration",
      children: [
        { id: 18, name: "firstname", objetRef: "registration.firstname" },
        { id: 19, name: "name", objetRef: "registration.name" },
        { id: 20, name: "street", objetRef: "registration.street" },
        { id: 21, name: "zipCode", objetRef: "registration.zipCode" },
        { id: 22, name: "numberplate", objetRef: "registration.numberplate" },
        { id: 23, name: "identificationnumber", objetRef: "registration.identificationnumber" },
        { id: 24, name: "huYear", objetRef: "registration.huYear" },
        { id: 25, name: "huMonth", objetRef: "registration.huMonth" },
        { id: 26, name: "firstregistrationDay", objetRef: "registration.firstregistrationDay" },
        { id: 27, name: "firstregistrationMonth", objetRef: "registration.firstregistrationMonth" },
        { id: 28, name: "firstregistrationYear", objetRef: "registration.firstregistrationYear" },
        { id: 29, name: "manufacturerNameCode", objetRef: "registration.manufacturerNameCode" },
        { id: 30, name: "manufacturerTypeCode", objetRef: "registration.manufacturerTypeCode" },
        { id: 31, name: "manufacturerName", objetRef: "registration.manufacturerName" },
        { id: 32, name: "manufacturerType", objetRef: "registration.manufacturerType" },
        { id: 33, name: "driveTyp", objetRef: "registration.driveTyp" },
        { id: 34, name: "vehicleClass", objetRef: "registration.vehicleClass" }
      ]
    },
    {
      id: 35,
      name: "year",
      objetRef: "year"
    },
    {
      id: 36,
      name: "isMultiyear",
      objetRef: "isMultiyear"
    }
  ];
}
