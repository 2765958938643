var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-radio-group', {
    model: {
      value: _vm.impactType,
      callback: function callback($$v) {
        _vm.impactType = $$v;
      },
      expression: "impactType"
    }
  }, _vm._l(_vm.items, function (item, i) {
    return _c('v-sheet', {
      key: i,
      staticClass: "mb-2 py-n6",
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": _vm.activeCardStyle(item.impactType)
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      },
      on: {
        "click": function click($event) {
          _vm.impactType = item.impactType;
        }
      }
    }, [_c('v-list', {
      attrs: {
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-avatar', {
      attrs: {
        "tile": "",
        "size": "60"
      }
    }, [item.src ? _c('v-img', {
      staticStyle: {
        "border-radius": "4px"
      },
      attrs: {
        "src": item.src
      }
    }) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(item.titleAlt)) + " "), item.showImpactFactorSlider ? _c('v-chip', {
      attrs: {
        "color": "success",
        "small": ""
      }
    }, [_vm._v(_vm._s(_vm.$t("report.thgImpactTypeItems.sustainable.label")))]) : _vm._e()], 1), _c('v-list-item-subtitle', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")]), _vm._v(_vm._s(_vm.$t("report.thgImpactTypeItems.sustainable.first")))], 1), _c('v-list-item-subtitle', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")]), _vm._v(_vm._s(_vm.$t("report.thgImpactTypeItems.sustainable.second")))], 1), _c('v-list-item-subtitle', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")]), _vm._v(_vm._s(_vm.$t("report.thgImpactTypeItems.sustainable.third")))], 1), item.showImpactFactorSlider ? _c('v-list-item-subtitle', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")]), _vm._v(_vm._s(_vm.$t(item.subtitleAlt)))], 1) : _vm._e(), item.impactType === _vm.impactType && item.showImpactFactorSlider ? _c('div', [_c('span', {
      staticClass: "font-weight-black text-h5",
      style: "color:".concat(_vm.color)
    }, [_vm._v(_vm._s(_vm.impactFactor + " %"))]), _c('thg-checkout-sustainable-factor-dialog', {
      attrs: {
        "item": item
      },
      on: {
        "update": _vm.updateImpactFactor
      }
    })], 1) : _vm._e()], 1), _c('v-list-item-action', [_c('v-radio', {
      attrs: {
        "value": item.impactType
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }