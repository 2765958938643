











import ThgCreateOverviewCard from "@/components/thg/ThgCreateOverviewCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { ConflictException, BadRequestException } from "@/lib/exceptions/http";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component } from "vue-property-decorator";
import ThgClosingCard from "./ThgClosingCard.vue";

@Component({
  components: {
    LayoutReportStep,
    ThgCreateOverviewCard,
    ThgClosingCard
  }
})
export default class ThgClosing extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.thgClosing.title",
    description: "report.thgClosing.description"
  };

  screen = ReportScreenEnum.thgclosing;
  isDone = true;

  isLoading = false;

  userExists = false;
  userExistsText = "report.thgClosing.userAlreadyExists";

  get hint() {
    return "";
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get isConfirmed(): boolean {
    return ThgCreateModule.isConfirmed;
  }

  set isConfirmed(bool: boolean) {
    ThgCreateModule.setIsConfirmed(bool);
  }

  get isProduction() {
    return process.env.NODE_ENV === "production";
  }

  /**
   * Tries to submit a THG.
   * If submission is successful
   */
  async submit() {
    // if (this.form.validate()) {
    this.isLoading = true;
    try {
      if (!this.isAuthenticated) {
        await ThgCreateModule.submit();
      } else {
        await ThgCreateModule.submitIfAuthenticated();
      }

      this.$toast.success(this.$t("report.thgClosing.success"));
      await this.$router.push({ name: "ThgSuccess" });
    } catch (error) {
      /**
       * Handle the case that a user already has an account.
       */
      if (error instanceof ConflictException) {
        if (this.isUserExisting(error.message)) {
          this.$toast.error(this.$t(this.userExistsText));
          this.userExists = true;
          return;
        } else {
          this.$toast.error(this.$t("report.thgClosing.error"));
        }
      } else if (error instanceof BadRequestException) {
        if (error.message.includes("userName") || error.message.includes("CodeDeliveryFailureException")) {
          this.$toast.error(this.$t("report.thgClosing.invalidUsername"));
          return;
        } else {
          this.$toast.error(this.$t("report.thgClosing.error"));
        }
      } else {
        this.$log.error(error);
        this.$toast.error(this.$t("report.thgClosing.error"));
      }

      this.isConfirmed = false;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Checks wether the error messages indicates a user exists.
   *
   * @param message the exception message received
   * @returns if user exists
   */
  isUserExisting(message: string) {
    return [
      "An account with the given email already exists.",
      "Email-Address already used.",
      "UsernameExistsError"
    ].includes(message);
  }

  get partnerName(): string {
    return ReportModule?.partner.companyName;
  }
}
