

















import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import ThgRegistrationFrontUploadCard from "@/components/thg/ThgRegistrationFrontUploadCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti,
    ThgRegistrationFrontUploadCard
  }
})
export default class ThgRegistrationFrontImageUpload extends ReportStepMixin implements IReportStep {
  get getRegistrationImageFront() {
    return ThgCreateModule.registrationImageFront;
  }

  screen = ReportScreenEnum.thgregistrationfront;

  header: IReportHeaderData = {
    title: "report.thgRegistrationFront.title",
    description: "report.thgRegistrationFront.description",
    image: AssetEnum.registrationExample
  };

  instruction: IReportInstructionData = {
    title: "report.thgRegistrationFront.title",
    text: "report.thgRegistrationFront.instructionText",
    pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
    example: AssetEnum.registrationExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  get isDone(): boolean {
    if (this.getRegistrationImageFront.length <= 0) {
      return false;
    }

    return true;
  }
}
