/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAffiliateControllerFindManyByCodePayloadGen,
  ThgAffiliateViewmodelGen,
  ThgCreateAffiliateDtoGen,
  ThgExceptionViewmodelGen,
  ThgShortAffiliateViewmodelGen,
  ThgUpdateAffiliateDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Affiliate<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerAddAffiliate
   * @summary Create an affiliate
   * @request POST:/partner/{partnerId}/affiliate
   * @secure
   * @response `200` `ThgAffiliateViewmodelGen` The record has been successfully created
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `409` `ThgExceptionViewmodelGen` An conflict occured.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerAddAffiliate = (partnerId: string, data: ThgCreateAffiliateDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgAffiliateViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/affiliate`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerFindAll
   * @summary Gets all Affiliates for a given partnerid
   * @request GET:/partner/{partnerId}/affiliate
   * @secure
   * @response `200` `(ThgAffiliateViewmodelGen)[]` The record has been successfully created
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerFindAll = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgAffiliateViewmodelGen[], ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/affiliate`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerFindAllByUserId
   * @summary Gets all Affiliates for a given userId
   * @request GET:/user/{userId}/affiliate
   * @secure
   * @response `200` `(ThgAffiliateViewmodelGen)[]` The record has been successfully created
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerFindAllByUserId = (userId: string, params: RequestParams = {}) =>
    this.http.request<ThgAffiliateViewmodelGen[], ThgExceptionViewmodelGen>({
      path: `/user/${userId}/affiliate`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerFindOrCreateMyAffiliates
   * @summary Get my Affiliates.
   * @request GET:/partner/{partnerId}/affiliate/me
   * @secure
   * @response `200` `(ThgShortAffiliateViewmodelGen)[]` The list of Affiliate Documents
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `409` `ThgExceptionViewmodelGen` An conflict occured.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerFindOrCreateMyAffiliates = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgShortAffiliateViewmodelGen[], ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/affiliate/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerFind
   * @summary Gets a specific Affiliate
   * @request GET:/partner/{partnerId}/affiliate/{affiliateId}
   * @secure
   * @response `200` `ThgAffiliateViewmodelGen` The requested affiliate document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerFind = (partnerId: string, affiliateId: string, params: RequestParams = {}) =>
    this.http.request<ThgAffiliateViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/affiliate/${affiliateId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerUpdate
   * @summary Updates a specific Affiliate
   * @request PATCH:/partner/{partnerId}/affiliate/{affiliateId}
   * @secure
   * @response `200` `ThgAffiliateViewmodelGen` The updated affiliate document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `409` `ThgExceptionViewmodelGen` An conflict occured.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerUpdate = (
    partnerId: string,
    affiliateId: string,
    data: ThgUpdateAffiliateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAffiliateViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/affiliate/${affiliateId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerFindManyByCode
   * @summary (ADMIN) Gets a list of affiliates by code
   * @request POST:/affiliate/many/byCode
   * @secure
   * @response `200` `(ThgAffiliateViewmodelGen)[]` The requested affiliates
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerFindManyByCode = (
    data: ThgAffiliateControllerFindManyByCodePayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAffiliateViewmodelGen[], ThgExceptionViewmodelGen>({
      path: `/affiliate/many/byCode`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags affiliate
   * @name AffiliateControllerFindByCode
   * @summary (AFFILIATE - READ) Gets a specific Affiliate by code
   * @request GET:/partner/{partnerId}/affiliate/{affiliateCode}/byCode
   * @secure
   * @response `200` `ThgAffiliateViewmodelGen` The requested affiliate document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  affiliateControllerFindByCode = (partnerId: string, affiliateCode: string, params: RequestParams = {}) =>
    this.http.request<ThgAffiliateViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/affiliate/${affiliateCode}/byCode`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
