


























import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgPriceCard extends Vue {
  @Prop({ default: 800 })
  maxWidth!: number;

  @Prop()
  title!: string;

  @Prop()
  subtitle!: string;

  @Prop()
  src!: string;

  @Prop()
  price!: string;

  get color() {
    return getDefaultPartnerColor();
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }
}
