






















import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DescriptionCard extends Vue {
  @Prop({ default: 800 })
  maxWidth!: number;

  @Prop()
  headline!: "";

  @Prop()
  description!: "";

  /**
   * Use this Prob to configure the order of the Text and the image
   */
  @Prop({ default: false })
  reverse!: boolean;

  @Prop({ default: "text-caption font-weight-light" })
  textStyle!: string;

  get color() {
    return getDefaultPartnerColor();
  }

  /**
   * Returns the order of the text and the image.
   * Last means, image is to the right side (or on mobile under the text; on mobile always returns last)
   * First means, image is to the left side (or on mobile above the text)
   */
  get order(): string {
    if (this.reverse && !["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      return "last";
    }
    return "first";
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }
}
