


































import { Component, Vue, Prop } from "vue-property-decorator";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import { $t } from "@/lib/utility/t";
import { ActivePromotion } from "@/store/models/thg/active-promotion";

@Component({
  components: {},
  filters: {}
})
export default class ThgPromotionListItem extends Vue {
  @Prop()
  value!: ActivePromotion;

  get valueString() {
    if (this.value.currency === CurrencyUnitEnum.CENT) {
      // make 123213 to 1232.13 €
      const value = this.value.value.toString();
      return `${value.slice(0, -2)},${value.slice(-2)} €`;
    }
    if (this.value.currency === CurrencyUnitEnum.TEUR) {
      return `${this.value.value * 1000} €`;
    }
    if (this.value.currency === CurrencyUnitEnum.EUR) {
      return `${this.value.value},00 €`;
    }

    return "N/A";
  }

  get type() {
    return $t(`common.PromotionTypeEnum.${this.value.type}`).toUpperCase();
  }
}
