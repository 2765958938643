import { CountryCodeEnum } from "../../../lib/enum/country-code.enum";
import { IUser } from "@/models/user.entity";
import { ThgAddressGen, ThgChargingPointOperatorGen } from "@/services/thg/v1/data-contracts";
import { UserModule } from "@/store/modules/me-user.store";

/**
 * @inheritdoc
 */
export class ChargingStationOperatorUiDto implements ThgChargingPointOperatorGen {
  /**
   * @inheritdoc
   */
  name: string;

  /**
   * @inheritdoc
   */
  managingDirectorName?: string;

  /**
   * @inheritdoc
   */
  address: ThgAddressGen;

  /**
   * @inheritdoc
   */
  evseOperatorId?: string;

  /**
   * @inheritdoc
   */
  bnetzaOperatorId?: string;

  /**
   * Creates a Charging Station Operator (CPO)
   */
  constructor(operator?: ThgChargingPointOperatorGen, user?: IUser, countryCode: string = CountryCodeEnum.germany) {
    this.name = "";
    this.evseOperatorId = "";
    this.bnetzaOperatorId = "";
    this.address = {
      street: "",
      zip: "",
      city: "",
      state: "",
      countryCode: countryCode
    };

    if (user && user.company) {
      this.name = user.company;
    }

    if (operator) {
      this.name = operator.name;
      this.evseOperatorId = operator.evseOperatorId;
      this.bnetzaOperatorId = operator.bnetzaOperatorId;

      if (operator.address) {
        this.address = operator.address;
      }
    }
  }

  /**
   * Creates a cpo from a user
   *
   * @param user the user to use as cpo
   * @returns the Charging Station Operator to create
   */
  static createFromUser(user: IUser): ChargingStationOperatorUiDto {
    const cpo = new ChargingStationOperatorUiDto();

    if (!user) {
      return cpo;
    }

    if (user.company) {
      cpo.name = user.company;
    }

    return cpo;
  }

  /**
   * Creates a cpo from a user
   *
   * @param user the user to use as cpo
   * @returns the Charging Station Operator to create
   */
  static createFromCurrentUser(): ChargingStationOperatorUiDto {
    const cpo = new ChargingStationOperatorUiDto();
    const user = UserModule.user;

    if (!user) {
      return cpo;
    }

    if (user.company) {
      cpo.name = user.company;
    }

    return cpo;
  }
}
