

































import { Component, Vue } from "vue-property-decorator";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import FileUploadMulti from "../files/FileUploadMulti.vue";

@Component({
  components: { FileUploadMulti }
})
export default class ThgRegistrationAtUploadCard extends Vue {
  multiSteps = {
    frontLeft: {
      example: AssetEnum.registrationAt,
      overlay: AssetEnum.registrationAtSchema,
      text: "report.thgRegistrationAt.registrationFront",
      textAlt: "report.thgRegistrationAt.registrationFrontAlt",
      filesCb: ThgCreateModule.registrationImageFront,
      handleUpload: ThgCreateModule.addRegistrationFront,
      handleDelete: ThgCreateModule.removeRegistrationFront
    },
    rearLeft: {
      example: AssetEnum.registrationAtBack,
      overlay: AssetEnum.registrationAtBackSchema,
      text: "report.thgRegistrationAt.registrationBack",
      textAlt: "report.thgRegistrationAt.registrationBackAlt",
      filesCb: ThgCreateModule.registrationImageBack,
      handleUpload: ThgCreateModule.addRegistrationBack,
      handleDelete: ThgCreateModule.removeFromRegistrationImageBack
    }
  };

  get getRegistrationImageFront() {
    return ThgCreateModule.registrationImageFront;
  }

  get getRegistrationImageBack() {
    return ThgCreateModule.registrationImageBack;
  }

  instruction: IReportInstructionData = {
    title: "report.thgRegistration.title",
    text: "report.thgRegistration.instructionText",
    pictures: [AssetEnum.registrationAtCorrectInstructions, AssetEnum.registrationAtIncorrectInstructions],
    example: AssetEnum.registrationAtExample,
    display: true
  };
}
