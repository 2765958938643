










































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgRegistrationDtoGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";

import { ThgModule } from "@/store/modules/thg.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class ThgQuoteVehicleUpdateRegistrationDialog extends DarkModeHighlightMixin {
  @Prop()
  selectedThg!: IThg;

  @Prop({ default: true })
  btn!: boolean;

  get myThg() {
    return this.selectedThg;
  }

  isLoading = false;

  dialog = false;

  async submit(registrationDto: ThgRegistrationDtoGen) {
    this.dialog = false;
    this.isLoading = true;
    await ThgModule.addRegistrationToMyThg({ thdId: this.selectedThg.id, registrationDto });
    this.isLoading = false;
  }

  get loading() {
    return this.isLoading;
  }

  get user() {
    return UserModule.user;
  }

  close() {
    this.dialog = false;
  }
}
