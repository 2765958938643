var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-10",
    attrs: {
      "max-width": "850",
      "flat": ""
    }
  }, [!_vm.hideTitle ? _c('v-card-title', {
    staticClass: "title pt-4",
    style: _vm.partnerColor.gradientStyle
  }, [_c('span', {
    style: "color:white"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.title")))]), _c('v-spacer')], 1) : _vm._e(), !_vm.isAuthenticated ? _c('div', [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_vm.isCompany ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-briefcase-account-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.company, "components.thg.ThgCreateOverviewCard.company")) + " ")]), !_vm.isResubmitTaxnumber ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.taxNumber, "components.thg.ThgCreateOverviewCard.taxNumber")) + " ")]) : _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.isResubmitTaxnumber")) + " ")]), !_vm.taxNumber ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hintTaxnumber2")) + " ")]) : _vm._e()], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.goToContactData
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-email-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.email, "components.thg.ThgCreateOverviewCard.mail")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.name, "components.thg.ThgCreateOverviewCard.name")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.phone) + " ")])], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.goToContactData
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-map-marker-outline")])], 1), !_vm.isResubmitAddress ? _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.street, "components.thg.ThgCreateOverviewCard.street")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.address, "components.thg.ThgCreateOverviewCard.address")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hintAddress1")) + " ")])], 1) : _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.isResubmitAddress")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hintAddress2")) + " ")])], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.goToContactData
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1), !_vm.showCustomerAccount ? _c('div', [!_vm.isResubmitBankingInformation ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-credit-card-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.banking.iban, "components.thg.ThgCreateOverviewCard.iban")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.banking.bank, "components.thg.ThgCreateOverviewCard.bankname")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.banking.name, "components.thg.ThgCreateOverviewCard.accountOwner")) + " ")])], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.goToContactData
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1) : _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-credit-card-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.resubmitBank")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hintResubmitBank")) + " ")])], 1)], 1)], 1) : _vm._e()], 1), _c('v-divider')], 1) : _vm._e(), _c('div', [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-counter")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.showValueOrDefault(_vm.numberplate, "components.thg.ThgCreateOverviewCard.numberplate")) + " ")])], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.goToContactData
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1), _vm.showYears ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-calendar-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.years")) + " ")]), _c('v-list-item-subtitle', _vm._l(_vm.years, function (year, i) {
    return _c('v-chip', {
      key: i,
      staticClass: "mx-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(_vm._s(year))]);
  }), 1)], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.goToYearSelection
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-car-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.registration")) + " "), _vm.isRegistrationFront ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]) : _c('v-icon', {
    attrs: {
      "color": "red",
      "small": ""
    }
  }, [_vm._v("mdi-close")])], 1), _vm.isRegistrationFront ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.registrationHint")) + " ")]) : _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.registrationHint2")) + " ")])], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.goToRegistration
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "ml-14"
  }, [_vm.isRegistrationFront ? _c('image-dialog', {
    attrs: {
      "src": _vm.getRegistrationImageFront,
      "title": "Vorderseite"
    }
  }) : _vm._e()], 1), _c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [!_vm.showCustomerAccount ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-chart-donut")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.payoutSubtitle1", {
    payout: _vm.payout
  })) + " ")]), _vm.showImpact ? _c('v-list-item-subtitle', {
    staticStyle: {
      "color": "#F44336 !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.impactSubtitle", {
    impact: _vm.impact,
    impactType: _vm.$t(_vm.impactType)
  })) + " ")]) : _vm._e()], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.goToImpactSelector
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.showCustomerAccount ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-chart-donut")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" Kundenkonto")]), !_vm.showImpact ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.customerAccount")) + " ")]) : _vm._e()], 1), !_vm.hideEdit ? _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.goToImpactSelector
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _vm.showImpact ? _c('v-card-text', {
    staticClass: "mt-n4"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hint", {
    impactType: _vm.$t(_vm.impactType),
    impact: _vm.impact,
    payOutRelative: _vm.payout
  })) + " ")]) : _vm._e(), _vm.isShowingPriceInformationForNextYear ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hintPriceNextYear")) + " ")]) : _vm._e(), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgCreateOverviewCard.hintUba")) + " ")]), _c('v-divider')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }