














import { Component, Vue, Prop } from "vue-property-decorator";
import ImageDialog from "../utility/ImageDialog.vue";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgCreateModule } from "@/store/modules/thg.create.store";

@Component({
  components: {
    ImageDialog
  }
})
export default class ThgQuickCheckoutValidateRegistrationCard extends Vue {
  @Prop()
  thg!: ThgThgMeViewModelGen;

  get isRegistrationValid() {
    return ThgCreateModule.isRegistrationValid;
  }

  set isRegistrationValid(isRegistrationValid: boolean) {
    ThgCreateModule.setIsRegistrationValid(isRegistrationValid);
  }
}
