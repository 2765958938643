





















































import { PartnerColor } from "@/lib/partnerColor";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import ThgCheckoutSustainableFactorDialog from "./ThgCheckoutSustainableFactorDialog.vue";

@Component({
  components: {
    ThgCheckoutSustainableFactorDialog
  }
})
export default class ThgCheckoutSustainableCard extends Vue {
  @Prop({ default: false })
  hideEdit?: boolean;

  get color() {
    return this.$vuetify.theme.dark ? "white" : this.$vuetify.theme.themes.light.primary;
  }

  get impactFactor() {
    return ThgCreateModule.impactFactor;
  }

  set impactFactor(impactFactor: number) {
    ThgCreateModule.setImpactFactor(impactFactor);
  }

  updateImpactFactor(impactFactor: number) {
    this.impactFactor = impactFactor;
  }

  get icon(): string {
    return ThgCreateModule.impactTypeItem.icon;
  }

  get impactType() {
    return ThgCreateModule.impactType;
  }

  set impactType(type: ImpactTypeEnum) {
    let setImpactFactor = false;
    if (type !== this.impactType) {
      setImpactFactor = true;
    }
    ThgCreateModule.setImpactType(type);

    /**
     * Only set impacfactor to base factor if impact item changes
     */
    if (setImpactFactor) {
      ThgCreateModule.setImpactFactor(ThgCreateModule.impactTypeItem.baseImpactFactor);
    }
  }

  activeCardStyle(type: ImpactTypeEnum) {
    if (this.impactType === type) {
      return "primary";
    }
    return "";
  }

  get items() {
    return ThgCreateModule.activeImpactTypeItemsOrdered;
  }

  partnerColor = new PartnerColor();
}
