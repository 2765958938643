
import { Component } from "vue-property-decorator";
import ThgClosing from "./ThgClosing.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {}
})
export default class ThgClosingWithHint extends ThgClosing {
  screen = ReportScreenEnum.thgclosingwithhint;
  get hint() {
    return this.$t("report.thgClosing.hint", { partner: this.partnerName }).toString();
  }
}
