var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type === 'lat' || _vm.type === 'lng' ? _c('v-text-field', {
    attrs: {
      "rules": _vm.geoRule,
      "min": _vm.min,
      "max": _vm.max,
      "label": _vm.label,
      "hint": _vm.hint,
      "type": "number",
      "required": "",
      "outlined": "",
      "persistent-hint": "",
      "append-icon": "mdi-map-marker",
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.disabled
    },
    on: {
      "click:append": _vm.getLocation,
      "input": _vm.updateValue
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = _vm._n($$v);
      },
      expression: "value"
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }