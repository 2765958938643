import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { Account } from "../v1/Account";
import {
  ThgPageViewModelGen,
  ThgAccountControllerCreateAccountParamsGen,
  ThgAccountControllerFindAllParamsGen,
  ThgAccountDtoGen,
  ThgAccountViewmodelGen,
  ThgOperationIdViewModelGen,
  ThgUpdateAccountDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Communicates with the accounting module
 */
class AccountService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Account;

  /**
   * @class Initialize AccountService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Account(this.client);
  }

  async findAll(
    query: ThgAccountControllerFindAllParamsGen
  ): Promise<ThgPageViewModelGen & { data: ThgAccountViewmodelGen[] }> {
    const q = query || {};
    const res = (await this.proxy.accountControllerFindAll(q)).data;

    return { meta: res.meta, data: res.data ?? [] };
  }

  async create(
    query: ThgAccountControllerCreateAccountParamsGen,
    dto: ThgAccountDtoGen
  ): Promise<ThgAccountViewmodelGen> {
    const response = await this.proxy.accountControllerCreateAccount(query, dto);

    return response.data;
  }

  async update(accountId: string, data: ThgUpdateAccountDtoGen): Promise<ThgAccountViewmodelGen> {
    const response = await this.proxy.accountControllerUpdate(accountId, data);

    return response.data;
  }

  async check(): Promise<ThgOperationIdViewModelGen> {
    const response = await this.proxy.accountControllerValidityCheck();

    return response.data;
  }

  async getUserAccountNumber() {
    const response = await this.proxy.accountControllerFindMe();

    return response.data;
  }
}

export default new AccountService(new ThgHttpClientProvider());
