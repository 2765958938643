



































import { Component, Prop, Vue } from "vue-property-decorator";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ThgCreateModule } from "@/store/modules/thg.create.store";

@Component({
  filters: {
    commaSeparated(values: []): string {
      if (!values) return "";

      return values.join(", ");
    }
  }
})
export default class ThgQuickCheckoutClosingCard extends Vue {
  @Prop()
  thg!: ThgThgMeViewModelGen;

  @Prop()
  isValid!: boolean;

  get selectedYears() {
    return ThgCreateModule.years;
  }

  get formValid() {
    return this.isValid;
  }
  set formValid(v: boolean) {
    this.$emit("update:isValid", v);
  }

  get isConfirmed() {
    return ThgCreateModule.isConfirmed;
  }

  set isConfirmed(isConfirmed: boolean) {
    ThgCreateModule.setIsConfirmed(isConfirmed);
  }

  get isTermsAccepted() {
    return ThgCreateModule.isTermsAccepted;
  }

  set isTermsAccepted(isTermsAccepted: boolean) {
    ThgCreateModule.setIsTermsAccepted(isTermsAccepted);
  }

  get requiredRule() {
    return requiredRule();
  }
}
