
































































import { HttpException } from "@/lib/exceptions/http";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDate } from "@/lib/utility/date-helper";
import { ThgCalculateEmissionSavingsDtoGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";

import { ThgCalculationMethodEnum } from "@/store/enum/thg/thg-calculation-type.enum";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ThgAdminUpdatePayoutConfigurationDialog from "./ThgAdminUpdatePayoutConfigurationDialog.vue";
import { ThgModule } from "@/store/modules/thg.store";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import ThgProof from "./ThgProofs.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { Card, ThgAdminUpdatePayoutConfigurationDialog, ConfirmActionDialog, ThgProof },
  filters: { simpleDate }
})
export default class ThgEmissionCalculationDialog extends Vue {
  @Prop({ default: undefined })
  thg!: IThg;

  @Prop({ default: false })
  admin!: boolean;

  get formDisabled() {
    /**
     * Form valid?
     */
    if (!this.form) {
      return true;
    }

    /**
     * If a file is currently uploading
     */
    if (ThgModule.isLoadingProofs) {
      return true;
    }

    /**
     * If calculation method exact, is at least one proof added?
     */
    if (this.dto.method === ThgCalculationMethodEnum.EXACT) {
      if (!ThgModule.myThg?.proofs?.length) {
        return true;
      }
    }

    /**
     * Form i.o.
     */
    return false;
  }

  get isHybridStatus(): boolean {
    return [
      ThgStatusEnum.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS,
      ThgStatusEnum.DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS,
      ThgStatusEnum.NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS
    ].includes(this.thg.status as ThgStatusEnum);
  }

  get btnConfig() {
    if (
      [
        ThgStatusEnum.DATA_REQUESTED_BEV_AT_QUOTA_DETAILS,
        ThgStatusEnum.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS
      ].includes(this.thg.status as ThgStatusEnum)
    ) {
      return {
        text: this.$t("components.thg.ThgEmissionCalculationDialog.btn"),
        color: "warning"
      };
    }
    return {
      text: this.$t("components.thg.ThgEmissionCalculationDialog.btnAlt"),
      color: "success"
    };
  }

  isDecommissioned = false;
  decommissioningDate = new Date().toISOString().substring(0, 10);

  loading = false;
  updateDialog = false;
  form = false;

  calculationMethodEnum = ThgCalculationMethodEnum;
  dto: ThgCalculateEmissionSavingsDtoGen = {
    method: this.calculationMethodEnum.STATISTIC,
    amountInKwh: 1
  };
  methods = [ThgCalculationMethodEnum.STATISTIC, ThgCalculationMethodEnum.EXACT];

  get amountRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberHigherRule(1));

    return rules;
  }

  get requiredRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  async openUpdateDialog() {
    this.loading = true;
    try {
      await ThgModule.getMyThg(this.thg.id);
      this.updateDialog = true;

      this.isDecommissioned = this.thg.decommissioningDate ? true : false;
      if (this.thg.decommissioningDate) {
        this.decommissioningDate = new Date(this.thg.decommissioningDate).toISOString().substring(0, 10);
      }

      this.dto.amountInKwh = this.thg.exactEnergyAmountInKwH || 0;
      this.dto.method = this.thg.calculationMethod as ThgCalculationMethodEnum;
    } catch (error) {
      handleError(error);
    }

    this.loading = false;
  }

  async calculateEmissionSavings() {
    this.loading = true;

    try {
      const updateDto: ThgCalculateEmissionSavingsDtoGen = {
        method: this.dto.method
      };
      if (this.dto.method === ThgCalculationMethodEnum.EXACT && this.dto.amountInKwh) {
        updateDto.amountInKwh = +this.dto.amountInKwh;
      }

      if (this.admin) {
        await ThgPortalModule.calculateEmissionSavings(updateDto);
      } else {
        if (this.isDecommissioned) {
          await ThgModule.decomission({
            thgId: this.thg.id,
            dto: { decommissioningDate: new Date(this.decommissioningDate).toISOString() }
          });
        } else {
          await ThgModule.decomission({
            thgId: this.thg.id,
            dto: { decommissioningDate: "" }
          });
        }

        await ThgModule.calculateEmissionSavings({ thgId: this.thg.id, dto: updateDto });
      }
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
      this.updateDialog = false;
    }
  }
}
