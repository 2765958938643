














































import Notifications from "@/components/thg/Notifications.vue";
import ThgChargingStationCardIterator from "@/components/thg/ThgChargingStationCardIterator.vue";
import ThgChargingStationTable from "@/components/thg/ThgChargingStationTable.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ThgChargingStationTable,
    ThgChargingStationCardIterator,
    Debug,
    LayoutReport,
    Notifications
  }
})
export default class ThgChargingStationOverview extends Vue {
  isLoading = false;
  partnerColor = new PartnerColor();
  activeView = 0;

  get ChargingStations() {
    return ChargingStationModule.charingStations;
  }

  get MeterReadings() {
    return MeterReadingModule.meterReadings;
  }

  get title() {
    return this.$t("report.thgChargingStationOverview.title");
  }

  get description() {
    return this.$t("report.thgChargingStationOverview.description");
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      await ChargingStationModule.getChargingStations();
      await MeterReadingModule.getMeterReadings();
    } catch (e) {
      this.$toast((e as any).message);
    } finally {
      this.isLoading = false;
    }
  }
}
