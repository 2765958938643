/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { PaginationFilterListElement } from "@/lib/filterable";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Billing, IBilling } from "@/models/billing.entity";
import { MrfiktivUserControllerFindAllPaginatedParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import billingBatchService from "@/services/thg/services/billingBatchService";
import billingService from "@/services/thg/services/billingService";
import {
  ThgAccountingRecordItemViewmodelGen,
  ThgBillingBatchCreateDtoGen,
  ThgBillingInfoGen,
  ThgBillingPdfInputGen,
  ThgBillingViewmodelGen,
  ThgCreateBillingThgPdfDtoGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgPartnerCommissionDtoGen,
  ThgPartnerCommissionPerYearDtoGen,
  ThgPublishBillingsDtoGen,
  ThgThgMeterReadingViewModelGen,
  ThgUrlViewmodelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { BackwardsCompatiblePaginatedStore } from "../backwards-compatible-paginated.store";
import { BillingDataAccessLayer } from "./access-layers/billing.access-layer";
import { IThg } from "@/models/thg.entity";

@Module({
  dynamic: true,
  namespaced: true,
  name: "billing",
  store
})
export class BillingStore extends BackwardsCompatiblePaginatedStore<
  IBilling,
  MrfiktivUserControllerFindAllPaginatedParamsGen
> {
  _data = BillingDataAccessLayer;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  private _myBillings: ThgBillingViewmodelGen[] = [];

  private _readyForBilling: IThg[] | ThgThgMeterReadingViewModelGen[] = [];

  private _readyForBillingLoading = false;

  private _selectedThgsForBilling: IThg[] | ThgThgMeterReadingViewModelGen[] = [];

  private _createdBillings: any[] = [];

  private _createdBilling: any = {};

  private _billingPdf = "";

  private _billingAccounting = "";

  get myBillings(): ThgBillingViewmodelGen[] {
    return this._myBillings;
  }

  get readyForBilling(): IThg[] | ThgThgMeterReadingViewModelGen[] {
    return this._readyForBilling;
  }

  get readyForBillingLoading(): boolean {
    return this._readyForBillingLoading;
  }

  get selectedThgsForBilling(): IThg[] | ThgThgMeterReadingViewModelGen[] {
    return this._selectedThgsForBilling;
  }

  get createdBillings(): any[] {
    return this._createdBillings;
  }

  get createdBilling(): any {
    return this._createdBilling;
  }

  get billingPdf(): string {
    return this._billingPdf;
  }

  get billingAccounting(): any {
    return this._billingAccounting;
  }

  get allBillings(): any[] {
    return this._paginationList;
  }

  @Mutation
  mutateMyBillings(myBillings: ThgBillingViewmodelGen[]): void {
    this._myBillings = myBillings;
  }

  @Mutation
  mutateReadyForBilling(readyForBilling: IThg[] | ThgThgMeterReadingViewModelGen[]) {
    this._readyForBilling = readyForBilling;
  }

  @Mutation
  mutateReadyForBillingLoading(readyForBillingLoading: boolean) {
    this._readyForBillingLoading = readyForBillingLoading;
  }

  @Mutation
  mutateSelectedThgsForBilling(selectedThgsForBilling: IThg[]) {
    this._selectedThgsForBilling = selectedThgsForBilling;
  }

  @Mutation
  mutateCreatedBillings(createdBillings: any[]) {
    this._createdBillings = createdBillings;
  }

  @Mutation
  mutateCreatedBilling(createdBilling: any) {
    this._createdBilling = createdBilling;
  }

  @Mutation
  mutateBillingPdf(billingPdf: string) {
    this._billingPdf = billingPdf;
  }

  @Mutation
  mutateBillingAccounting(billingAccounting: any) {
    this._billingAccounting = billingAccounting;
  }

  @Mutation
  mutateAllBillings(allBillings: any[]) {
    this._paginationList = allBillings;
  }

  @Action
  async setMyBillings(): Promise<void> {
    const myBillings = await billingService.getMyBillings();
    this.context.commit("mutateMyBillings", myBillings);
  }

  @Action
  async getReadyForBilling(data: { billingType: BillingTypeEnum; from: string; to: string }): Promise<void> {
    this.context.commit("mutateReadyForBillingLoading", true);
    const readyForBilling = await billingService.getThgsReadyForBilling(data.billingType, data.from, data.to);
    this.context.commit("mutateReadyForBilling", readyForBilling);
    this.context.commit("mutateReadyForBillingLoading", false);
  }

  @Action
  async setReadyForBilling(readyForBilling: IThg[]): Promise<void> {
    this.context.commit("mutateReadyForBillingLoading", true);
    this.context.commit("mutateReadyForBilling", readyForBilling);
    this.context.commit("mutateReadyForBillingLoading", false);
  }

  @Action
  async setReadyForBillingLoading(loaded: boolean): Promise<void> {
    this.context.commit("mutateReadyForBillingLoading", loaded);
  }

  @Action
  setSelectedThgsForBilling(selectedThgsForBilling: IThg[] | ThgThgMeterReadingViewModelGen[]): void {
    this.context.commit("mutateSelectedThgsForBilling", selectedThgsForBilling);
  }

  @Action
  setCreatedBillings(createdBillings: any[]): void {
    this.context.commit("mutateCreatedBillings", createdBillings);
  }

  @Action
  addToCreatedBillings(data: { index: number; createdBilling: any }) {
    const createdBillings = this.createdBillings;
    createdBillings.splice(data.index, 1, data.createdBilling);
    this.context.commit("mutateCreatedBillings", createdBillings);
  }

  @Action
  async createBillingByThgId(data: { thgId: string; billingType: BillingTypeEnum }): Promise<void> {
    const created = await billingService.createBillingForThg(data.thgId, false, true, true, data.billingType);
    this.context.commit("mutateCreatedBilling", created);
  }

  @Action
  async forceCreateBillingByThgId(data: { thgId: string; billingType: BillingTypeEnum }): Promise<void> {
    const created = await billingService.createBillingForThg(data.thgId, true, true, true, data.billingType);
    this.context.commit("mutateCreatedBilling", created);
  }

  @Action
  async getPdf(billingNumber: number): Promise<string> {
    const billingPdf = await billingService.getPdf(billingNumber);
    this.context.commit("mutateBillingPdf", billingPdf.url);
    return billingPdf.url;
  }

  @Action
  async updateAccountingItem(data: { billingNumber: number; accountingItem: ThgAccountingRecordItemViewmodelGen[] }) {
    const accounting = await billingService.updateAccountingItem(data.billingNumber, data.accountingItem);
    this.context.commit("mutateBillingAccounting", accounting);
  }

  @Action
  async regenerateAccountingItem(data: {
    billingNumber: number;
    isBatchBilling?: boolean;
    partnerCommissions?: ThgPartnerCommissionPerYearDtoGen[];
    pricePerKwh?: number;
  }) {
    const accounting = !data.isBatchBilling
      ? await billingService.generateAccountingItem(data.billingNumber)
      : await billingBatchService.createBatchAccountingRecords(data.billingNumber, {
          partnerCommissions: data.partnerCommissions,
          pricePerKwh: data.pricePerKwh
        });
    this.context.commit("mutateBillingAccounting", accounting);
  }

  @Action
  async regeneratePdf(data: {
    billingNumber: number;
    dateString?: string;
    isBatchBilling?: boolean;
    partnerCommissions?: ThgPartnerCommissionPerYearDtoGen[];
    pricePerKwh?: number;
  }) {
    const updated = !data.isBatchBilling
      ? await billingService.generatePdf(data.billingNumber, data.dateString)
      : await billingBatchService.createBatchPdf(data.billingNumber, {
          partnerCommissions: data.partnerCommissions,
          billingDate: data.dateString,
          pricePerKwh: data.pricePerKwh
        });
    this.context.commit("mutateBillingPdf", updated.url);
  }

  @Action
  async publish(data: { billingNumber: number; publish: boolean; notifyUsers: boolean }) {
    await billingService.publish(data.billingNumber, data.publish, data.notifyUsers);
  }

  @Action
  async publishBatch(data: ThgPublishBillingsDtoGen) {
    return billingService.publishBatch(data);
  }

  @Action
  async getBilling(billingNumber: number) {
    const billing = await billingService.getBilling(billingNumber);
    this.context.commit("mutateCreatedBilling", billing);

    return billing;
  }

  @Action
  async getAllBillings() {
    const billings = (await billingService.getAllBillings()) as ThgBillingViewmodelGen[];
    billings.sort((a, b) => (a.billingNumber > b.billingNumber ? -1 : 1));
    this.context.commit("mutateAllBillings", billings);
  }

  @Action
  async dispatchBillingCsv(billingNumbers: number[]): Promise<string> {
    return (await billingService.dispatchBillingCsv(billingNumbers)).operationId;
  }

  @Action
  async dispatchCreditorCsv(accountNumbers: string[]) {
    return (await billingService.dispatchCreditorCsv(accountNumbers)).operationId;
  }

  @Action
  async dispatchCreditorCsvForBillings(billingNumbers: number[]) {
    return (await billingService.dispatchCreditorCsvByBilling(billingNumbers)).operationId;
  }

  @Action
  async getCsvAccess(operationId: string): Promise<ThgUrlViewmodelGen> {
    return billingService.getCsvByOperationId(operationId);
  }

  @Action
  async uploadPdf(data: { billingNumber: number; file: File }) {
    await billingService.uploadPdf(data.billingNumber, data.file);
  }

  @Action
  async addBillingPdfManually(data: { billingNumber: number; pdfInput: ThgBillingPdfInputGen }) {
    const created = await billingService.addBillingPdfManually(data.billingNumber, data.pdfInput);

    const billing = new Billing(created);

    BillingDataAccessLayer.set(billing);

    return billing;
  }

  @Action
  async createThgBillingCancellation(billingNumber: number): Promise<IBilling> {
    const created = await billingService.createBillingForThg(
      billingNumber.toString(),
      true,
      true,
      true,
      BillingTypeEnum.CANCELLATION
    );
    this.context.commit("mutateCreatedBilling", created);

    const billing = new Billing(created);
    BillingDataAccessLayer.set(billing);

    return billing;
  }

  @Action
  async check(): Promise<ThgOperationIdViewModelGen> {
    return billingService.check();
  }

  @Action
  async creditAutoBilling(): Promise<ThgOperationIdViewModelGen> {
    return billingService.creditAutoBilling();
  }

  @Action
  async regeneratePdfAsync(data: { billingNumber: number; data: ThgCreateBillingThgPdfDtoGen }) {
    return (await billingBatchService.dispatchBatchPdfAsync(data.billingNumber, data.data)).operationId;
  }

  @Action
  async regenerateAccountingRecordsAsync(data: { billingNumber: number; data: ThgPartnerCommissionDtoGen }) {
    return (await billingBatchService.dispatchBatchAccountingRecordsAsync(data.billingNumber, data.data)).operationId;
  }

  @Action
  async regenerateBillingAsync(data: ThgBillingBatchCreateDtoGen) {
    return (await billingBatchService.dispatchBatchBillingAsync(data)).operationId;
  }

  @Action
  async addBillingInfoToThg({ thgId, data }: { thgId: string; data: ThgBillingInfoGen }): Promise<void> {
    await billingService.addBillingInfoToThg(thgId, data);
  }

  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: ThgBillingViewmodelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = Billing.filterables;

  @Action
  protected async loadDocuments(query: MrfiktivUserControllerFindAllPaginatedParamsGen): Promise<any> {
    const res = (await billingService.getAllBillings(query)) as ThgPageViewModelGen & {
      data?: IBilling[] | undefined;
    };

    if (!res.data) {
      res.data = [];
    }

    res.data = res.data.map(r => new Billing(r));

    return res;
  }
}

export const BillingModule = getModule(BillingStore);
