/**
 * formats 1321300.13 to 1.321.300,13
 * @param num
 */
export function formatCommaEuroToDottedComma(num: number): string {
  const [euro, cent] = num
    .toFixed(2)
    .toString()
    .split(".");

  const euroDigits = euro.split("");
  const centDigits = cent.toString();

  let dottedEuro = "";
  let ctr = 0;
  euroDigits.reverse().forEach(d => {
    dottedEuro = d + dottedEuro;
    ctr++;
    if (ctr % 3 === 0 && ctr !== euroDigits.length) {
      dottedEuro = "." + dottedEuro;
    }
  });

  return dottedEuro + "," + centDigits;
}
