var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug,
      "showFooter": false,
      "hasInstructions": _vm.instruction.display,
      "instructionPictures": _vm.instruction.pictures,
      "instructionText": _vm.instruction.text,
      "instructionTitle": _vm.instruction.title
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.items, function (item, i) {
    return _c('v-sheet', {
      key: i,
      staticClass: "mb-2 py-n6",
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": _vm.activeCardStyle(item)
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      },
      on: {
        "click": function click($event) {
          _vm.selected = item;
        }
      }
    }, [_c('v-list', {
      attrs: {
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("report.thgPayoutOptionSelector.options.".concat(item, ".title"))) + " "), item === 'regular' ? _c('v-chip', {
      attrs: {
        "color": "success",
        "outlined": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("report.thgProducts.popular")) + " ")]) : _vm._e(), item === 'express' ? _c('v-chip', {
      attrs: {
        "color": "success",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("report.thgProducts.new")) + " ")]) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("report.thgPayoutOptionSelector.options.".concat(item, ".subtitle"))) + " ")])], 1), _c('v-list-item-action', [_c('v-radio', {
      attrs: {
        "value": item
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), 1), _c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.thgPayoutOptionSelector.hint")))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }