var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "px-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgQuickCheckout.thgDialogConfirm.description")) + " ")]), _vm._l(_vm.selectedYears, function (year, i) {
    return _c('v-chip', {
      key: i,
      staticClass: "mx-2 pb-0 pt-0"
    }, [_vm._v(_vm._s(year))]);
  }), _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgQuickCheckout.thgDialogConfirm.footer")) + " ")])]), _c('v-card-text', {
    staticClass: "px-0",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.thg.thgQuickCheckout.thgDialogConfirm.subtitle'))
    }
  }), _c('v-form', {
    ref: "form",
    model: {
      value: _vm.formValid,
      callback: function callback($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_c('v-checkbox', {
    ref: "reviewed",
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('report.thgClosing.confirmation'),
      "data-test-review-checkbox": ""
    },
    model: {
      value: _vm.isConfirmed,
      callback: function callback($$v) {
        _vm.isConfirmed = $$v;
      },
      expression: "isConfirmed"
    }
  }), _c('v-checkbox', {
    ref: "privacy",
    staticClass: "px-0",
    attrs: {
      "rules": [function (v) {
        return !!v || _vm.$t('report.thgClosing.readPrivacy');
      }],
      "label": _vm.$t('report.thgClosing.confirmationPrivacy'),
      "data-test-privacy-checkbox": ""
    },
    model: {
      value: _vm.isTermsAccepted,
      callback: function callback($$v) {
        _vm.isTermsAccepted = $$v;
      },
      expression: "isTermsAccepted"
    }
  })], 1), _c('v-spacer')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }