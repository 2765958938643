import { IThgVehicleClass } from "../interfaces/thg/thg-vehicle-class.interface";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";

export const thgVehicleClassMap = new Map<ThgVehicleTypes, IThgVehicleClass>([
  [
    ThgVehicleTypes.M1,
    {
      text: "M1 - PKW",
      icon: "mdi-car",
      ubaIdentifier: "PKW (M1)"
    }
  ],
  [
    ThgVehicleTypes.N1,
    {
      text: "N1 - Nutzfahrzeug",
      icon: "mdi-truck",
      ubaIdentifier: "LNF (N1)"
    }
  ],
  [
    ThgVehicleTypes.N2,
    {
      text: "SNF (N2)",
      icon: "mdi-truck",
      ubaIdentifier: "SNF (N2)"
    }
  ],
  [
    ThgVehicleTypes.N3,
    {
      text: "SNF (N3)",
      icon: "mdi-truck",
      ubaIdentifier: "SNF (N3)"
    }
  ],
  [
    ThgVehicleTypes.M3,
    {
      text: "M3 - Bus",
      icon: "mdi-bus-side",
      ubaIdentifier: "Bus (M3)"
    }
  ],
  [
    ThgVehicleTypes.other,
    {
      text: "E-Roller und andere Klassen",
      icon: "mdi-motorbike",
      ubaIdentifier: "Andere"
    }
  ]
]);
