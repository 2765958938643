


































import LoginComponentPasswordRequirementList from "@/components/login/LoginComponentPasswordRequirementList.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    LayoutReportStep,
    LoginComponentPasswordRequirementList
  }
})
export default class ThgCustomerDetails extends ReportStepMixin implements IReportStep {
  public md = 4;
  public header: IReportHeaderData = {
    title: "report.thgCustomerDetails.title",
    description: "report.thgCustomerDetails.description"
  };

  screen = ReportScreenEnum.thgcustomerdetails;

  isFormValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get isExternalCustomer(): boolean {
    return ThgCreateModule.isExternalCustomer;
  }

  set isExternalCustomer(isExternalCustomer: boolean) {
    ThgCreateModule.setIsExternalCustomer(isExternalCustomer);
  }

  // Update the Store Section
  updateExternalCustomerNumber(e: any) {
    ThgCreateModule.setExternalCustomerNumber(e.target.value);
  }

  get companyName() {
    return ReportModule.partner.companyName;
  }

  get externalCustomerNumber(): any {
    return ThgCreateModule.externalCustomerNumber;
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get isDone() {
    if (this.isExternalCustomer) {
      return this.isFormValid;
    }
    return true;
  }
}
