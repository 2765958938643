


















import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Vue } from "vue-property-decorator";
import FileUploadMulti from "../files/FileUploadMulti.vue";

@Component({
  components: { FileUploadMulti }
})
export default class ThgRegistrationFrontUploadCard extends Vue {
  multiSteps = {
    frontLeft: {
      example: AssetEnum.registration,
      overlay: AssetEnum.registrationSchema,
      text: "report.thgRegistration.registrationFront",
      textAlt: "report.thgRegistration.registrationFrontAlt",
      filesCb: ThgCreateModule.registrationImageFront,
      handleUpload: ThgCreateModule.addRegistrationFront,
      handleDelete: ThgCreateModule.removeRegistrationFront
    }
  };

  get getRegistrationImageFront() {
    return ThgCreateModule.registrationImageFront;
  }

  instruction: IReportInstructionData = {
    title: "report.thgRegistrationFront.title",
    text: "report.thgRegistrationFront.instructionText",
    pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
    example: AssetEnum.registrationExample,
    display: true
  };
}
