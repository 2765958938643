var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": _vm.width,
      "height": _vm.height,
      "data-name": "Layer 1",
      "viewBox": _vm.viewBox
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "fill": _vm.color,
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M1097 917C1097 930.807 1086.03 942 1072.5 942 1058.97 942 1048 930.807 1048 917 1048 903.193 1058.97 892 1072.5 892 1086.03 892 1097 903.193 1097 917Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M1082.78 894.985C1092.8 904.119 1093.57 919.698 1084.49 929.784 1077.27 937.808 1065.69 940.15 1055.95 935.555 1065.95 944.724 1081.44 944 1090.56 933.939 1099.67 923.876 1098.95 908.286 1088.95 899.117 1087.11 897.434 1085.03 896.04 1082.78 894.985Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M1770 944C1770 957.807 1742.69 969 1709 969 1675.31 969 1648 957.807 1648 944 1648 930.193 1675.31 919 1709 919 1742.69 919 1770 930.193 1770 944Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M1709.13 918.985C1707.04 918.985 1704.98 919.029 1702.95 919.114 1733.63 920.387 1757.58 931.037 1757.58 943.985 1757.58 956.935 1733.63 967.583 1702.95 968.858 1704.98 968.941 1707.04 968.985 1709.13 968.985 1742.72 968.985 1769.95 957.793 1769.95 943.985 1769.95 930.179 1742.72 918.985 1709.13 918.985Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M1787.76 944.098 1482.66 944.098 1482.66 911.161C1482.66 907.75 1479.89 904.985 1476.47 904.985L1307.43 904.985C1304.01 904.985 1301.24 907.75 1301.24 911.161L1301.24 944.098 1123.95 944.098 1123.95 923.512C1123.95 920.101 1121.18 917.336 1117.76 917.336L1027.06 917.336C1023.64 917.336 1020.87 920.101 1020.87 923.512L1020.87 944.098 996.133 944.098C992.721 944.094 989.953 946.852 989.949 950.257 989.949 950.263 989.949 950.269 989.949 950.273L989.949 1355.81C989.944 1359.22 992.705 1361.98 996.117 1361.99 996.123 1361.99 996.127 1361.99 996.133 1361.99L1787.76 1361.99C1791.18 1361.99 1793.94 1359.23 1793.95 1355.83 1793.95 1355.82 1793.95 1355.82 1793.95 1355.81L1793.95 950.273C1793.95 946.869 1791.19 944.102 1787.78 944.098 1787.77 944.098 1787.77 944.098 1787.76 944.098Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M1787.76 1332.16 996.133 1332.16C992.723 1332.17 989.955 1329.41 989.949 1326 989.949 1326 989.949 1325.99 989.949 1325.99L989.949 1354.81C989.944 1358.22 992.707 1360.98 996.117 1360.99 996.123 1360.99 996.129 1360.99 996.133 1360.99L1787.76 1360.99C1791.18 1360.99 1793.94 1358.23 1793.95 1354.83 1793.95 1354.82 1793.95 1354.81 1793.95 1354.81L1793.95 1325.99C1793.95 1329.39 1791.19 1332.16 1787.78 1332.16 1787.78 1332.16 1787.77 1332.16 1787.76 1332.16Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M990 1134 1794 1134 1794 1212 990 1212Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M990 1094 1794 1094 1794 1173 990 1173Z"
    }
  }), _c('path', {
    attrs: {
      "fill": _vm.color,
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M990 1113 1794 1113 1794 1191 990 1191Z"
    }
  }), _c('path', {
    attrs: {
      "fill": _vm.color,
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M1549 1152C1549 1238.71 1478.71 1309 1392 1309 1305.29 1309 1235 1238.71 1235 1152 1235 1065.29 1305.29 995 1392 995 1478.71 995 1549 1065.29 1549 1152Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M1512.91 1051.98C1556.73 1126.72 1531.73 1222.87 1457.06 1266.73 1390.74 1305.69 1305.98 1290.75 1256.95 1231.46 1300.77 1306.2 1396.83 1331.24 1471.5 1287.38 1546.17 1243.52 1571.18 1147.38 1527.36 1072.64 1523.11 1065.38 1518.27 1058.47 1512.91 1051.98Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M1509 1152C1509 1216.62 1456.84 1269 1392.5 1269 1328.16 1269 1276 1216.62 1276 1152 1276 1087.38 1328.16 1035 1392.5 1035 1456.84 1035 1509 1087.38 1509 1152Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#FFF",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M1482.95 1151.98C1482.97 1164.33 1480.45 1176.56 1475.57 1187.9 1472.95 1194.01 1469.65 1199.82 1465.74 1205.2 1436.36 1245.96 1379.5 1255.18 1338.74 1225.8 1297.97 1196.43 1288.75 1139.57 1318.13 1098.82 1323.85 1090.89 1330.8 1083.93 1338.74 1078.21 1344.12 1074.3 1349.93 1071.01 1356.05 1068.39 1402.22 1048.56 1455.73 1069.92 1475.56 1116.09 1480.43 1127.43 1482.95 1139.64 1482.95 1151.98Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#000",
      "fill-opacity": ".2",
      "fill-rule": "evenodd",
      "d": "M1481.98 1139.18C1478.6 1116.15 1466.47 1095.3 1448.11 1080.99 1464.26 1106.27 1466.85 1137.92 1455 1165.49 1452.4 1171.58 1449.11 1177.36 1445.22 1182.72 1415.97 1223.28 1359.37 1232.46 1318.8 1203.22 1317.83 1202.53 1316.88 1201.81 1315.95 1201.08 1342.83 1243.26 1398.81 1255.66 1441 1228.79 1450.68 1222.63 1459.1 1214.68 1465.81 1205.37 1469.71 1200.01 1472.99 1194.23 1475.6 1188.14 1482.22 1172.72 1484.43 1155.77 1481.98 1139.18Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#E6E6E6",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2203.06-79.5246C2201.37-100.27 2216.82-118.457 2237.56-120.147L3935.04-258.379C3955.79-260.068 3973.98-244.62 3975.67-223.875L4068.25 913.098C4069.94 933.843 4054.5 952.03 4033.75 953.72L2336.27 1091.95C2315.52 1093.64 2297.34 1078.19 2295.65 1057.45Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2296.73-63.5378C2297.91-49.0351 2287.1-36.3201 2272.59-35.1386 2258.08-33.957 2245.36-44.7563 2244.18-59.2591 2243-73.7619 2253.81-86.4767 2268.32-87.6583 2282.83-88.8398 2295.55-78.0406 2296.73-63.5378Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2385.82 1030.56C2387 1045.06 2376.2 1057.77 2361.69 1058.96 2347.18 1060.14 2334.46 1049.34 2333.28 1034.84 2332.1 1020.33 2342.9 1007.62 2357.41 1006.44 2371.92 1005.25 2384.64 1016.05 2385.82 1030.56Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#FFF",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "stroke": "#CACACA",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "6.875",
      "d": "M2303.92 24.8002C2303.28 17.0076 2309.09 10.176 2316.88 9.54141L3879.14-117.679C3886.94-118.314 3893.77-112.511 3894.4-104.719L3958.36 680.747C3959 688.539 3953.2 695.371 3945.4 696.005L2383.14 823.226C2375.35 823.861 2368.52 818.058 2367.88 810.265Z"
    }
  }), _c('path', {
    attrs: {
      "fill": _vm.color,
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2522.07 155.556C2520.46 135.784 2548.3 117.381 2584.25 114.454 2620.2 111.526 2650.65 125.182 2652.26 144.955 2653.87 164.728 2626.03 183.13 2590.08 186.058 2554.13 188.985 2523.68 175.329 2522.07 155.556Z"
    }
  }), _c('text', {
    attrs: {
      "fill": "#FFF",
      "fill-opacity": "1",
      "direction": "ltr",
      "font-family": "Calibri,Calibri_MSFontService,sans-serif",
      "font-size": "55",
      "font-stretch": "normal",
      "font-style": "normal",
      "font-variant": "normal",
      "font-weight": "400",
      "text-anchor": "start",
      "text-decoration": "none",
      "transform": "matrix(0.996701 -0.081165 0.081165 0.996701 2571.78 170)",
      "unicode-bidi": "normal",
      "writing-mode": "lr-tb"
    }
  }, [_vm._v(" D ")]), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2853.8 71.7523 2904.69 696.662"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3366.96 29.9631 3417.85 654.873"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "nonzero",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2388.76 235.214 2796.69 201.995 2806.94 327.846 2399.01 361.065Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2405.02 434.839 2812.95 401.62"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2409.47 489.518 2817.4 456.3"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2413.93 544.197 2821.85 510.979"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2418.38 598.877 2826.4 565.651"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2422.83 653.557 2830.85 620.33"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2427.28 708.236 2835.31 675.009"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2376.21 82.2488 2784.23 49.0225"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2382.57 160.363 2456.9 154.311"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2716.28 133.189 2790.6 127.137"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2902.17 115.868 3310.09 82.6489"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2906.62 170.547 3314.55 137.328"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2911.07 225.226 3319 192.008"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2915.52 279.906 3323.54 246.68"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2919.98 334.586 3328 301.359"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2924.43 389.265 3332.45 356.038"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2929.05 447.207 3336.98 413.988"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2933.51 501.886 3341.43 468.668"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2937.96 556.566 3345.98 523.339"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2942.41 611.245 3350.43 578.019"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2946.86 665.925 3354.89 632.698"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3428.52 75.1878 3836.45 41.9691"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3432.98 129.867 3840.9 96.6486"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3437.43 184.547 3845.36 151.328"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3441.88 239.226 3849.9 206"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3446.34 293.906 3854.36 260.679"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3450.79 348.585 3858.81 315.359"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3455.41 406.527 3863.34 373.308"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3459.87 461.207 3867.79 427.988"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3464.32 515.886 3872.34 482.66"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3468.77 570.565 3876.79 537.339"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3473.22 625.245 3881.24 592.019"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M4028.85 896.759C4030.03 911.262 4019.22 923.977 4004.71 925.159 3990.2 926.34 3977.49 915.541 3976.3 901.038 3975.12 886.535 3985.93 873.82 4000.44 872.639 4014.95 871.457 4027.67 882.257 4028.85 896.759Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M3939.57-199.612C3940.75-185.109 3929.94-172.394 3915.43-171.212 3900.92-170.031 3888.2-180.83 3887.02-195.333 3885.84-209.836 3896.65-222.55 3911.16-223.732 3925.66-224.914 3938.38-214.114 3939.57-199.612Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#E6E6E6",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2305.05 1192.47C2306.86 1171.73 2325.14 1156.39 2345.87 1158.2L4042.54 1306.09C4063.27 1307.89 4078.62 1326.17 4076.81 1346.9L3977.75 2483.33C3975.95 2504.07 3957.67 2519.41 3936.94 2517.6L2240.27 2369.71C2219.53 2367.91 2204.19 2349.63 2206 2328.9Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2394.72 1223.91C2393.46 1238.41 2380.68 1249.13 2366.18 1247.87 2351.67 1246.6 2340.94 1233.83 2342.2 1219.33 2343.47 1204.84 2356.25 1194.11 2370.75 1195.37 2385.25 1196.64 2395.99 1209.41 2394.72 1223.91Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2299.4 2317.48C2298.14 2331.98 2285.36 2342.7 2270.85 2341.44 2256.35 2340.17 2245.62 2327.4 2246.88 2312.9 2248.15 2298.41 2260.93 2287.68 2275.43 2288.94 2289.93 2290.21 2300.66 2302.98 2299.4 2317.48Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M3941.64 2460.63C3940.37 2475.12 3927.59 2485.85 3913.09 2484.58 3898.59 2483.32 3887.86 2470.54 3889.12 2456.05 3890.38 2441.55 3903.16 2430.82 3917.67 2432.09 3932.17 2433.35 3942.9 2446.13 3941.64 2460.63Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#3F3D56",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M4037.16 1364.78C4035.89 1379.28 4023.11 1390 4008.61 1388.74 3994.11 1387.47 3983.38 1374.7 3984.64 1360.2 3985.9 1345.71 3998.68 1334.98 4013.19 1336.24 4027.69 1337.51 4038.42 1350.28 4037.16 1364.78Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#FFF",
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "stroke": "#CACACA",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "6.875",
      "d": "M2387.02 1312.21C2387.7 1304.42 2394.57 1298.65 2402.36 1299.33L3963.87 1435.44C3971.66 1436.12 3977.42 1442.98 3976.74 1450.77L3908.31 2235.86C3907.63 2243.65 3900.77 2249.41 3892.98 2248.74L2331.47 2112.63C2323.68 2111.95 2317.91 2105.08 2318.59 2097.29Z"
    }
  }), _c('path', {
    attrs: {
      "fill": _vm.color,
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2372.28 1967.52C2376.33 1921.05 2417.28 1886.67 2463.75 1890.72 2510.21 1894.77 2544.59 1935.72 2540.54 1982.18 2536.49 2028.65 2495.54 2063.03 2449.08 2058.98 2402.62 2054.93 2368.23 2013.98 2372.28 1967.52Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2921.28 1450.55 2866.84 2075.16"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3434.2 1495.26 3379.76 2119.87"
    }
  }), _c('path', {
    attrs: {
      "fill": "#E6E6E6",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2593.23 1753.39 2847.85 1775.58 2838.06 1887.89 2583.44 1865.69Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2573.38 1912.13 2832.24 1934.69"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2568.61 1966.78 2827.47 1989.34"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2563.85 2021.43 2654.45 2029.33"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2961.58 1502.14 3369.31 1537.68"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2956.82 1556.79 3364.55 1592.33"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2952.06 1611.45 3359.79 1646.99"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2947.29 1666.1 3355.12 1701.65"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2942.53 1720.75 3350.35 1756.3"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2937.76 1775.41 3345.59 1810.95"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2932.62 1833.3 3340.35 1868.84"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2927.86 1887.96 3335.59 1923.5"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2923.09 1942.61 3330.92 1978.16"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2918.33 1997.26 3326.16 2032.81"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M2913.57 2051.92 3321.39 2087.46"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3487.32 1550.15 3895.06 1585.69"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3482.56 1604.8 3890.29 1640.34"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3477.8 1659.46 3885.53 1695"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3473.03 1714.11 3880.86 1749.66"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3468.27 1768.76 3876.09 1804.31"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3463.5 1823.42 3871.33 1858.96"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3458.36 1881.31 3866.1 1916.85"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3453.6 1935.97 3861.33 1971.51"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3448.84 1990.62 3856.66 2026.17"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3444.07 2045.27 3851.9 2080.82"
    }
  }), _c('path', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "#E4E4E4",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "stroke-miterlimit": "8",
      "stroke-opacity": "1",
      "stroke-width": "20.625",
      "d": "M3439.31 2099.93 3847.13 2135.48"
    }
  }), _c('path', {
    attrs: {
      "fill": _vm.color,
      "fill-opacity": "1",
      "fill-rule": "evenodd",
      "d": "M2603.98 1776.9C2604.8 1767.58 2613.01 1760.68 2622.33 1761.5L2817.78 1778.53C2827.1 1779.34 2834 1787.56 2833.19 1796.88L2827.3 1864.37C2826.49 1873.69 2818.28 1880.59 2808.96 1879.78L2613.5 1862.74C2604.18 1861.93 2597.29 1853.71 2598.1 1844.4Z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }