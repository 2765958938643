






























































import Notifications from "@/components/thg/Notifications.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/I18nPlugin";

@Component({
  components: {
    LayoutReport,
    Debug,
    Notifications
  }
})
export default class ThgSuccess extends Vue {
  verificationCode = "";
  isLoading = false;
  valid = false;

  get textFieldLabel(): string {
    return String(i18n.tc("report.thgSuccess.textFieldLabel"));
  }

  get isAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  get description() {
    if (!this.isAuthenticated) {
      return String(i18n.tc("report.thgSuccess.description")) + ThgCreateModule.contact.email;
    } else {
      return String(i18n.tc("report.thgSuccess.descriptionAlt"));
    }
  }

  get partner() {
    return ReportModule.partner;
  }

  get title() {
    if (!this.isAuthenticated) {
      return String(i18n.tc("report.thgSuccess.title")) + ThgCreateModule.user.firstName + "!";
    } else {
      return String(i18n.tc("report.thgSuccess.titleAlt"));
    }
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  newCar() {
    this.$router.push({ path: "/" });
  }

  goToVehicle() {
    this.$router.push({ path: "/vehicle" });
  }

  async submit() {
    if (this.form.validate()) {
      this.isLoading = true;
      try {
        const verificationCodeWithoutWhitespace = this.verificationCode.trim();
        await ThgCreateModule.verifyEmail(verificationCodeWithoutWhitespace);
        try {
          await UserModule.logIn({ username: ThgCreateModule.contact.email, password: ThgCreateModule.password });
          this.$router.push({ name: "ThgOverview" });
        } catch {
          this.$router.push({ path: "/login" });
        }
      } catch (error) {
        this.$log.error(error);
        this.$router.push({ path: "/login/confirmmail" });
        //TODO: Link to verify mail page
        this.$toast.error(String(i18n.tc("report.thgSuccess.errorMsg")));
      } finally {
        ThgCreateModule.resetThg();
        this.isLoading = false;
      }
    }
  }
}
