






















































// import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Vue } from "vue-property-decorator";
import ThgQuoteExportDialog from "./ThgQuoteExportDialog.vue";
import ThgQuoteVehicleUpdateRegistrationDialog from "./ThgQuoteVehicleUpdateRegistrationDialog.vue";
import ThgQuoteUpdateMyRegistrationImageDialog from "./ThgQuoteUpdateMyRegistrationImageDialog.vue";
import { BillingModule } from "@/store/modules/billing.store";
import FileDownload from "../utility/FileDownload.vue";
import { ThgBillingViewmodelGen, ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { IDownloadFile } from "../utility/interface/download-file.interface";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { ThgModule } from "@/store/modules/thg.store";

@Component({
  components: {
    ThgQuoteExportDialog,
    ThgQuoteVehicleUpdateRegistrationDialog,
    ThgQuoteUpdateMyRegistrationImageDialog,
    FileDownload,
    LatestEntriesCardEmpty
  }
})
export default class ThgBillingOverviewCard extends Vue {
  search = "";
  selection: ThgBillingViewmodelGen[] = [];
  downLoading = false;

  partnerColor = new PartnerColor();

  /**
   * Thg Id on year
   */
  thgYearMap: Map<string, number> = new Map();

  /**
   * Internationalization for billing types
   */
  get i18nBilling() {
    return this.$t("components.thg.thgBillingCreateNewBillingManual");
  }

  goToBanking() {
    this.$router.push({ name: "ProfileTab", params: { tab: "accountinformation" } });
  }

  get selectedFiles(): IDownloadFile[] {
    return this.selection.map((billing: ThgBillingViewmodelGen) => {
      return {
        filename: `${billing.billingNumber}.pdf`,
        url: billing.pdfBilling[0] as any
      };
    });
  }

  get myBillings() {
    return BillingModule.myBillings;
  }

  get headers() {
    return [
      { text: this.$t("components.thg.ThgBillingOverviewCard.timestamp"), value: "timestamp.created" },
      { text: this.$t("components.thg.ThgBillingOverviewCard.billingNumber"), value: "billingNumber" },
      { text: this.$t("components.thg.ThgBillingOverviewCard.type"), value: "type" },
      { text: this.$t("components.thg.ThgBillingOverviewCard.thgYear"), value: "thgId", sort: this.sortYear }
    ];
  }

  get thgs(): ThgThgMeViewModelGen[] {
    return ThgModule.myThgs;
  }

  async mounted() {
    for (const thg of this.thgs) {
      if (thg.year) {
        this.thgYearMap.set(thg.id, thg.year);
      }
    }
  }

  sortYear(thgIdA: string, thgIdB: string): number {
    const yearA = this.thgYearMap.get(thgIdA) || 0;
    const yearB = this.thgYearMap.get(thgIdB) || 0;

    return yearA - yearB;
  }

  filterAll(value: any, search: string, item: ThgBillingViewmodelGen) {
    return JSON.stringify(item)
      .toLowerCase()
      .includes(search.trim());
  }

  async downloadBilling(billing: ThgBillingViewmodelGen) {
    let allDownloaded = true;
    try {
      for (const pdf of billing.pdfBilling) {
        const url = (pdf as any).url;
        if (!url || !(await downloadUrlFromRemoteOrigin(url, `${billing.billingNumber}.pdf`))) {
          allDownloaded = false;
        }
      }
    } catch (e) {
      allDownloaded = false;
      Vue.$toast.error(`${billing.billingNumber}: ${(e as any).message}`);
    }

    return allDownloaded;
  }

  async download() {
    this.downLoading = true;
    let allDownloaded = true;
    for (const billing of this.selection) {
      const downloaded = await this.downloadBilling(billing);

      if (!downloaded) {
        allDownloaded = downloaded;
      }
    }

    if (allDownloaded) {
      Vue.$toast.success(this.$t("components.thg.ThgBillingOverviewCard.downloadSuccess"));
    } else {
      Vue.$toast.error(this.$t("components.thg.ThgBillingOverviewCard.downloadError"));
    }
    this.downLoading = false;
  }
}
