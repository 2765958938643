

















import ThgCheckoutSustainableCard from "@/components/thg/ThgCheckoutSustainableCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "./enum/impact-type.enum";

@Component({
  components: {
    LayoutReportStep,
    ThgCheckoutSustainableCard
  }
})
export default class ThgSustainable extends ReportStepMixin implements IReportStep {
  screen = ReportScreenEnum.thgSustainable;

  isLoading = false;

  public header: IReportHeaderData = {
    title: "report.thgImpactSelector.title",
    description: "report.thgImpactSelector.description"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgImpactSelector.instructionTitle",
    text: "report.thgImpactSelector.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  mounted() {
    ThgCreateModule.setActiveImpactTypeItems([ImpactTypeEnum.payout, ImpactTypeEnum.charging, ImpactTypeEnum.trees]);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return true;
  }
}
