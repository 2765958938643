import { IThgVehicleClass } from "../interfaces/thg/thg-vehicle-class.interface";

/**
 * Maps a drive type to text and icon
 * @see https://de.wikipedia.org/wiki/Kraftstoffcode#Liste
 */
export const driveTypeMap = new Map<string, IThgVehicleClass>([
  ["0000", { text: "Unbekannt", icon: "mdi-car" }],
  ["0001", { text: "Benzin", icon: "mdi-car" }],
  ["0002", { text: "Diesel", icon: "mdi-car" }],
  ["0003", { text: "Vielstoff", icon: "mdi-car" }],
  ["0004", { text: "reines Elektrofahrzeug", icon: "mdi-car-electric" }],
  ["0005", { text: "Flüssiggas (LPG)", icon: "mdi-car" }],
  ["0006", { text: "bivalenter Betrieb mit Benzin oder Flüssiggas (LPG)", icon: "mdi-car" }],
  ["0007", { text: "bivalenter Betrieb mit Benzin oder Erdgas (CNG)", icon: "mdi-car" }],
  ["0008", { text: "kombinierter Betrieb mit Benzin und Elektromotor", icon: "mdi-hybrid" }],
  ["0009", { text: "Erdgas (NG)", icon: "mdi-car" }],
  ["0010", { text: "kombinierter Betrieb mit Diesel und Elektromotor", icon: "mdi-hybrid" }],
  ["0011", { text: "Wasserstoff", icon: "mdi-car" }],
  ["0012", { text: "kombinierter Betrieb mit Wasserstoff und Elektromotor", icon: "mdi-hybrid" }],
  ["0013", { text: "bivalenter Betrieb mit Wasserstoff oder Benzin", icon: "mdi-car" }],
  ["0014", { text: "bivalenter Betrieb mit Wasserstoff oder Benzin kombiniert mit Elektromotor", icon: "mdi-hybrid" }],
  ["0015", { text: "Brennstoffzelle mit Primärenergie Wasserstoff", icon: "mdi-car" }],
  ["0016", { text: "Brennstoffzelle mit Primärenergie Benzin", icon: "mdi-car" }],
  ["0017", { text: "Brennstoffzelle mit Primärenergie Methanol", icon: "mdi-car" }],
  ["0018", { text: "Brennstoffzelle mit Primärenergie Ethanol", icon: "mdi-car" }],
  ["0019", { text: "kombinierter Betrieb mit Vielstoff- und Elektromotor", icon: "mdi-hybrid" }],
  ["0020", { text: "Methan (Biogas)", icon: "mdi-car" }],
  ["0021", { text: "bivalenter Betrieb mit Benzin oder Methan", icon: "mdi-car" }],
  ["0022", { text: "kombinierter Betrieb mit Erdgas und Elektromotor", icon: "mdi-hybrid" }],
  ["0023", { text: "Benzin/Ethanol (E85)", icon: "mdi-car" }],
  ["0024", { text: "kombinierter Betrieb mit Flüssiggas (LPG) und Elektromotor", icon: "mdi-hybrid" }],
  [
    "0025",
    {
      text: "Hybridantrieb mit Benzin und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  [
    "0026",
    {
      text: "Hybridantrieb mit Diesel und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  [
    "0027",
    {
      text: "Hybridantrieb mit Flüssiggas (LPG) und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  [
    "0028",
    {
      text: "Hybridantrieb mit Wasserstoff und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  [
    "0029",
    {
      text: "Hybridantrieb mit Vielstoff und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  [
    "0030",
    {
      text: "Hybridantrieb mit Erdgas (NG) und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  [
    "0031",
    {
      text:
        "Hybridantrieb mit bivalentem Betrieb mit Wasserstoff oder Benzin und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  ["0032", { text: "Wasserstoff/Erdgas (HCNG)", icon: "mdi-car" }],
  [
    "0033",
    {
      text: "Hybridantrieb mit Wasserstoff/Erdgas und extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  ["0034", { text: "Ethanol (E95)", icon: "mdi-car" }],
  [
    "0035",
    { text: "Hybridantrieb mit Brennstoffzelle (Elektromotor) und Wasserstoff (Verbrennungsmotor)", icon: "mdi-hybrid" }
  ],
  [
    "0036",
    {
      text:
        "Hybridantrieb mit Brennstoffzelle (Elektromotor) und Wasserstoff (Verbrennungsmotor) mit extern aufladbarem elektrischen Speicher (Plug-in-Hybrid)",
      icon: "mdi-car-electric"
    }
  ],
  ["0037", { text: "Zweistoffbetrieb mit verflüssigtem Erdgas (LNG) und Diesel", icon: "mdi-car" }],
  ["0038", { text: "Verflüssigtes Erdgas (LNG)", icon: "mdi-car" }],
  ["9999", { text: "Andere", icon: "mdi-car" }]
]);
