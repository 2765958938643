var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [!_vm.bigButton ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.isLoading,
            "icon": ""
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-upload")])], 1) : _c('div', [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.isLoading,
            "dense": "",
            "small": "",
            "flat": "",
            "depressed": "",
            "color": "warning"
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_vm._v(_vm._s(_vm.$t(_vm.btnTextAlt)))])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [!_vm.isLoading ? _c('v-card', [_c('v-card-title', [_c('span', [_vm._v(_vm._s(_vm.$t(_vm.title)))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t(_vm.text)) + " ")]), _c('div', {
    staticClass: "mx-6 my-6"
  }, [_c('file-upload-multi', {
    attrs: {
      "instruction": _vm.instruction,
      "snackbarTitle": _vm.multiSteps.frontLeft.text,
      "example": _vm.multiSteps.frontLeft.example,
      "overlay": _vm.multiSteps.frontLeft.overlay,
      "text": _vm.multiSteps.frontLeft.text,
      "textAlt": _vm.multiSteps.frontLeft.textAlt,
      "filesCb": _vm.multiSteps.frontLeft.filesCb,
      "displayImages": _vm.getRegistrationImageFront,
      "handleDeleteCb": _vm.multiSteps.frontLeft.handleDelete,
      "handleUploadCb": _vm.multiSteps.frontLeft.handleUpload,
      "data-test-registration-front": ""
    }
  }), _c('file-upload-multi', {
    attrs: {
      "instruction": _vm.instruction,
      "snackbarTitle": _vm.multiSteps.rearLeft.text,
      "example": _vm.multiSteps.rearLeft.example,
      "overlay": _vm.multiSteps.rearLeft.overlay,
      "text": _vm.multiSteps.rearLeft.text,
      "textAlt": _vm.multiSteps.rearLeft.textAlt,
      "filesCb": _vm.multiSteps.rearLeft.filesCb,
      "displayImages": _vm.getRegistrationImageBack,
      "handleDeleteCb": _vm.multiSteps.rearLeft.handleDelete,
      "handleUploadCb": _vm.multiSteps.rearLeft.handleUpload,
      "data-test-registration-back": ""
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "mx-4 mb-4",
    attrs: {
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.btnText)) + " ")])], 1)], 1) : _vm._e(), _c('v-card', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, article, actions"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }