import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { BillingBatch } from "../v1/BillingBatch";
import {
  ThgBillingBatchAutoCreationDtoGen,
  ThgBillingBatchControllerCreateBillingDocumentParamsGen,
  ThgBillingBatchCreateDtoGen,
  ThgBillingListDtoGen,
  ThgCreateBillingThgPdfDtoGen,
  ThgNamedUrlViewModelGen,
  ThgOperationIdViewModelGen,
  ThgPartnerCommissionDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Communicates with the billing batch api
 */
export class BillingBatchService {
  /**
   * The HttpClient containing the Axios Instance
   */
  private client: HttpClient;

  /**
   * The proxy for billing batches
   */
  private billingBatchProxy: BillingBatch;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.billingBatchProxy = new BillingBatch(this.client);
  }

  /**
   * create a batch of billings
   *
   * @param query
   * @param data
   * @returns
   */
  async createBatchBilling(
    query: ThgBillingBatchControllerCreateBillingDocumentParamsGen,
    data: ThgBillingBatchCreateDtoGen
  ) {
    return (await this.billingBatchProxy.billingBatchControllerCreateBillingDocument(query, data)).data;
  }

  /**
   * create a batch of billings asynchronous
   *
   * @param query
   * @param data
   * @returns
   */
  async dispatchBatchBillingAsync(data: ThgBillingBatchCreateDtoGen) {
    return (await this.billingBatchProxy.billingBatchControllerCreateBillingBatchAsync(data)).data;
  }

  /**
   * Create accounting records
   *
   * @param billingNumber
   * @param data
   * @returns
   */
  async createBatchAccountingRecords(billingNumber: number, data: ThgPartnerCommissionDtoGen) {
    return (await this.billingBatchProxy.billingBatchControllerCreateBillingAccountingRecords(billingNumber, data))
      .data;
  }

  /**
   * Create accounting records async
   *
   * @param billingNumber
   * @param data
   * @returns
   */
  async dispatchBatchAccountingRecordsAsync(billingNumber: number, data: ThgPartnerCommissionDtoGen) {
    return (await this.billingBatchProxy.billingBatchControllerCreateBillingAccountingRecordsAsync(billingNumber, data))
      .data;
  }

  /**
   * Create pdf batch
   *
   * @param billingNumber
   * @param data
   * @returns
   */
  async createBatchPdf(billingNumber: number, data: ThgCreateBillingThgPdfDtoGen) {
    return (await this.billingBatchProxy.billingBatchControllerCreateBillingPdf(billingNumber, data)).data;
  }

  /**
   * Create pdf batch
   *
   * @param billingNumber
   * @param data
   * @returns
   */
  async dispatchBatchPdfAsync(billingNumber: number, data: ThgCreateBillingThgPdfDtoGen) {
    return (await this.billingBatchProxy.billingBatchControllerCreateBillingPdfAsync(billingNumber, data)).data;
  }

  /**
   * Create CSV files for billings
   *
   * @param data
   * @returns
   */
  async dispatchBillingCsv(data: ThgBillingListDtoGen): Promise<ThgOperationIdViewModelGen> {
    return (await this.billingBatchProxy.billingBatchControllerDispatchBillingCsv(data)).data;
  }

  /**
   * Create billings automatically
   *
   * @param data
   * @returns
   */
  async dispatchAutoBill(data: ThgBillingBatchAutoCreationDtoGen): Promise<ThgOperationIdViewModelGen> {
    return (await this.billingBatchProxy.billingBatchControllerDispatchAutoBilling(data)).data;
  }

  /**
   * Get Billing CSVs
   *
   * @param operationId
   * @returns
   */
  async getBillingCSVs(operationId: string): Promise<ThgNamedUrlViewModelGen[]> {
    return (await this.billingBatchProxy.billingBatchControllerAccessCsvFromOperation(operationId)).data;
  }
}

export default new BillingBatchService(new ThgHttpClientProvider());
