import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { Billing } from "../v1/Billing";
import { BillingThg } from "../v1/BillingThg";
import {
  ThgAccountingRecordItemViewmodelGen,
  ThgBillingControllerGetAllBillingsParamsGen,
  ThgBillingControllerGetPartnerParamsGen,
  ThgBillingInfoGen,
  ThgBillingListDtoGen,
  ThgBillingPartnerViewModelGen,
  ThgBillingPdfDtoGen,
  ThgBillingPdfInputGen,
  ThgBillingThgControllerCreateBillingDocumentParamsGen,
  ThgBillingThgControllerGetAllThgsParamsGen,
  ThgBillingViewmodelGen,
  ThgCreateBillingThgDtoGen,
  ThgCreateBillingThgSettingsDtoGen,
  ThgCreditorListDtoGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgPublishBillingsDtoGen,
  ThgThgMeterReadingViewModelGen,
  ThgUpdateAccountingRecordsDtoGen,
  ThgUrlViewmodelGen,
  ThgThgViewModelGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Communicates with the billing module
 */
export class BillingService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for the billing.
   */
  billingProxy: Billing;

  /**
   * The proxy for the thg billing.
   */
  billingThgProxy: BillingThg;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.billingProxy = new Billing(this.client);
    this.billingThgProxy = new BillingThg(this.client);
  }

  /**
   * (ADMIN) Returns all thgs that are ready to be billed (where status is confirmed by the thg and the billing is not created)
   *
   * @param from lower time limit
   * @param to upper time limit
   * @returns list of thgs
   */
  async getThgsReadyForBilling(
    billingType: BillingTypeEnum,
    from?: string,
    to?: string
  ): Promise<ThgThgViewModelGen[] | ThgThgMeterReadingViewModelGen[]> {
    if (!from) {
      const date = new Date();
      date.setFullYear(2000);
      from = date.toISOString();
    }
    if (!to) {
      const date = new Date();
      date.setFullYear(2100);
      to = date.toISOString();
    }
    const thgBillingThgControllerGetAllThgsParamsGen: ThgBillingThgControllerGetAllThgsParamsGen = {
      from: from,
      to: to,
      billingType: billingType
    };

    return (await this.billingThgProxy.billingThgControllerGetAllThgs(thgBillingThgControllerGetAllThgsParamsGen)).data;
  }

  async addBillingInfoToThg(thgId: string, data: ThgBillingInfoGen): Promise<ThgThgViewModelGen> {
    return (await this.billingThgProxy.billingThgControllerAddBillingInfoToThg(thgId, data)).data;
  }

  /**
   * Creates a billing for a thg
   *
   * @param thgId the id of the thg
   * @param force if true, the billing will be created even if there is already a billing for the thg
   * @param createPdf whether to create a pdf for the billing
   * @param createAccountingRecord whether to create an accounting record for the billing
   * @returns
   */
  async createBillingForThg(
    thgId: string,
    force: boolean,
    createPdf: boolean,
    createAccountingRecord: boolean,
    billingType: BillingTypeEnum
  ): Promise<ThgBillingViewmodelGen> {
    const thgBillingThgControllerCreatesBillingDocumentParamsGen: ThgBillingThgControllerCreateBillingDocumentParamsGen = {
      id: thgId,
      force: force
    };
    const thgCreateBillingThgSettingsDtoGen: ThgCreateBillingThgSettingsDtoGen = {
      createPdf: createPdf,
      createAccountingRecord: createAccountingRecord,
      billingType: billingType
    };

    return (
      await this.billingThgProxy.billingThgControllerCreateBillingDocument(
        thgBillingThgControllerCreatesBillingDocumentParamsGen,
        thgCreateBillingThgSettingsDtoGen
      )
    ).data;
  }

  /**
   * Gets an access link to a pdf of a billing
   *
   * @param billingNumber of the billing to get the pdf for
   * @returns
   */
  async getPdf(billingNumber: number): Promise<ThgUrlViewmodelGen> {
    return (await this.billingProxy.billingControllerGetBillingPdfAccess(billingNumber)).data;
  }

  /**
   * Updates the accounting records of a billing
   *
   * @param billingNumber the billing number of the billing to update
   * @param accountingItems the new acconting records
   * @returns
   */
  async updateAccountingItem(billingNumber: number, accountingItems: ThgAccountingRecordItemViewmodelGen[]) {
    const thgUpdateAccountingRecordsDtoGen: ThgUpdateAccountingRecordsDtoGen = {
      accountingRecords: accountingItems as any
    };

    return (
      await this.billingProxy.billingControllerReplaceAccountingRecords(billingNumber, thgUpdateAccountingRecordsDtoGen)
    ).data;
  }

  /**
   * Generates an accounting item for a thg
   *
   * @param billingNumber the billing number of the billing to generate the accounting item for
   * @returns
   */
  async generateAccountingItem(billingNumber: number): Promise<ThgAccountingRecordItemViewmodelGen[]> {
    return (await this.billingThgProxy.billingThgControllerCreateBillingAccountingRecords(billingNumber)).data;
  }

  /**
   * Generates a pdf for a billing
   *
   * @param billingNumber the billing number of the billing to generate the pdf for
   * @param billingDate the date the pdf is generated for
   * @returns
   */
  async generatePdf(billingNumber: number, billingDate?: string): Promise<ThgUrlViewmodelGen> {
    return (
      await this.billingThgProxy.billingThgControllerCreateBillingPdf(billingNumber, { billingDate: billingDate })
    ).data;
  }

  /**
   * Publishes a billing
   *
   * @param billingNumber the billing to publish
   * @param publish whether to set the billing to published or not published
   * @param notifyUsers whether to send a notification to users (in case of publishing)
   * @returns
   */
  async publish(billingNumber: number, publish: boolean, notifyUsers: boolean) {
    return (
      await this.billingProxy.billingControllerPublishBilling(billingNumber, {
        publish: publish,
        notifyUsers: notifyUsers
      })
    ).data;
  }

  /**
   * Publishes a batch of billings
   *
   * @param data
   * @returns
   */
  async publishBatch(data: ThgPublishBillingsDtoGen) {
    return (await this.billingProxy.billingControllerPublishBillingBatch(data)).data;
  }

  /**
   * Get a billing by its number
   *
   * @param billingNumber the number to get the billing for
   * @returns
   */
  async getBilling(billingNumber: number) {
    return (await this.billingProxy.billingControllerGetBillingByBillingNumber(billingNumber)).data;
  }

  /**
   * Get all billings
   *
   * @returns
   */
  async getAllBillings(query?: ThgBillingControllerGetAllBillingsParamsGen) {
    const q = query || {};
    return (await this.billingProxy.billingControllerGetAllBillings(q)).data;
  }

  /**
   * Starts job to get accounting csv
   *
   * @param billingNumbers
   * @returns
   */
  async dispatchBillingCsv(billingNumbers: number[]) {
    const thgBillingListDtoGen: ThgBillingListDtoGen = {
      billingNumbers: billingNumbers
    };

    return (await this.billingProxy.billingControllerCreateBillingsAsync(thgBillingListDtoGen)).data;
  }

  /**
   * Starts job to get creditor csv
   *
   * @param accountNumbers the accounts to get the creditor csv for
   */
  async dispatchCreditorCsv(accountNumbers: string[]): Promise<ThgOperationIdViewModelGen> {
    const thgCreditorListDtoGen: ThgCreditorListDtoGen = {
      accountNumbers: accountNumbers
    };

    return (await this.billingProxy.billingControllerCreateCreditorsAsync(thgCreditorListDtoGen)).data;
  }

  /**
   * Starts job to get creditor csv by billing numbers
   *
   * @param billingNumbers the billings to get the creditor csv for
   */
  async dispatchCreditorCsvByBilling(billingNumbers: number[]): Promise<ThgOperationIdViewModelGen> {
    const thgBillingListDtoGen: ThgBillingListDtoGen = {
      billingNumbers
    };

    return (await this.billingProxy.billingControllerCreateCreditorBillingAsync(thgBillingListDtoGen)).data;
  }

  /**
   * Get a csv by operation id
   *
   * @param operationId the operation id to get the csv for
   */
  async getCsvByOperationId(operationId: string): Promise<ThgUrlViewmodelGen> {
    return (await this.billingProxy.billingControllerAccessCsvFromOperation(operationId)).data;
  }

  /**
   * Upload a pdf for a billing
   *
   * @param billingNumber the billing to upload the pdf for
   * @param pdf the pdf to upload
   */
  async uploadPdf(billingNumber: number, pdf: File) {
    const thgBillingPdfDtoGen: ThgBillingPdfDtoGen = {
      billingPdf: pdf
    };

    return (await this.billingProxy.billingControllerReplacePdfs(billingNumber, thgBillingPdfDtoGen)).data;
  }

  /**
   * Create a billing manually with a dto
   *
   * @param {ThgCreateBillingThgDtoGen} dto the dto to create the billing with
   */
  async createBilling(dto: ThgCreateBillingThgDtoGen) {
    return (await this.billingProxy.billingControllerCreateBilling(dto)).data;
  }

  async addBillingPdfManually(billingNumber: number, data: ThgBillingPdfInputGen) {
    return (await this.billingProxy.billingControllerCreateBillingManually(billingNumber, data)).data;
  }

  /**
   * Loads billings of user that is signed in
   * @returns
   */
  async getMyBillings() {
    return (await this.billingProxy.billingControllerGetMe()).data;
  }

  /**
   * Kicks off validity check
   */
  async check(): Promise<ThgOperationIdViewModelGen> {
    return (await this.billingProxy.billingControllerValidityCheck()).data;
  }

  /**
   * Kicks off autobilling for credit check
   */
  async creditAutoBilling(): Promise<ThgOperationIdViewModelGen> {
    return (await this.billingProxy.billingControllerDispatchCreditAutoBilling()).data;
  }

  /**
   * Get paginated billing data for partner
   *
   * @param query
   * @returns
   */
  async getPartnerBillings(
    query: ThgBillingControllerGetPartnerParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgBillingPartnerViewModelGen[] }> {
    return (await this.billingProxy.billingControllerGetPartner(query)).data;
  }

  /**
   * Get billing data for partner
   *
   * @param partnerId
   * @param billingNumber
   * @returns
   */
  async getPartnerBilling(partnerId: string, billingNumber: number): Promise<ThgBillingPartnerViewModelGen> {
    return (await this.billingProxy.billingControllerGetOneByBillingNumberForPartner(partnerId, billingNumber)).data;
  }
}

export default new BillingService(new ThgHttpClientProvider());
