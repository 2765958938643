


















































import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";

@Component({
  components: {}
})
export default class ThgDialogYearSelection extends Vue {
  @Prop()
  possibleYears!: number[];

  @Prop()
  numberplate!: string;

  selection: boolean[] = [];

  mounted() {
    for (let i = 1; i <= this.possibleYears.length; i++) {
      this.selection.push(true);
    }
  }

  get bonus() {
    return ThgCreateModule.bonus;
  }

  get prefix() {
    return ThgCreateModule.bonusPrefix;
  }

  isPriceReliability(year: number): boolean {
    // FIXME: Fix ME
    const yearsWithPriceReliability = [2023];

    return yearsWithPriceReliability.includes(year);
  }

  getPrice(year: number, vehicleClass?: ThgVehicleTypes) {
    return PartnerActiveOfferModule.priceConfig.get({ year: year, vehicleClass: vehicleClass || ThgVehicleTypes.M1 });
  }

  get selectedYears() {
    return ThgCreateModule.years;
  }

  set selectedYears(years: number[]) {
    ThgCreateModule.setYears(years);
  }

  getIsSelected(year: number) {
    return ThgCreateModule.years.includes(year);
  }

  toggleYear(year: number) {
    const index = this.selectedYears.findIndex(y => y == year);

    if (index === -1) {
      this.selectedYears = [...this.selectedYears, year];
    } else {
      this.selectedYears.splice(index, 1);
    }
  }
}
