var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('description-card', {
    attrs: {
      "reverse": false,
      "headline": "components.thg.ThgProcessDescriptionCard.car.headline",
      "description": "components.thg.ThgProcessDescriptionCard.car.text"
    }
  }, [_c('thg-description-svg-car', {
    attrs: {
      "width": "350",
      "height": "300",
      "viewBox": "0 0 1100 800"
    }
  })], 1), _c('description-card', {
    attrs: {
      "reverse": true,
      "headline": "components.thg.ThgProcessDescriptionCard.registration.headline",
      "description": "components.thg.ThgProcessDescriptionCard.registration.text"
    }
  }, [_c('thg-description-svg-registration', {
    attrs: {
      "width": "380",
      "height": "300",
      "viewBox": "500 0 4400 2600"
    }
  })], 1), _c('description-card', {
    attrs: {
      "reverse": false,
      "headline": "components.thg.ThgProcessDescriptionCard.agency.headline",
      "description": "components.thg.ThgProcessDescriptionCard.agency.text"
    }
  }, [_c('thg-description-svg-agency', {
    attrs: {
      "width": "350",
      "height": "300",
      "viewBox": "0 0 700 700"
    }
  })], 1), _c('description-card', {
    attrs: {
      "reverse": true,
      "headline": "components.thg.ThgProcessDescriptionCard.quote.headline",
      "description": "components.thg.ThgProcessDescriptionCard.quote.text"
    }
  }, [_c('thg-description-svg-quote', {
    attrs: {
      "width": "350",
      "height": "300",
      "viewBox": "0 0 700 800"
    }
  })], 1), _c('description-card', {
    attrs: {
      "reverse": false,
      "headline": "components.thg.ThgProcessDescriptionCard.sustainability.headline",
      "description": "components.thg.ThgProcessDescriptionCard.sustainability.text"
    }
  }, [_c('thg-description-svg-sustainability', {
    attrs: {
      "width": "350",
      "height": "300",
      "viewBox": "0 0 1100 800"
    }
  })], 1), _c('description-card', {
    attrs: {
      "reverse": true,
      "headline": "components.thg.ThgProcessDescriptionCard.yearly.headline",
      "description": "components.thg.ThgProcessDescriptionCard.yearly.text"
    }
  }, [_c('thg-description-svg-yearly', {
    attrs: {
      "width": "350",
      "height": "300",
      "viewBox": "0 0 800 800"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }