





















import { Component, Prop, Vue } from "vue-property-decorator";
import partnerService from "@/services/shared/partnerService";
import { HttpException } from "@/lib/exceptions/http";
import { MAX_LAT, MAX_LNG, MIN_LAT, MIN_LNG } from "@/lib/rules/geoRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { ThgAddressWithGeoGen } from "@/services/thg/v1/data-contracts";
import { MrfiktivAddressWithGeoGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({})
export default class GeoFinderField extends Vue {
  @Prop({ required: true }) readonly type!: "lat" | "lng";
  @Prop({ default: 0 }) value!: number;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) hint!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => ({}) }) address!: ThgAddressWithGeoGen | MrfiktivAddressWithGeoGen;

  loading = false;

  get isLat() {
    return this.type === "lat";
  }

  get min() {
    return this.isLat ? MIN_LAT : MIN_LNG;
  }

  get max() {
    return this.isLat ? MAX_LAT : MAX_LNG;
  }

  get geoRule() {
    const min = this.min;
    const max = this.max;
    return [isNumberRule(), requiredRule(), numberHigherRule(min), numberLowerRule(max)];
  }

  mounted() {
    if (this.type !== "lat" && this.type !== "lng") {
      this.$log.warn("Type must be either 'lat' or 'lng'. You passed: ", this.type);
    }
  }

  updateValue(newValue: string) {
    this.$emit("update:value", Number(newValue));
  }

  async getLocation() {
    this.loading = true;
    const searchstring = `${this.address.street} ${this.address.zip} ${this.address.city} ${this.address.state}`;
    this.$log.warn(searchstring);
    try {
      const geoResult = await partnerService.geocodehelper(searchstring);

      this.$log.warn(geoResult);

      if (geoResult && geoResult.type != "Point") {
        this.$toast.error("Error fetching geo");
        return;
      }

      this.$emit("update:address", {
        ...this.address,
        geo: {
          lat: geoResult.coordinates[1],
          lng: geoResult.coordinates[0]
        }
      });
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
