






























import ThgInviteFriendsCard from "@/components/thg/ThgInviteFriendsCard.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { AffiliateModule } from "@/store/modules/affiliate.store";
import { Component, Vue } from "vue-property-decorator";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

@Component({
  components: { LayoutReport, Debug, ThgInviteFriendsCard }
})
export default class ThgInviteFriends extends Vue {
  isLoading = false;

  get title() {
    return this.$t("report.thgInviteFrinds.title");
  }

  get description() {
    return this.$t("report.thgInviteFrinds.description");
  }

  get myAffiliates() {
    return AffiliateModule.myAffiliates;
  }

  async mounted() {
    this.isLoading = true;
    try {
      await AffiliateModule.getMyAffiliates();
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim laden der Affilates");
    } finally {
      this.isLoading = false;
    }
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
