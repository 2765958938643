







import LoaderReport from "@/components/utility/LoaderReport.vue";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { EventBusEnum } from "@/lib/enum/eventBus.enum";
import eventBus from "@/lib/eventBus";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { MissingInformationHandler } from "@/lib/notification/MissingUserInformationHandler";
import { thgQueryParams } from "@/lib/queryParams/thgQueryParams";
import { AppColorSchema } from "@/lib/utility/AppColorSchema";
import { LocalHostToPartnerMapper } from "@/lib/utility/local-host-to-partner-mapper";
import documentService from "@/services/sign/services/document.service";
import signRequestService from "@/services/sign/services/sign-request.service";
import signService from "@/services/sign/services/sign.service";
import { ThgSignHttpClientProvider } from "@/services/sign/sign-http-client.provider";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { BankingModule } from "@/store/modules/banking.store";
import { ConfigModule } from "@/store/modules/config";
import { FeatureModule } from "@/store/modules/feature.store";
import { NotificationModule } from "@/store/modules/notification.store";
import { ReportModule } from "@/store/modules/report.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import { GtagModule } from "@/store/modules/gtag.store";
import { ThgModule } from "@/store/modules/thg.store";

@Component({
  components: { LoaderReport }
})
export default class V extends Vue {
  created() {
    if (this.$cookies.get("_ga")) {
      GtagModule.consent();
    }

    ConfigModule.setAppContext(AppContextEnum.THG);
    new AppColorSchema(this.$vuetify, ConfigModule.mintFutureColorSchema);
    ConfigModule.setThgBaseUrl();
    ConfigModule.setisBankingAvailable(true);
    ConfigModule.setThgSupportMail();
    this.trySetStepperByPath();

    ConfigModule.setNavigationSettings({
      showMmmmintLogo: false,
      showThePartnerList: false,
      showTheOrganizationList: false,
      showTheFleetList: false,
      showTheAnonymizer: false,
      showTheCustomerList: false,
      showTheOnlineBookingList: false,
      showTheDigitalSignatureList: false,
      showTheAdminList: false,
      showTheThgList: true,
      showTheAboutList: false,
      showThgAboutList: true,
      showThgFeatureSettings: true,
      showTheProjectList: false,
      showTheCostList: false
    });

    ConfigModule.setPartnerSelectorDestinationVue("ThgDashboardPartnerSelected");
    signService.setProxy(new ThgSignHttpClientProvider());
    signRequestService.setProxy(new ThgSignHttpClientProvider());
    documentService.setProxy(new ThgSignHttpClientProvider());
  }

  async mounted() {
    eventBus.$on("login", () => {
      try {
        BankingModule.getMyBanking();
      } catch (error) {
        this.$log.error(error);
      }
    });

    eventBus.$on("logout", () => {
      NotificationModule.setIsNotificationsLoaded(false);
      ThgModule.clearMyThgs();
      this.$log.info("Handle Logout Event");
      this.$router.push({ name: "Logout" });
    });

    eventBus.$on("sessionExpired", () => {
      ThgModule.clearMyThgs();
      //handle forced logout
      this.$router.push({ path: "/login/logout?reason=expired" });
    });
    eventBus.$on(EventBusEnum.SPAM_USER_EXCEPTION, async () => {
      this.$router.push({ name: "NoRoles" });
    });

    eventBus.$on("userUpdated", async () => {
      NotificationModule.clear();
      await MissingInformationHandler.check();
    });

    eventBus.$on("bankingUpdated", async () => {
      NotificationModule.clear();
      await MissingInformationHandler.check();
    });

    eventBus.$on(EventBusEnum.CHARGING_STATION_CREATED, async () => {
      NotificationModule.clear();
      await MissingInformationHandler.check();
    });

    try {
      // if some data is given in the query the report is prefilled.
      this.prefillDataFromQuery();
    } catch (error) {
      Vue.$log.error(error);
    }

    ReportModule.setLoading(true);
    PartnerActiveOfferModule.setLoading(true);

    try {
      await LocalHostToPartnerMapper.tryGetPartnerByHost(window.location.hostname, {
        localhost: "devappwirkaufendeinethg"
        // localhost: "devappwirkaufendeinethgat"
      });

      await PartnerActiveOfferModule.loadDocuments(ReportModule.partner.id);

      this.$log.info("ReportModule.partner", ReportModule.partner);
      this.$log.info("PartnerActiveOfferModule.prices", PartnerActiveOfferModule.prices);

      if (FeatureModule.isVuetifyInPartnerColors) {
        this.setPartnerColorAsAccent();
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast("Fehler beim Laden der Anwendung.");
      this.$router.push({ name: "PartnerContactError" });
    } finally {
      ReportModule.setLoading(false);
      PartnerActiveOfferModule.setLoading(false);
    }

    if (UserModule.isAuthenticated && !NotificationModule.isNotificationsLoaded) {
      NotificationModule.setIsNotificationsLoaded(true);
      await MissingInformationHandler.check();
    }
  }

  setPartnerColorAsAccent() {
    this.$vuetify.theme.themes.light.primary = getDefaultPartnerColor().slice(0, 7);
  }

  get loading() {
    return ReportModule.loading;
  }

  trySetStepperByPath() {
    const route = this.$route.path;
    const parts = route.split("/"); // "/3" -> ["", "3" ]
    const maybeAStep: string = parts[1];
    if (Object.values(ReportScreenEnum).includes(maybeAStep as ReportScreenEnum)) {
      ReportModule.setStep(maybeAStep as ReportScreenEnum);
    }
  }

  prefillDataFromQuery() {
    thgQueryParams.forEach(param => {
      const value = this.$route.query[param.key];

      if (value !== undefined) {
        param.onFound((value as string) || "");
        this.$log.info(`${param.key} set in store`);
      }
    });
  }
}
