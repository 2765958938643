var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g({
          attrs: {
            "loading": _vm.isLoading,
            "icon": ""
          },
          on: {
            "click": _vm.initialize,
            "v-bind": attrs
          }
        }, on), [_c('v-icon', [_vm._v("mdi-table-arrow-down")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color,
    style: _vm.titleStyle
  }, [_c('h4', {
    staticClass: "text-truncate",
    style: _vm.titleTextStyle
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgQuoteExportDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "color": _vm.btnColor,
      "outlined": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "loading": _vm.isLoading,
      "color": _vm.btnColor,
      "dark": true
    },
    on: {
      "click": _vm.exportData
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteExportDialog.btnText")) + " ")])], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "4"
    }
  }, [_c('v-card-text', [!_vm.disableStatiSelector ? _c('span', [_vm._v(_vm._s(_vm.$t("components.thg.ThgQuoteExportDialog.filterText")))]) : _vm._e(), !_vm.disableStatiSelector ? _c('v-autocomplete', {
    staticClass: "mt-4",
    attrs: {
      "items": _vm.thgStati,
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "Status",
      "multiple": ""
    },
    model: {
      value: _vm.selectedStati,
      callback: function callback($$v) {
        _vm.selectedStati = $$v;
      },
      expression: "selectedStati"
    }
  }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteExportDialog.selectedText", {
    value: _vm.filteredData.length.toString()
  })))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "4"
    }
  }, [_c('v-card-text', [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.ThgQuoteExportDialog.instructioText")))]), _c('v-treeview', {
    attrs: {
      "items": _vm.items,
      "selection-type": "leaf",
      "selectable": "",
      "return-object": ""
    },
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }