var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('v-form', {
    ref: "chargingStationForm",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.dateRangeTitle")) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.dateRangeSubTitle")) + " ")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-list', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "mandatory": "",
      "color": "primary"
    },
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  }, _vm._l(_vm.activeItems, function (item, i) {
    return _c('v-list-item', {
      key: item.id,
      attrs: {
        "data-test-form-date": i
      },
      on: {
        "click": function click($event) {
          return _vm.usedPredefinedDateRange(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var active = _ref.active;
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(item.text)))])], 1), active ? _c('v-list-item-action', [_c('v-icon', [_vm._v("mdi-check")])], 1) : _vm._e()];
        }
      }], null, true)
    });
  }), 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card-text', [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.isDateRangeResetDisabled,
      "text": "",
      "x-small": ""
    },
    on: {
      "click": _vm.resetDate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.reset")) + " ")]), _c('v-chip', {
    staticClass: "mr-2"
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-calendar-start ")]), _vm._v(" " + _vm._s(_vm.temporaryDateFrom) + " ")], 1), _c('v-chip', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.temporaryDateTo) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" mdi-calendar-end ")])], 1)], 1), _c('v-date-picker', {
    attrs: {
      "flat": "",
      "range": "",
      "full-width": "",
      "show-adjacent-months": "",
      "no-title": "",
      "scrollable": "",
      "first-day-of-week": "1",
      "color": "primary",
      "locale": "de-de",
      "min": _vm.minDate,
      "max": _vm.today,
      "data-test-form-date": ""
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), !_vm.isSameYear ? _c('v-card-text', {
    staticClass: "red--text"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.dateRangeError")) + " ")]) : _vm._e()], 1)], 1), _c('v-card-text', [_c('p', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.amountTitle")) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.amountSubTitle", {
    minimum: _vm.meterReadingMinAmount
  })) + " ")])]), _c('v-text-field', {
    attrs: {
      "rules": _vm.amountRule,
      "label": _vm.$t('components.thg.ThgMeterReadingForm.amountInKwh'),
      "required": "",
      "outlined": "",
      "suffix": "kWh",
      "hide-spin-buttons": "",
      "data-test-form-amount-in-kwh": "",
      "type": "number"
    },
    model: {
      value: _vm.meterReading.meterReading.amountInKwh,
      callback: function callback($$v) {
        _vm.$set(_vm.meterReading.meterReading, "amountInKwh", _vm._n($$v));
      },
      expression: "meterReading.meterReading.amountInKwh"
    }
  }), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.thg.ThgMeterReadingForm.termsTitle'))
    }
  }), _c('v-checkbox', {
    ref: "reviewed",
    staticClass: "mx-4",
    attrs: {
      "label": _vm.$t('components.thg.ThgMeterReadingForm.confirmation'),
      "rules": _vm.requiredRule,
      "data-test-review-checkbox": ""
    },
    model: {
      value: _vm.meterReading.isConfirmed,
      callback: function callback($$v) {
        _vm.$set(_vm.meterReading, "isConfirmed", $$v);
      },
      expression: "meterReading.isConfirmed"
    }
  }), _c('v-checkbox', {
    ref: "privacy",
    staticClass: "mx-4",
    attrs: {
      "label": _vm.$t('components.thg.ThgMeterReadingForm.confirmationPrivacy'),
      "rules": _vm.requiredRule,
      "data-test-privacy-checkbox": ""
    },
    model: {
      value: _vm.isTermsAccepted,
      callback: function callback($$v) {
        _vm.isTermsAccepted = $$v;
      },
      expression: "isTermsAccepted"
    }
  }), _c('v-btn', {
    staticClass: "mt-4 mr-4",
    attrs: {
      "loading": _vm.isSaving,
      "color": "primary",
      "disabled": !_vm.valid || !_vm.isInvalidDateRange,
      "data-test-form-submit": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingForm.submit")) + " ")])], 1)], 1) : _c('v-container', {
    staticClass: "padded"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "offset": "0",
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-heading, table-tbody, table-tfoot"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }